import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Row, Form, Button, Nav, Card,Accordion } from "react-bootstrap";

import { loadAllCustomer, loadCustomer, resetCustomer } from '../action/customerAction';
import { addItemSale, changeItemSale, changeSale, resetItemSale, saveSale, loadAllSales, loadSales, cancelEditSale, resetSale, deleteSale } from '../action/saleAction';

import ProductListing,{ProductListingOption} from "./ProductListing";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../constant/dateFormat";
import { isPermitted, isOwner } from "../constant/permission"
import { useParams } from "react-router-dom";
import groupBy from "../constant/groupBy";

export default function Sales(props) {

    const params = new URLSearchParams(window.location.search);
    const editBillId = params.get("id");
    const editCustomerId = params.get("stackHolder");
    const editOnly = params.get("editOnly");

    const [mode, setMode] = useState("new");

    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);


    const sales = useSelector(
        (state) => state.sale.sales
    );

    const sale = useSelector(

        (state) => state.sale.sale
    );

    const [errors, setErrors] = useState({});
    const customers = useSelector(
        (state) => state.customer.customers
    );

    const customer = useSelector(
        (state) => state.customer.customer
    );



    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = findFormErrors()
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            calculateTotal();

            const postData = { ...sale, products: sale.products.filter(pro => pro.quantity && Number(pro.quantity) > 0).map(pro => { return { ...pro, price: Number(pro.price), quantity: Number(pro.quantity) } }) }

            if (postData.products.length > 0) {

                dispatch(saveSale({ ...postData, billDate: formatDate(sale.billDate), discount: (sale.discount ? Number(sale.discount) : sale.discount) }, editOnly));
            }
        }
    }

    const deleteBill = () => {

        if (sale.id) {
            dispatch(deleteSale(sale.id));
        }
    }

    const findFormErrors = () => {

        const { billDate, billNo, customerId } = sale
        const newErrors = {}

        if (!billDate || billDate === '') newErrors.billDate = 'Bill Date should not be blank!'
        if (!billNo || billNo === '') newErrors.billNo = 'Bill number should not be blank!'
        if (!customerId || customerId === '' || customerId === 0) newErrors.customerId = 'Select a vendor !'
        return newErrors
    }

    const setField = (field, value) => {
        dispatch(changeSale({ name: field, value }));
        if (mode === "new") {
            if (field === "customerId") {
                dispatch(loadCustomer(value));
                dispatch(resetItemSale());
                dispatch(changeSale({ name: "billAmount", value: 0 }))
            }
            if (!!errors[field]) setErrors({
                ...errors,
                [field]: null
            })
        }
        else if (field === "billDate" || field === "customerId") {
            if ((field === "billDate") && sale.customerId) {
                dispatch(loadAllSales({ date: formatDate(value), customerId: sale.customerId, status: "UNPAID" }));
            } else if ((field === "customerId") && sale.billDate) {

                dispatch(loadAllSales({ date: formatDate(sale.billDate), customerId: value, status: "UNPAID" }));
            }
        }
        else if (field === "id") {
            setEditMode(true);
            dispatch(loadSales(value));
            dispatch(loadCustomer(sale.customerId))
        }
    }

    const setDecimalField = (id, value, field) => {

        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;
        if (value === "" || re.test(value)) {
            dispatch(changeItemSale(id, value, field));
            // setField(field,value);
        }
    }

    const setDecimalFieldByName = (name, value) => {

        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;
        if (value === "" || re.test(value)) {
            setField(name, value);
        }
    }
    const setOnBlur = (id, value, field) => {
        dispatch(changeItemSale(id, Number(Number(value).toFixed(2)), field));
        calculateTotal();
    }

    const calculateTax = () => {

        if (sale.taxper && sale.billAmount) {

            const billAmount = sale.products.reduce((billAmount, product) => {
                return Number(billAmount) + Number(product.price * (product.quantity ? product.quantity : 0));
            }, 0);

            const tax = (Number(billAmount) * Number(sale.taxper) / 100).toFixed(2);

            dispatch(changeSale({ name: "tax", value: tax }));

            dispatch(changeSale({ name: "billAmount", value: (Number(billAmount) + Number(tax)).toFixed(2) }));
        }

    }

    const calculateTotal = () => {

        if (sale && sale.products) {

            const billAmount = sale.products.reduce((billAmount, product) => {
                return Number(billAmount) + Number(product.price * (product.quantity ? product.quantity : 0));
            }, 0);

            if (sale.taxper) {
                calculateTax();
            }
            else {

                dispatch(changeSale({ name: "billAmount", value: (billAmount + Number(sale.tax ? sale.tax : 0)).toFixed(2) }));
            }

        }
        else {
            dispatch(changeSale({ name: "billAmount", value: 0 }));
        }

    }

    React.useEffect(() => {
        dispatch(loadAllCustomer('active'));
        dispatch(resetSale());
        dispatch(resetCustomer());
    }, [dispatch]);



    React.useEffect(() => {

        if (sale && !sale.billDate) {
            dispatch(changeSale({ name: "billDate", value: new Date() }))
            setEditMode(false);
        }

    }, [dispatch, mode, sale]);

    React.useEffect(() => {

        if (editOnly) {

            props.hideMenu();
            setMode("edit");
            setEditMode(true);
            dispatch(loadSales(editBillId));
            dispatch(loadCustomer(editCustomerId))
        }

    }, [dispatch, editOnly]);



    React.useEffect(() => {
        if (customer && customer.products && mode === "new") {
            dispatch(resetItemSale());
            customer.products.forEach(product =>
                dispatch(addItemSale({ ...product }))
            )
        }

        else if (customer && customer.products && mode !== "new") {

            customer.products.forEach(product => {

                const existing = sale && sale.products ? sale.products.filter(pur => pur.id === product.id) : [];

                if (existing && existing.length > 0) {
                    //already exists    
                }
                else {
                    dispatch(addItemSale({ ...product }))
                }
            }
            )
        }
        else {
            dispatch(resetItemSale());
        }
    }, [dispatch, customer]);

    const listProductByGroup = (products) => {

        return (<ProductListingOption products={products} isRetail={customer.isRetail} setDecimalField={setDecimalField} setOnBlur={setOnBlur}></ProductListingOption>);
    }

    return (
        <Container className="mt-3  col-11 ">
            <Card border="dark" >
                <Card.Header className="bg-dark">

                    {editOnly ? ("Edit") :
                        (<Nav variant="tabs" defaultActiveKey={mode}
                            onSelect={(e) => {
                                setMode(e);
                                dispatch(resetSale());
                                setEditMode(false);
                            }}>
                            <Nav.Item>
                                <Nav.Link eventKey={"new"}>New  </Nav.Link>
                            </Nav.Item>
                            {
                                isPermitted("EDIT_SALE") ? (<Nav.Item>
                                    <Nav.Link eventKey={"edit"}>Edit </Nav.Link>
                                </Nav.Item>) : ("")
                            }

                            {
                                isPermitted("ADD_DISCOUNT") ? (<Nav.Item>
                                    <Nav.Link eventKey={"discount"}>Discount </Nav.Link>
                                </Nav.Item>) : ("")
                            }


                            <Nav.Item className="col ms-auto">

                                <h6 className="nav-link float-end mb-0">
                                    Sale
                                </h6></Nav.Item>
                        </Nav>)
                    }

                </Card.Header>

                <Card.Body className="p-1"  >
                    <Container fluid className="p-0">
                        <Form >


                            <Row className="g-2 mb-2 mt-2" >
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker maxDate={new Date()} disabled={editMode} dateFormat="dd-MM-yyyy" className={`form-control form-control-sm ${!!errors.billDate ? "is-invalid" : ""}`} isInvalid={true} selected={sale && sale.billDate ? sale.billDate : null} onChange={(date) => setField("billDate", date)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Customer</Form.Label>
                                        <Form.Select disabled={editMode} size="sm" as="select" isInvalid={!!errors.user} onChange={e => setField("customerId", Number(e.target.value))} value={sale && sale.customerId ? sale.customerId : ""} >
                                            <option value={0}>Select a Customer </option>
                                            {
                                                customers ? customers.map((cust, i) => {
                                                    return <option value={cust.id} key={i}>{cust.name}</option>;
                                                }) : ("")
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Bill No</Form.Label>
                                        <Form.Control size="sm" placeholder="Bill Number" as="input" isInvalid={!!errors.billNo} onChange={e => setField("billNo", e.target.value)} value={sale && sale.billNo ? sale.billNo : ""}></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {
                                ((mode && mode === "new") || editOnly) ?
                                    ("")
                                    : (
                                        <Row className="g-2 mb-2">
                                            <Col >
                                                <Form.Group >
                                                    <Form.Label>Bills</Form.Label>
                                                    <Form.Select size="sm" as="select" placeholder="Select a bill" onChange={e => { if (Number(e.target.value) > 0) setField("id", Number(e.target.value)) }} value={sale && sale.id ? sale.id : "0"}>
                                                        <option value={0}>  {sales ? sales.length : 0} bills avaliable for the bill date </option>
                                                        {
                                                            sales ? sales.map((pur, i) => {
                                                                return <option value={pur.id} key={i}>{pur.billNo}</option>;
                                                            }) : ("")
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                            }

                            {
                                mode === "discount" ? (<Row className="g-2 mb-2">
                                    <Col >
                                        <Form.Group >
                                            <Form.Label>Discount</Form.Label>
                                            <Form.Control size="sm" placeholder="Discount" as="input" onChange={e => setDecimalFieldByName("discount", e.target.value)} value={sale && sale.discount ? sale.discount : ""}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>) : ("")
                            }

                            <hr></hr>

                            <Row className="mt-1 mb-1 g-2">
                                {
                                    sale.products ? listProductByGroup(sale.products)



                                        //.filter(product => Number(product.quantity) > 0)
                                        : ("")
                                }
                            </Row>

                            <hr></hr>



                            <Row className="">
                                <Col className="text-end">Tax:</Col>
                                <Col className="text-end"> <input disabled={sale && sale.billAmount && Number(sale.billAmount) > 0 ? false : true} value={sale && sale.taxper ? sale.taxper : ''} onChange={(e) => setDecimalFieldByName("taxper", Number(e.target.value))}
                                    onBlur={calculateTax}
                                    placeholder="Tax" className="rounded-0 border w-100" /></Col>
                                <Col className="text-end">

                                    &#x20b9;{sale && sale.tax ? sale.tax : ''}

                                </Col>
                            </Row>

                            <Row className="">
                                <Col></Col>
                                <Col className="text-end">Total :</Col>
                                <Col className=" fw-bold text-end">

                                    &#x20b9;{Number(sale && sale.billAmount ? sale.billAmount : 0).toFixed(2)}

                                </Col>
                            </Row>
                            <hr></hr>

                        </Form>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end">
                        {
                            mode === "edit" || mode === "discount" ? (
                                <React.Fragment>
                                    <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={Number(sale && sale.billAmount ? sale.billAmount : 0) === 0 && !isOwner()}>Update</Button>
                                    {isOwner() && !editOnly ? (<Button variant="danger" size="sm" onClick={deleteBill}>Delete</Button>) : ("")}
                                </React.Fragment>


                            ) : (
                                <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={Number(sale && sale.billAmount ? sale.billAmount : 0) === 0 && !isOwner()}>Save</Button>
                            )
                        }
                        {mode === "edit" || (mode === "discount" && editMode) ?
                            (
                                <Button variant="danger" size="sm" onClick={() => {

                                    setEditMode(false);

                                    if (editOnly) {
                                        window.close();
                                    }
                                    else {
                                        dispatch(cancelEditSale())
                                    }

                                }}>Cancel</Button>

                            )
                            : ("")
                        }
                    </div>
                </Card.Footer>
            </Card>

        </Container>
    );
}
