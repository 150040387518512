

import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import groupBy from "../constant/groupBy";
export function ProductListingOption({products,...rest}){


    
    const listProductByGroup = () => {

        const groupByProduct = groupBy(products, product => product.prodId.text);

        return (<React.Fragment>

            {
                [...groupByProduct.entries()].map(([key],i) => {
                    return (<Card key={i}>
                        <Card.Header  className="bg-dark">{key}</Card.Header>
                        <Card.Body className="p-0">

                            {
                                groupByProduct.get(key)
                                    .map((product, i) => {
                                        return (<ProductListing  {...product} {...rest}></ProductListing>)
                                    })
                            }
          

                        </Card.Body>
                    </Card>)

                })
            }

        </React.Fragment>)
    }

    return listProductByGroup();
}

export default function ProductListing({ setDecimalField, setOnBlur, billItem = false, isRetail = 'N', ...product }) {


    return (<Col sm={12} className="mt-1">
        <Card border="secondary" >
            <Card.Body className={`p-1`}>
                <Container fluid>
                    <Row className="ps-1 pe-1 border-bottom">
                        <Col className="p-0 fw-bold"> {product.text} <span className="float-end fs-l7">{product.uom.code} </span></Col>
                    </Row>
                    <Row className="ps-1 pe-1">
                        <Col className="p-0 border-end">
                            {
                                billItem ? (product.quantity ? product.quantity : '') :
                                    (<input value={product.quantity ? product.quantity : ''}
                                        onChange={(e) => setDecimalField(product.id, e.target.value, 'quantity')}
                                        onBlur={(e) => setOnBlur(product.id, e.target.value, 'quantity')}
                                        placeholder="Quantity" className=" bg-quantity  rounded-0 border-0 w-100" />)
                            }

                        </Col>
                        <Col className="p-0 border-end ">
                            {
                                isRetail === 'Y' ? (
                                    billItem ? (product.price ? product.price : '') :
                                        (<input value={product.price ? product.price : ''}
                                            onChange={(e) => setDecimalField(product.id, e.target.value, 'price')}
                                            onBlur={(e) => setOnBlur(product.id, e.target.value, 'price')}
                                            placeholder="Price" className=" bg-quantity  rounded-0 border-0 w-100" />)
                                ) : (
                                <p className="text-center m-0 fs-l7 pt-1"> &#x20b9;{product.price}</p>
                            )
                        }
                        </Col>

                        <Col className="p-0 text-end fw-bold fs-l7 align-middle pt-1"> &#x20b9;{Number(product.price * (product.quantity ? product.quantity : 0)).toFixed(2)}</Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </Col>);
}

