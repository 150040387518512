import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, FloatingLabel, Col, Row, Form, Button, Card, Modal } from "react-bootstrap";

import { warningNotification } from "../action/notificationAction";
import { loadVendor, changeVendor, saveVendor, resetVendor, changeVendorProduct, addVendorProduct, removeVendorProduct } from "../action/vendorAction";
import { loadProductLookups } from "../action/productAction";
import ProductLookup from "../component/ProductLookup";
import { isPermitted } from "../constant/permission"
import { numberCheck } from "../constant/numberCheck"


export default function VendorEntry({ viewalone = false, ...userProps }) {

    const [props, setProps] = React.useState(userProps);
    const [errors, setErrors] = useState({});
    const [selProduct, setSelProduct] = useState({});

    const vendor = useSelector(
        (state) => state.vendor.vendor
    );

    const productLookup = useSelector((state) => state.productLookup.productLookup);

    const [show, setShow] = useState(false);

    const handleClose = (product) => {

        if (product && product.idx) {

            if (vendor && vendor.products) {
                let filter = vendor.products.filter((ven) => ven.id === product.id && ven.idx !== product.idx);

                if (filter === null || filter.length === 0) {
                    dispatch(changeVendorProduct(product));

                }
                else {
                    dispatch(warningNotification("Product already mapped"));
                    return;
                }
            }
            else {
                dispatch(changeVendorProduct(product));
            }
        }
        else if (product) {
            if (vendor && vendor.products) {
                let filter = vendor.products.filter((ven) => ven.id === product.id);

                if (filter === null || filter.length === 0) {
                    dispatch(addVendorProduct(product));

                }
                else {
                    dispatch(warningNotification("Product already mapped"));
                    return;
                }
            }
            else {
                dispatch(addVendorProduct({ ...product, idx: (vendor && vendor.products ? vendor.products.length + 1 : 1) }));
            }
        }

        setShow(false);
        setSelProduct({});

    }

    const removeProduct = (idx) => {
        dispatch(removeVendorProduct(idx));
    }

    const handleShow = (selProduct) => {
        if (selProduct != null) {
            setSelProduct({ ...selProduct });
        }
        setShow(true);
    }

    const dispatch = useDispatch();


    React.useEffect(() => {
        if (props && props.seletedVendor) {
            dispatch(loadVendor(props.seletedVendor));
        }
    }, [dispatch]);


    React.useEffect(() => {
        dispatch(loadProductLookups());

    }, [dispatch]);

    React.useEffect(() => {
        dispatch(resetVendor());
    }, [dispatch]);


    const setField = (field, value) => {
        dispatch(changeVendor({ name: field, value }))
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleSubmit = (e, method) => {
        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors);
        } else {

            dispatch(saveVendor({ ...vendor, status: (props && props.seletedVendor ? vendor.status : 'active') }, method));

            if (props && props.handleClose) {
                props.handleClose();
            }
        }
    }

    const findFormErrors = () => {
        const { name, code, address, gstNo, phone, password } = vendor
        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Name should not be blank!'
        if (!code || code === '') newErrors.code = 'Code should not be blank!'
        if (!gstNo || gstNo === '') newErrors.gstNo = 'Code should not be blank!'
        if (!address || address === '') newErrors.address = 'Address cannot be blank!'
        if (!phone || phone === '') newErrors.phone = 'Address cannot be blank!'
        if ((props && props.seletedVendor)) {

        }
        else if (!password || password === '') {
            newErrors.password = 'password cannot be blank!'
        }

        return newErrors
    }





    return (
        <Container className="mt-3  col-11">

            <Card border="dark" >
                <Card.Header as="h6" className="bg-dark">Vendor</Card.Header>
                <Card.Body className="p-0" style={{ "height": "70vh", "overflowX": "auto" }} >
                    <Container >
                        <Form >
                            <Row className="g-2 mb-2 mt-2">
                                <Col >

                                    <FloatingLabel label="Vendor Name">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Vendor Name" isInvalid={!!errors.name} onChange={e => setField('name', e.target.value)} value={vendor && vendor.name ? vendor.name : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="Vendor Code">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Vendor Code" isInvalid={!!errors.code} onChange={e => setField('code', e.target.value)} value={vendor && vendor.code ? vendor.code : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.code} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >

                                    <FloatingLabel label="Address">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Address" isInvalid={!!errors.address} onChange={e => setField('address', e.target.value)} value={vendor && vendor.address ? vendor.address : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.address} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="GSTIN">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="GSTIN" isInvalid={!!errors.gstNo} onChange={e => setField('gstNo', e.target.value)} value={vendor && vendor.gstNo ? vendor.gstNo : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.gstNo} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            {/* <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="Manager">
                        <Form.Control size="sm" type="text" placeholder="Manager"  isInvalid={!!errors.manager} onChange={e => setField('manager', e.target.value)} value={form.manager ?form.manager:(props && props.manager?props.manager:"")}/>
                        <Form.Control.Feedback type='invalid'>{errors.manager} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                </Row> */}
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="Phone">
                                        <Form.Control disabled={props && props.seletedVendor ? true : false} size="sm" type="text" placeholder="Phone Number" onKeyPress={numberCheck} maxlength="10" isInvalid={!!errors.phone} onChange={e => setField('phone', e.target.value)} value={vendor && vendor.phone ? vendor.phone : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            {props && props.seletedVendor ? (<Row className="g-2 mb-2">

                                <Form.Group >

                                    <Form.Check disabled={viewalone} type="checkbox" label="Active ? " checked={vendor && vendor.status && vendor.status === "active" ? true : false} onChange={e => setField('status', e.target.checked ? 'active' : 'dormant')} />

                                </Form.Group>

                            </Row>) :
                                <Row className="g-2 mb-2">
                                    <Col >
                                        <FloatingLabel label="Password">
                                            <Form.Control size="sm" type="password" placeholder="Password" isInvalid={!!errors.userName} onChange={e => setField('password', e.target.value)} value={vendor && vendor.password ? vendor.password : ""} />
                                            <Form.Control.Feedback type='invalid'>{errors.password} </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            }

                            <Row className="g-2 mb-2">

                                <Form.Group >

                                    <Form.Check disabled={viewalone} type="checkbox" label="Retail Vendor ?" id="custRetail" checked={vendor && vendor.isRetail && vendor.isRetail === "Y" ? true : false} onChange={e => setField('isRetail', e.target.checked ? 'Y' : 'N')} />

                                </Form.Group>

                            </Row>

                        </Form>
                    </Container>
                    <Container className="mb-2">
                        <Card>
                            <Card.Header as="h6" className="bg-dark">Product Mapping

                                {isPermitted("NEW_VENDOR") || isPermitted("EDIT_VENDOR") ? (
                                    <Button variant="success" size="sm" className="float-end" onClick={() => handleShow()}> Add</Button>)
                                    : ("")
                                }
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-2 mb-2">

                                    {
                                        vendor && vendor.products ? (

                                            vendor.products.map((ven, i) => {
                                                return (
                                                    <Col md={12} sm={4} key={i}>
                                                        <Card border="secondary" >
                                                            <Card.Body className="p-1">
                                                                <Card.Title as="h6">
                                                                    <Button disabled={viewalone} className="p-0" variant="link" size="sm" style={{ fontSize: "inherit" }} onClick={() => handleShow({ ...ven, idx: ven.idx ? ven.idx : vendor.products.length + 1 })}>{ven.text}</Button>
                                                                    {
                                                                        viewalone ? ("") : (<i className="fa fa-trash text-danger float-end" style={{ cursor: "pointer" }} onClick={() => { removeProduct(ven.idx ? ven.idx : vendor.products.length + 1) }}></i>)
                                                                    }
                                                                </Card.Title>
                                                                <Card.Text>
                                                                    Rs.{ven.price} / {ven.uom.code}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>)
                                            })
                                        ) : ("")
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                </Card.Body>
                <Card.Footer>

                    {isPermitted("NEW_VENDOR") || isPermitted("EDIT_VENDOR") ? (
                        <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                            {
                                props && props.seletedVendor ? (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                    <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Update</Button>
                                    <Button variant="danger" size="sm" onClick={() => { props.handleClose() }}>Back</Button>
                                </div>) : (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                    <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Save</Button>
                                    <Button variant="danger" size="sm" onClick={() => dispatch(resetVendor())}>Reset</Button>
                                </div>)
                            }
                        </div>
                    ) : ("")}


                </Card.Footer>
            </Card>


            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Product Mapping</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form size="md">
                        <ProductLookup product={selProduct} productLookup={productLookup} handleClose={handleClose} />

                    </Form>

                </Modal.Body>

            </Modal>

        </Container>);
}