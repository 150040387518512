import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";

import { Container, Col, Row, Form, Button, Card, } from "react-bootstrap";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import fileDownload from 'js-file-download'
import { formatDate,formatDateView } from "../constant/dateFormat";
import { pAxios } from ".."
import { dangerNotification } from "../action/notificationAction"


import { loadAllCustomer } from '../action/customerAction';
import { loadAllVendor } from '../action/vendorAction';
export default function Report() {



    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedReport, setSelectedReport] = useState(0);
    const [stackHoderId, setStackHoderId] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const dispatch = useDispatch();

    const reports = [{id:0,name:"Select a report"},
    {
        id:1,
        name: "Sales",
        type: "qty",
        entity: "sales",
        url: "itemReport"
    },
    
    {
        id:3,
        name: "Purchase",
        type: "qty",
        entity: "purchase",
        url: "itemReport"
    },
    {
        id:5,
        name:"Recon P/S",
        type:"qty",
        url:"reconReport"
    },
     {
        id:7,
        name: "Vendor Wise Purchase",
        type: "vendor",
        entity: "purchase",
        url: "itemReport",
        isStakHolderReq:true,
        
     },
     {
        id:8,
        name: "Customer Wise Sales",
        type: "customer",
        entity: "sales",
        url: "itemReport",
        isStakHolderReq:true,
    },
    {
        id:9,
        name:"Sales Invoice",
        type:"price",
        entity: "sales",
        url:"invoiceReport"
    },
    {
        id:10,
        name:"Purchase Invoice",
        type:"price",
        entity: "purchase",
        url:"invoiceReport"
    },
    {
        id:11,
        name:"Payment",
        type:"price",
        entity: "purchase",
        url:"paymentReport"
    },
    {
        id:12,
        name:"Expense",
        type:"price",
        entity: "purchase",
        url:"expenseReport"
    },

     
    ];

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    React.useEffect(() => {
        dispatch(loadAllCustomer('active'));
        dispatch(loadAllVendor('active'));
    
    }, [dispatch])



    const handleDownload = () => {

        if (startDate && endDate && selectedReport) {

            
            let {type,entity,url,isStakHolderReq}=getSelectedReportProp(selectedReport);

            if(isStakHolderReq && !stackHoderId){
                dispatch(dangerNotification("Select valid stackholder"))
            }
            else{

                const data = {
                    fromDate: formatDate(startDate),
                    toDate: formatDate(endDate),
                    type:`${type}${stackHoderId?'-'+stackHoderId:""}`,
                    entity 
                    
                }

                if(stackHoderId){
                    data.stackHoderId=stackHoderId;
                }
    
                let fileName= `${url}${entity?'-'+entity:''}-${formatDateView(startDate)+"-"+formatDateView(endDate)}.xlsx`;
                let params = Object.keys(data).map(function (k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
                }).join('&')
    
                pAxios({
                    "method": "GET",
                    "url": "/api/auth",
                
                }).then(r=>{
    
                    pAxios.get(`/api/report/${url}?${params}`, {
                        responseType: 'blob',
                    })
                        .then((res) => {
                            fileDownload(res.data,fileName);
                            setStackHoderId(null);
                        })
                        .catch((err)=>{
        
                            setStackHoderId(null);
                            err.response.data.text().then(txt=>{
                                dispatch(dangerNotification( JSON.parse(txt).message ))
                            })
                           
                        })
                })

               
            }

        }
        else{
            dispatch(dangerNotification("Select valid date and report name"))
        }
    }

    const getSelectedReportProp=(selectedReport)=>{

        return reports.filter(rep=>rep.id===selectedReport)[0]; 

    }

    return (
        <Container className="mt-3  col-11 ">
            <Card border="dark" >
                <Card.Header className="bg-dark" as="h6">
                    Report
                </Card.Header>

                <Card.Body className="p-1"  >
                    <Container fluid className="p-0">
                        <Form >
                            <Row className="g-2 mb-2 mt-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Date</Form.Label>

                                        <DatePicker maxDate={new Date()}
                                            placeholderText="Select date range"
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            className="form-control form-control-sm h-100"
                                            isClearable={true}
                                        ></DatePicker>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Report</Form.Label>
                                        <Form.Select size="sm" as="select" onChange={e => setSelectedReport(Number(e.target.value))} value={selectedReport} >
                                            
                                            
                                            {
                                                reports.map((report,idx)=>
                                                    <option value={report.id} key={idx}>{report.name}</option>
                                                )
                                            }

                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {
                                getSelectedReportProp(selectedReport).isStakHolderReq?
                                
                                   ( getSelectedReportProp(selectedReport).name.includes("Sales")?
                                    (<Row className="g-2 mb-2">
                                    <Col >
                                        <Form.Group >
                                            <Form.Label>Customer</Form.Label>
                                            <Form.Select  size="sm" as="select"  onChange={e => setStackHoderId(Number(e.target.value))} value={stackHoderId?stackHoderId:0} >
                                                <option value={0}>Select a Customer </option>
                                                {
                                                    customers ? customers.map((cust, i) => {
                                                        return <option value={cust.id} key={i}>{cust.name}</option>;
                                                    }) : ("")
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>):
                                (<Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Vendor</Form.Label>
                                        <Form.Select onChange={e => setStackHoderId(Number(e.target.value))} value={stackHoderId?stackHoderId:0} >
                                            <option value={0}>Select a Vendor </option>
                                            {
                                                vendors ? vendors.map((ven, i) => {
                                                    return <option value={ven.id} key={i}>{ven.name}</option>;
                                                }) : ("")
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>))
                                :
                                ("")

                            }
                            

                            <div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                <Button variant="danger" size="sm" onClick={handleDownload}>Download</Button>
                            </div>

                        </Form>
                    </Container>
                </Card.Body>

            </Card>

        </Container>
    );
}
