import {
    LOADSUMMARY,
    LOADSTATEMENTS,
    RESETSTATEMENT,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.summary }, action) {

    switch (action.type) {
        case LOADSUMMARY:
            return { ...state, summary: { ...action.summary } }

        case LOADSTATEMENTS:
            return { ...state, statement: [...action.statement] };
        case RESETSTATEMENT:
            return { ...state, statement: [] };
        default:
            return { ...state };
    }
}