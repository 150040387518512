import React from 'react';
import ReactDOM from 'react-dom';

import { LOADING } from "./constant/constant"
import App from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import { dangerNotification } from "./action/notificationAction"

import { removeLocal } from "./constant/resetLocal";

import * as serviceWorker from './serviceWorker';
import './assets/index.css';
import 'react-notifications-component/dist/theme.css'
import { history } from './app/history';
import axios from 'axios';

export const pAxios = axios.create();

pAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");

  if (token)
    config.headers.common['x-access-token'] = token;

  store.dispatch({ type: LOADING, isLoading: true });
  return config;
});

pAxios.interceptors.response.use(response => {
  store.dispatch({ type: LOADING, isLoading: false });
  return response;
}, error => {

  const originalReq = error.config;

  store.dispatch({ type: LOADING, isLoading: false });

  if (error.response.status === 401 && originalReq.url.includes('refreshToken')) {
           history.push('/login');
           return Promise.reject(error);
  }

  if (error.response.status === 401 && error.response.data && error.response.data.message === "Token Expired!" && !originalReq._retry) {
    //store.dispatch(refreshToken());
    return pAxios({
      "method": "POST",
      "url": "/api/auth/refreshToken",
      "headers": {
        'content-type': 'application/json'
      },
      "data": JSON.stringify({
        "username": localStorage.getItem("userName"),
        "refreshToken": localStorage.getItem("refreshToken")
      })
    }).then(response => {

      if (response.data && response.data.accessToken) {

        localStorage.setItem("token", response.data.accessToken);
        originalReq.headers['x-access-token']=response.data.accessToken;
        originalReq._retry=true;
        return pAxios(originalReq);
      }

    })
  }
  else if (error.response.status === 401) {
    console.log("401")
    removeLocal();
    history.push("/");

    return Promise.reject(error);
  }
  else if (error.response.status === 403) {
    store.dispatch(dangerNotification("Access Restricted; You are not authorized for performing this operation. Please contact System Administrator"));
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
