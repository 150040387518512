import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Card, Row, Col, Nav, Tab, Modal } from "react-bootstrap";

import { loadSummary } from "../action/summaryAction"
import { ExpenseStatment } from "./ViewExpenses";

export default function Summary() {


    const dispatch = useDispatch();
    const [currentPage,] = useState(1);
    const [vendor, setVendor] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [expense, setExpense] = useState([]);

    const [account, setAccount] = useState({ cash: 0, bank: 0 });
    const summary = useSelector(
        (state) => state.summary.summary
    );

    const [show, setShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    React.useEffect(() => {
        dispatch(loadSummary());
    }, [dispatch]);


    React.useEffect(() => {

        if (summary && summary.modes) {

            let { received, paid } = summary.modes;

            let reciveCash = received.filter(rec => rec.mode === "CASH");
            reciveCash = reciveCash ? reciveCash[0].amount : 0;

            let reciveBank = received.filter(rec => rec.mode === "BANK");
            reciveBank = reciveBank ? reciveBank[0].amount : 0;


            let paidCash = paid.filter(rec => rec.mode === "CASH");
            paidCash = paidCash ? paidCash[0].amount : 0;

            let paidBank = paid.filter(rec => rec.mode === "BANK");
            paidBank = paidBank ? paidBank[0].amount : 0;

            setAccount({ cash: (reciveCash - paidCash), bank: (reciveBank - paidBank) });

        }

        if (summary && summary.vendor) {
            setVendor([...summary.vendor]);
        }
        if (summary && summary.customer) {
            setCustomer([...summary.customer])
        }
        if (summary && summary.expense) {
            setExpense([...summary.expense])
        }
    }, [summary]);

    const handleClose = () => {
        setSelectedUser(null);
        setShow(false);
    }

    const handleShow = (userId) => {
        setSelectedUser(userId);
        setShow(true);

    }

    const getPage = () => {
        return 0;
    }
    const getNextPage = () => {
    }
    const getPrevPage = () => {
    }

    const generateRows = (object, direction) => {

        return (object ? object.map((pur, i) => {
            return (
                <Col md={12} sm={6} key={i} className="mt-1">
                    <Card border="secondary" >
                        <Card.Body className="p-1 ">

                            <Container fluid>
                                <Row className="ps-1 pe-1">
                                    <Col className="text-start p-0 ">
                                        <i className="fa fa-user"></i>
                                        <strong> {pur.name} </strong></Col>
                                    <Col className="text-end p-0 border-start tiles"> <p className="text-secondary m-0"><i className="fa"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                    </svg></i> <span> &#x20b9;{pur.walletAmount} </span></p></Col>
                                </Row>
                                <Row className="ps-1 pe-1 border-top">
                                    <Col className="p-0" title="Total billed amount">

                                        <p className="m-0">
                                            <i className={`fa fa-arrow-${direction === "P" ? "down text-success" : "up text-danger"}`}></i>
                                            <span className=""> &#x20b9;{pur.totalBillAmount ? (Number(pur.totalBillAmount)-(pur.discountAmount?Number(pur.discountAmount):0)).toFixed(2) : Number(0).toFixed(2)} </span>
                                        </p>
                                    </Col>
                                    <Col className=" p-0 text-center border-start border-end" title="Total paid amount">
                                        <p className="m-0">
                                            <i className={`fa fa-arrow-${direction === "P" ? "up text-danger" : "down text-success"}`}></i>
                                            <span className=""> &#x20b9;{pur.paidAmount ? pur.paidAmount : Number(0).toFixed(2)} </span>
                                        </p>

                                    </Col>
                                    <Col className=" p-0 text-end" title="Remaining billed amount to be paid" >
                                        <p className="m-0">
                                            <i className={`fa fa-arrow-circle-${direction === "P" ? "up text-danger" : "down text-success"}`}></i>
                                            <span className="ps-1"> &#x20b9;{direction === "P" ?
                                                (pur.totalPayable ? pur.totalPayable : Number(0).toFixed(2))
                                                : (pur.totalReceivable ? pur.totalReceivable : Number(0).toFixed(2))}
                                            </span>
                                        </p>

                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>)
        }) : (""))

    }

    const generateExpenseRows = (object) => {

        return (object ? object.map((pur, i) => {
            return (
                <Col md={12} sm={6} key={i} className="mt-1">
                    <Card border="secondary" >
                        <Card.Body className="p-1 ">

                            <Container fluid >
                                <Row className="ps-1 pe-1">
                                    <Col className="p-0 ">
                                        <i className="fa fa-user"></i>
                                        <Button variant="link" onClick={() => { handleShow(pur.id) }} size="sm"><strong  > {pur.name} </strong> </Button>
                                    </Col>
                                    <Col className="border-start text-end p-0 tiles"> <p className="text-secondary m-0"><i className="fa"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                    </svg></i> <span> &#x20b9;{pur.walletAmount} </span></p></Col>
                                </Row>
                                <Row className="ps-1 pe-1 border-top">
                                    <Col className="p-0" title="Last spend">
                                        {/* 
                                        <p className="m-0">
                                            <i className={`fa fa-calendar`}></i>
                                            <span className=""> {` ${pur.lastSpentOn}`}</span>
                                        </p> */}
                                    </Col>

                                    <Col className=" p-0 text-end border-start" title="Total spend" >
                                        <p className="m-0">
                                            <i className={`fa fa-arrow-circle-up text-danger`}></i>
                                            <span className="ps-1"> &#x20b9;{pur.totalBillAmount ? pur.totalBillAmount : Number(0).toFixed(2)} </span>
                                        </p>

                                    </Col>
                                </Row>

                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }) : (""))

    }


    const payable = ({ totalPaid = 0, totalPayable = 0 }) => {
        return Number(totalPayable).toFixed(2);
    }

    const receivable = ({ totalReceivable = 0, totalReceived = 0 }) => {

        return Number(totalReceivable).toFixed(2);
    }

    return (
        <div>
            {
                (
                    <React.Fragment>
                        <Container fluid className="p-1">

                            <Card border="secondary" >

                                <Card.Body className="p-0" >

                                    <Container fluid className="p-0">
                                        <Row className="gx-0">

                                            <Col className="col-8 border-end" >
                                                <Row className="gx-0">
                                                    <Col className="border-bottom text-center">
                                                             <p className="text-success m-0 fw-bold"><i className="fa fa-arrow-circle-down"></i> &#x20b9;{summary.summary  ? receivable(summary.summary[0]) : Number(0).toFixed(2)} </p>
                                                             <p className="text-secondary m-0 " style={{ fontSize: ".7rem" }}>(Receivable)</p>
                                                    </Col>
                                                    
                                                    
                                                </Row>
                                                <Row className="gx-0">
                                                    <Col className="border-bottom text-center">
                                                             <p className="text-danger m-0 fw-bold"><i className="fa fa-arrow-circle-up"></i> &#x20b9;{summary.summary  ? payable(summary.summary[0]) : Number(0).toFixed(2)} </p>
                                                             <p className="text-secondary m-0" style={{ fontSize: ".7rem" }}>(Payable)</p>
                                                    </Col>
                                                </Row>
                                                <Row className="gx-0">
                                                <Col className="border-bottom text-center">
                                                             <p className="text-warning m-0 fw-bold" ><i className="fa fa-cart-arrow-down"></i> &#x20b9;{summary.stock  ? Number(summary.stock).toFixed(2) : Number(0).toFixed(2)} </p>
                                                             <p className="text-secondary m-0" style={{ fontSize: ".7rem" }}>(Stock)</p>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className="col-4">
                                                <Row style={{ "height": "50%" }} className="border-bottom gx-0">

                                                    <div className="d-flex align-items-center justify-content-center flex-wrap">

                                                        <p className=" m-0 fw-bold"><i className="fa fa-university text-secondary" style={{ fontSize: ".7rem" }}></i> <span className="m-0 text-success">&#x20b9;{Number(account.bank).toFixed(2)} </span></p>
                                                        <p className="text-secondary m-0 d-block" style={{ fontSize: ".7rem" }}>(Bank)</p>
                                                    </div>
                                                </Row>
                                                <Row style={{ "height": "50%" }} className="gx-0">
                                                    <div className="d-flex align-items-center justify-content-center flex-wrap">

                                                        <p className=" text-success m-0 fw-bold"><i className="fa fa-money text-secondary" style={{ fontSize: ".7rem" }}></i> <span className="m-0 text-success">&#x20b9;{Number(account.cash).toFixed(2)} </span> </p>
                                                        <p className="text-secondary m-0" style={{ fontSize: ".7rem" }}>(Cash)</p>
                                                    </div>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>

                         

                            <Card className={`mb-0 mt-1`} border="dark">

                                <Tab.Container defaultActiveKey="recivable" id="tab">
                                    <Card.Header className="bg-dark">

                                        <Nav variant="tabs" onSelect={(e) => { }}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="recivable">Purchase</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="payable">Sales</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="expense">
                                                    Expense
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>

                                    </Card.Header>





                                    <Card.Body className="p-0" style={{ "height": "59vh", "overflowX": "auto" }}>
                                        <Container fluid>

                                            <Tab.Content className="mt-2">
                                                <Tab.Pane eventKey="recivable" title="Recivable">

                                                    <Row className="g-2 mb-2">

                                                        {

                                                            generateRows(vendor, "P")

                                                        }

                                                    </Row>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="payable" title="Payable">
                                                    {
                                                        generateRows(customer, "S")
                                                    }
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="expense" title="Expense">


                                                    {
                                                        generateExpenseRows(expense)
                                                    }

                                                </Tab.Pane>
                                            </Tab.Content>



                                        </Container>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row>
                                            <Col className="me-auto col-auto" >

                                                <Button variant="primary" size="sm" disabled={currentPage === 1} onClick={getPrevPage}>Prev</Button>
                                            </Col>

                                            <Col className="mx-auto col-auto">

                                                <button className="btn btn-sm">{currentPage > 1 ? (`pg#:${currentPage}`) : ""}</button>
                                            </Col>
                                            <Col className="ms-auto col-auto">

                                                <Button variant="primary" className="float-end" size="sm" disabled={!getPage() || Number(currentPage) === Number(getPage())} onClick={getNextPage}>Next</Button>
                                            </Col>
                                        </Row>



                                    </Card.Footer>
                                </Tab.Container>
                            </Card>
                        </Container>

                        <Modal show={show} onHide={handleClose} centered >
                            <Modal.Header closeButton >
                                <Modal.Title as="h6">Statement</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-0">
                                <ExpenseStatment userId={selectedUser} reconsiled='N'></ExpenseStatment>
                            </Modal.Body>
                            <Modal.Footer></Modal.Footer>
                        </Modal>

                    </React.Fragment>
                )

            }
        </div >
    );
}
