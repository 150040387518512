import {LOADALLPRODUCT,LOADPRODUCT,
        ADDPRODUCTTYPE,CHANGEPRODUCTTYPE,REMOVEPRODUCTTYPE,
        CHANGEPRODUCT,RESETPRODUCT,LOADPRODUCTLOOKUPS,
        LOADSTOCKS,RESETSTOCKS,
        SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


function loadAllProductService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/products?status=${status&&status==='active'?'active':'dormant'}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLPRODUCT, product: (response.data) });
           }
           else{
            resolve({ type: LOADALLPRODUCT, product: []});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load product data",variant:"danger",err}  })
            });
    });

}



function loadProductLookupService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/products`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADPRODUCTLOOKUPS, productLookup: (response.data.rows) });
           }
           else{
            resolve({ type: LOADPRODUCTLOOKUPS, productLookup: []});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load product lookup",variant:"danger",err}  })
            });
    });

}


function loadProductService(productId){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/products/${productId}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADPRODUCT, product: (response.data) });
           }
           else{
            resolve({ type: LOADPRODUCT, product: {}});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load product data",variant:"danger",err}  })
            });
    });


}

function saveProductService(product){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/products`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(product)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Product saved successfully", variant: "success", err: "" }
                });

            resolve({ type: RESETPRODUCT});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving product",variant:"danger",err}  })
            });
    });
  
  }

  function activeProductService(productIds){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/products/status/active`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(productIds)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Product updated", variant: "success", err: "" }
                });

            resolve(loadAllProductService("dormant"));

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while updating product",variant:"danger",err}  })
            });
    });
  
  }

  
  function loadStocksService(date){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/subtypes/stocks${date?`?date=${date}`:''}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADSTOCKS, stocks: (response.data) });
           }
           else{
            resolve({ type: LOADSTOCKS, stocks: []});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load stocks",variant:"danger",err}  })
            });
    });

}

  
 export const saveProduct=(product)=>{

    return dispatch => {

        saveProductService(product)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



 export const loadAllProduct=(status)=>{

    return dispatch => {

        loadAllProductService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadProduct=(productId)=>{

  return dispatch => {

    loadProductService(productId)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));

  }
}

export const loadStocks=(date)=>{

    return dispatch => {
  
        loadStocksService(date)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  }



export const changeProduct=({name,value})=>{

    return dispatch => {
      dispatch({type:CHANGEPRODUCT,name,value});
  }

}
export const resetProduct=()=>{
   
   return dispatch=>{

       dispatch({type:RESETPRODUCT});
   }
}

export const resetStocks=()=>{
   
    return dispatch=>{
 
        dispatch({type:RESETSTOCKS});
    }
 }
 

export const changeProducType=(subtype)=>{

    return dispatch => {
      dispatch({type:CHANGEPRODUCTTYPE,subtype});
    }
    
    
    }
    
export const addProductType=(subtype)=>{
    
    return dispatch => {
      dispatch({type:ADDPRODUCTTYPE,subtype});
    }
    
    }
    
export const removeType=(idx)=>{
    
    return dispatch => {
      dispatch({type:REMOVEPRODUCTTYPE,idx});
    }
}


export const loadProductLookups=()=>{

    return dispatch => {

        loadProductLookupService()
              .then(response => dispatch(response))
              .catch(err => dispatch(err));
    
      }
}

export const updateStatus=(productIds)=>{

    return dispatch => {

        activeProductService(productIds)
              .then(response => dispatch(response))
              .catch(err => dispatch(err));
    
      }
    
    
}