import {
    LOADSTOCKS,
    
    RESETSTOCKS,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.stocks }, action) {

    switch (action.type) {
        case LOADSTOCKS:
            return { ...state, stocks: [ ...action.stocks] }

       
        case RESETSTOCKS:
            return { ...state, stocks: [] };
        default:
            return { ...state };
    }
}