import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Row, Form, Button, Card, Nav } from "react-bootstrap";

import { formatDate } from "../constant/dateFormat";

import { changeExpense, resetExpense, saveExpense, cancelEdit, loadAllExpenses, loadExpenseById } from "../action/expenseAction"
import { loadAllUser } from "../action/userAction";
import { isEmployee, isPermitted } from "../constant/permission"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

export default function Expense() {



    const [mode, setMode] = useState("new");
    const [editMode, setEditMode] = useState(false);
    const [currentUser, setCurrentUser] = useState();
    const dispatch = useDispatch();

    const lookup = JSON.parse(localStorage.getItem("lookup"));

    const users = useSelector(
        (state) => state.user.users
    );

    const expense = useSelector(
        (state) => state.expense.expense
    );

    const expenses = useSelector(
        (state) => state.expense.expenses
    );



    const [errors, setErrors] = useState({});



    const handleSubmit = (e) => {


        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors);
        } else {

            dispatch(saveExpense({ ...expense, billDate: formatDate(expense.billDate)}));

        }
    }


    const findFormErrors = () => {

        const { billDate, categoryId, transferTo } = expense
        const newErrors = {}

        if (!billDate || billDate === '') newErrors.billDate = 'Date should not be blank!'
        if (!categoryId || categoryId === '' || categoryId === 0) newErrors.categoryId = "Select a expense category"
        if (categoryId && (categoryId === 10 || categoryId === 3)) {
            if (!transferTo || transferTo === 0) newErrors.transferTo = "Select a user to transfer";
        }

        return newErrors
    }

    const setField = (field, value) => {


        dispatch(changeExpense({ name: field, value }));


        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })


        if(field==="categoryId"){
            dispatch(changeExpense({ name: "transferTo", value:null }));
        }

        if (mode === "edit" && field === "billDate") {
            dispatch(loadAllExpenses({ userId: localStorage.getItem("userId"), date: formatDate(field === "billDate" ? value : expense.billDate) }));
        }
        else if (mode === "edit" && field === "id" && value !== 0) {
            dispatch(loadExpenseById(value));
            setEditMode(true)
        }
        else if (mode === "edit" && field === "id" && value === 0) {
            setEditMode(false);
            dispatch(cancelEdit());
        }

    }

    const setDecimalField = (field, value) => {
        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;
        if (value === "" || re.test(value)) {
            dispatch(changeExpense({ name: field, value }));
        }
    }

    const setOnBlur = (field, value) => {
        dispatch(changeExpense({ name: field, value: Number(Number(value).toFixed(2)) }));
    }


    React.useEffect(() => {

        if (expense && !expense.billDate) {
            dispatch(changeExpense({ name: "billDate", value: new Date() }))
            setEditMode(false);
        }

        setCurrentUser(localStorage.getItem("userId") ? Number(localStorage.getItem("userId")) : null)
    }, [dispatch, mode, expense]);




    React.useEffect(() => {
        dispatch(loadAllUser());

    }, [dispatch]);





    return (
        <Container className="mt-3  col-11">

            <Card border="dark" >
                <Card.Header className="bg-dark">
                    <Nav variant="tabs" defaultActiveKey={mode}
                        onSelect={(e) => {
                            setMode(e);
                            dispatch(resetExpense());
                            setEditMode(false);
                        }}>
                        <Nav.Item>
                            <Nav.Link eventKey={"new"}>New  </Nav.Link>
                        </Nav.Item>
                        {
                            isPermitted("EDIT_EXPENSE") ? (<Nav.Item>
                                <Nav.Link eventKey={"edit"}>Edit </Nav.Link>
                            </Nav.Item>) : ("")
                        }


                        <Nav.Item className="col ms-auto">

                            <h6 className="nav-link float-end mb-0">
                                Expense
                            </h6></Nav.Item>
                    </Nav>

                </Card.Header>
                <Card.Body className="p-0"  >
                    <Container>
                        <Form >
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker disabled={editMode} maxDate={new Date()} dateFormat="dd-MM-yyyy" className={`form-control form-control-sm ${!!errors.date ? "is-invalid" : ""}`} isInvalid={true} selected={expense && expense.billDate ? expense.billDate : null} onChange={(date) => setField("billDate", date)} />

                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Category </Form.Label>
                                        <Form.Select  size="sm" as="select" isInvalid={!!errors.categoryId} onChange={e => setField("categoryId", Number(e.target.value))} value={expense && expense.categoryId ? expense.categoryId : 0}>

                                            <option value={0}>Select expense Category</option>
                                            {
                                                lookup.expenseCategory.filter(cat => (cat.name !== "Salary" || !isEmployee())).map(cat => {
                                                    return <option value={cat.id}>{cat.name}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                expense && expense.categoryId && (expense.categoryId === 10 || expense.categoryId === 3) ? (<Row className="g-2 mb-2">
                                    <Col >

                                        <Form.Group >
                                            <Form.Label>Transfer To</Form.Label>
                                            <Form.Select size="sm" as="select" isInvalid={!!errors.transferTo} onChange={e => setField("transferTo", Number(e.target.value))} value={expense && expense.transferTo ? expense.transferTo : 0}>

                                                <option value={0}>Select User</option>
                                                {
                                                    users ? users.filter(user => user.role !== "CUSTOMER" && user.role !== "VENDOR" && Number(user.id) !== currentUser).map((user, idx) => {
                                                        return <option key={idx} value={user.id}>{user.username}</option>;
                                                    }) : ("")
                                                }

                                            </Form.Select>
                                        </Form.Group>


                                    </Col>
                                </Row>) : ("")

                            }


                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control size="sm" placeholder="Amount" as="input"
                                            onChange={e => setDecimalField("amount", e.target.value)}
                                            onBlur={(e) => {
                                                setOnBlur("amount", e.target.value)
                                            }}
                                            value={expense && expense.amount ? expense.amount : ""}></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Bill No / Remarks</Form.Label>
                                        <Form.Control size="sm" placeholder="Bill Number" as="input" isInvalid={!!errors.billNo} onChange={e => setField("billNo", e.target.value)} value={expense && expense.billNo ? expense.billNo : ""}></Form.Control>

                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >

                                        {mode && mode === "new" ?
                                            ("")

                                            : (
                                                <React.Fragment>
                                                    <Form.Label>Expense Id</Form.Label>
                                                    <Form.Select size="sm" as="select" onChange={e => setField("id", Number(e.target.value))} value={expense && expense.id ? expense.id : 0}>
                                                        <option value={0}>Select a bill </option>
                                                        {
                                                            expenses ? expenses.map((exp, i) => {
                                                                return <option value={exp.id} key={i}>{`${exp.id}-${exp.category}`}</option>;
                                                            }) : ("")
                                                        }
                                                    </Form.Select>
                                                </React.Fragment>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
                <Card.Footer>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                        {
                            mode === "edit" ? (
                                <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={Number(expense && expense.amount ? expense.amount : 0) === 0}>Update</Button>
                            ) :
                                (
                                    <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={Number(expense && expense.amount ? expense.amount : 0) === 0}>Save</Button>)
                        }
                        {
                            mode === "edit" && editMode ? (<Button variant="danger" size="sm" onClick={() => { setEditMode(false); dispatch(cancelEdit()) }}>Cancel</Button>) : ("")
                        }
                    </div>

                </Card.Footer>
            </Card>



        </Container>
    );
}
