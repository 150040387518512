import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row, Col, Form, Card ,Nav} from "react-bootstrap";
import ProductEntry from "./ProductEntry";

import Avatar from 'react-avatar';
import { loadAllProduct, updateStatus } from '../action/productAction';


export default function Product() {

    const [status,setStatus]=useState("active"); 
    const dispatch = useDispatch();

    const products = useSelector(
        (state) => state.product.products
    );

    const [show, setShow] = useState(false);
    const [filterStr, setFilterStr] = useState("");
    const [selectedIds, setSelectedIds] = useState({});

    const handleClose = () => {
        setShow(false);
        setSelectedProduct(null);
    }

    const handleShow = (id) => {
        setShow(true);
        setSelectedProduct(id);
    }

    const toggleSelect = (id) => {

        let status = selectedIds[id];
        setSelectedIds({ ...selectedIds, [id]: status && status === "Y" ? "N" : "Y" })

    }
    const [selectedProduct, setSelectedProduct] = useState();


    React.useEffect(() => {
        dispatch(loadAllProduct(status));
    }, [dispatch,status]);


    const activeProduct = () => {

        const ids = Object.keys(selectedIds).filter((k) => selectedIds[k] === "Y").map((k) => k);

        if (ids) {

            dispatch(updateStatus(ids));
        }

    }


    return (
        <div>
            {
                !show && !selectedProduct ?
                    (<Container fluid>
                        <Card border="dark" className={`mb-0 mt-1`}>

                            <Card.Header className="bg-dark">
                                {/* <Nav variant="pills" defaultActiveKey={status} onSelect={(e) => setStatus(e)}>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"active"}>Active</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"dormant"}>Dormant</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className="searchBar col-sm pt-2 pt-sm-0">

                                        <div className="float-md-end">
                                            <Form.Control type="text" placeholder={`Search ${products ? products.length : 0} records`} onChange={(e) => setFilterStr(e.target.value)} />
                                        </div></Nav.Item>
                                </Nav> */}
                                Products

                                <div className="float-md-end">
                                    <Form.Control type="text" placeholder={`Search ${products ? products.length : 0} records`} onChange={(e) => setFilterStr(e.target.value)} />
                                </div>
                            </Card.Header>

                            <Card.Body className=" p-0" style={{ "height": "65vh", "overflowX": "auto" }}>
                                <Container className="p-1" fluid>

                                    <Row className="g-2 justify-content-center">
                                        {products.filter((product) => !filterStr || product.text.toLowerCase().includes(filterStr.toLowerCase())).map((product, i) => {
                                            return <Col xs="auto" md="auto" className="text-center" key={i}>
                                                {
                                                    status && status === 'active' ?
                                                        (
                                                            <Button variant="link" className="text-decoration-none" onClick={() => {
                                                                handleShow(product.id);
                                                            }}>
                                                                <div>
                                                                    <Avatar className="fw-bold" className="p-1" value={product.text.substring(0, 2).toUpperCase()} round={true} size="60" textSizeRatio={1} />
                                                                </div>
                                                                <span>{product.text}</span>
                                                            </Button>
                                                        ) :
                                                        (<Button variant="link" className="text-decoration-none" onClick={() => {
                                                            toggleSelect(product.id);
                                                        }}>
                                                            <div>
                                                                {selectedIds[product.id] && selectedIds[product.id] === "Y" ?
                                                                    (<i className="fa-4x fa fa-check-circle"></i>)
                                                                    : (<Avatar className="fw-bold" className="p-1" value={product.text.substring(0, 2).toUpperCase()} round={true} size="60" textSizeRatio={1} />)}
                                                            </div>
                                                            <span>{product.text}</span>
                                                        </Button>
                                                        )
                                                }
                                            </Col>;
                                        })}
                                    </Row>

                                </Container>
                            </Card.Body>
                            {status && status === 'active' ? ("") : (<Card.Footer>
                                <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                                    <Button variant="primary" size="sm" onClick={activeProduct}>Active</Button>
                                </div>
                            </Card.Footer>)}
                        </Card>
                    </Container>)
                    : (<ProductEntry selectedProduct={selectedProduct} handleClose={handleClose} />)
            }
        </div>
    );
}
