import {RESETSALE,CHANGESALE,
    CHANGEITEMSALE,ADDITEMSALE,
    RESETITEMSALE,
    LOADALLSALE,
    LOADSALE,
    SET_NOTIFICATION,
    RESETSALEEDIT,
    CANCELEDITSALE} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


export function loadAllSaleService(url){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/sales?${url}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLSALE, sales: (response.data) });
           }
           else{
            resolve({ type: LOADALLSALE, sales: []});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load sale data",variant:"danger",err}  })
            });
    });

}

function loadSaleService(saleId){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/sales/${saleId}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADSALE, sale:{...response.data, billDate: (response.data.billDate ? new Date(response.data.billDate) : response.data.billDate) } });
           }
           else{
            resolve({ type: LOADSALE, sale: {}});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load sale data",variant:"danger",err}  })
            });
    });

}


function saveSaleService(sales,editOnly){

return new Promise(function (resolve, reject) {

    pAxios({
        "method": "POST",
        "url": `/api/sales`,
        "headers": {
            'content-type': 'application/json'
        },
        "data":JSON.stringify(sales)
      
    }).then(response => {

       if(response.data){
           

        if(editOnly){
            
            resolve({
                type: SET_NOTIFICATION, notification:
                    { message: "Sales bill successfully", variant: "success", err: "" }
                });
        }
        else{

            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Sales bill successfully", variant: "success", err: "" }
                });

            if(sales.id){
    
                resolve({ type: RESETSALEEDIT});
            } 
            else{
                resolve({ type: RESETSALE});
            }   
        }


       }
    })
        .catch(err => {
            
            reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving sale bill",variant:"danger",err}  })
        });
});

}


function deleteSaleService(id){

    return new Promise(function (resolve, reject) {
    
        pAxios({
            "method": "DELETE",
            "url": `/api/sales/${id}`,
            "headers": {
                'content-type': 'application/json'
            },
        }).then(response => {
    
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Sales bill deleted", variant: "success", err: "" }
                });
    
           
                resolve({ type: RESETSALE});
             
    
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while deleting sales bill",variant:"danger",err}  })
            });
    });
    
    }
    




export const loadAllSales=({date=null,billNumber=null,reconsiled=null,customerId=null,limit=null,status=null,fromDate=null,toDate=null,page=null})=>{

    let data={};

    if(date){
        data={...data,fromDate:date,toDate:date}
    }
    else if(fromDate && toDate){
        data ={...data,fromDate,toDate}
    }


    if(customerId){
        data={...data,customerId}
    }

    if(limit){
        data={...data,limit}
    }

    if(page){
        data={...data,page}
    }

    if(status){
        data={...data,status}
    }

    if(billNumber){
        data={...data,billNumber}
    }
    if(reconsiled){
        data={...data,reconsiled}
    }
    if(Object.keys(data).length === 0){
        data={limit:10}
    }

    let url = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')

    return dispatch => {
    
        loadAllSaleService(url)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    
    }
    
    }
    

    export const loadSales=(saleId)=>{

        return dispatch => {
        
            loadSaleService(saleId)
                .then(response => dispatch(response))
                .catch(err => dispatch(err));
        
        }
        
        }
            

export const saveSale=(sale,editOnly)=>{

return dispatch => {

    saveSaleService(sale,editOnly)
        .then(response => dispatch(response))
        .catch(err => dispatch(err));

}

}

export const deleteSale=(id)=>{

    return dispatch => {
    
        deleteSaleService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
    
    }
    
    }
    

export const changeSale=({name,value})=>{

return dispatch => {
  dispatch({type:CHANGESALE,name,value});
}

}

export const changeItemSale=(id,value,field)=>{

return dispatch => {
  dispatch({type:CHANGEITEMSALE,id,value,field});
}
}

export const addItemSale=(product)=>{

    return dispatch => {
      dispatch({type:ADDITEMSALE,product});
    }
    

}

export const resetItemSale=()=>{

    return dispatch => {
        dispatch({type:RESETITEMSALE});
      }

   
}

export const resetSale=()=>{

    return dispatch => {
        dispatch({type:RESETSALE});
      }
}

export const cancelEditSale=()=>{

    return dispatch => {
        dispatch({type:CANCELEDITSALE});
      }

   
}