import {
    LOADCUSTOMER,
    LOADALLCUSTOMER,
    RESETCUSTOMER,
    CHANGECUSTOMER,
    CHANGECUSTOMERPRODUCT,
    ADDCUSTOMERPRODUCT,
    REMOVECUSTOMERPRODUCT,
    initialState,
    sortByName,
    sortByText
} from "../constant/constant";

function sortProductName(customer){


    return {...customer,products:sortByText(customer.products)}
}


export default function reducer(state = { ...initialState.vendor }, action) {

    switch (action.type) {
        case LOADALLCUSTOMER:
            return { ...state, customers: [...sortByName(action.customer.rows)] };

        case LOADCUSTOMER:
            return { ...state, customer: { ...sortProductName(action.customer) } };

        case CHANGECUSTOMER:
            return { ...state, customer: { ...state.customer, [action.name]: action.value } };

        case ADDCUSTOMERPRODUCT:
            return {
                ...state, customer: {
                    ...state.customer, products: [...(state.customer.products ?
                        [...state.customer.products, { ...action.product,idx:state.customer.products.length+1 }] : ([{ ...action.product,idx:1 }]))]
                }
            };
        case REMOVECUSTOMERPRODUCT:
            return {
                ...state, customer: {
                    ...state.customer, products: [
                        ...(state.customer.products.filter((product) =>
                            product.idx !== action.idx

                        ))
                    ]
                }
            };
        case CHANGECUSTOMERPRODUCT:
            return {
                ...state, customer: {
                    ...state.customer, products: [
                        ...(state.customer.products.map((product) => {
                            if (product.idx === action.product.idx)
                                return { ...action.product };
                            return product;
                        }))
                    ]
                }
            };
        case RESETCUSTOMER:
            return { ...state, customer: {} };
        default:
            return { ...state };
    }
}