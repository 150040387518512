import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, FloatingLabel, Col, Row, Form, Button, Card, Modal } from "react-bootstrap";

import { warningNotification } from "../action/notificationAction";
import { loadCustomer, changeCustomer, saveCustomer, resetCustomer, changeCustomerProduct, addCustomerProduct, removeCustomerProduct } from "../action/customerAction";
import { loadProductLookups } from "../action/productAction";
import ProductLookup from "../component/ProductLookup";
import { isPermitted } from "../constant/permission"
import {numberCheck} from "../constant/numberCheck"

export default function CustomerEntry({ viewalone, ...userProps }) {

    const [props,] = React.useState(userProps);

    const [errors, setErrors] = useState({});

    const [selProduct, setSelProduct] = useState({});



    const customer = useSelector(
        (state) => state.customer.customer
    );

    const productLookup = useSelector((state) => state.productLookup.productLookup);

    const [show, setShow] = useState(false);

    const handleClose = (product) => {

        if (product && product.idx) {

            if (customer && customer.products) {
                let filter = customer.products.filter((cus) => cus.id === product.id && cus.idx !== product.idx);

                if (filter === null || filter.length === 0) {
                    dispatch(changeCustomerProduct(product));

                }
                else {
                    dispatch(warningNotification("Product already mapped"));
                    return;
                }
            }
            else {
                dispatch(changeCustomerProduct(product));
            }
        }
        else if (product) {
            if (customer && customer.products) {
                let filter = customer.products.filter((cus) => cus.id === product.id);

                if (filter === null || filter.length === 0) {
                    dispatch(addCustomerProduct(product));

                }
                else {
                    dispatch(warningNotification("Product already mapped"));
                    return;
                }
            }
            else {
                dispatch(addCustomerProduct({ ...product, idx: (customer && customer.products ? customer.products.length + 1 : 1) }));
            }
        }

        setShow(false);
        setSelProduct({});

    }

    const removeProduct = (idx) => {
        dispatch(removeCustomerProduct(idx));
    }

    const handleShow = (selProduct) => {
        if (selProduct != null) {
            setSelProduct({ ...selProduct });
        }
        setShow(true);
    }

    const dispatch = useDispatch();


    React.useEffect(() => {
        if (props && props.selectedCustomer) {
            dispatch(loadCustomer(props.selectedCustomer));
        }
    }, [dispatch]);


    React.useEffect(() => {
        dispatch(loadProductLookups());

    }, [dispatch]);

    React.useEffect(() => {
        dispatch(resetCustomer());
    }, [dispatch]);


    const setField = (field, value) => {
        dispatch(changeCustomer({ name: field, value }))
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleSubmit = (e, method) => {
        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors);
        } else {

            dispatch(saveCustomer({ ...customer, status: (props && props.selectedCustomer ? customer.status : 'active'), isRetail: customer.isRetail ? customer.isRetail : 'N' }, method));

            if (props && props.handleClose) {
                props.handleClose();
            }
        }
    }

    const findFormErrors = () => {
        const { name, code, address, phone, password } = customer
        const newErrors = {}

        if (!name || name === '') newErrors.name = 'Name should not be blank!'
        if (!code || code === '') newErrors.code = 'Code should not be blank!'
        // if (!gstNo || gstNo === '') newErrors.gstNo = 'Code should not be blank!'
        if (!address || address === '') newErrors.address = 'Address cannot be blank!'
        if (!phone || phone === '') newErrors.phone = 'Address cannot be blank!'
        if ((props && props.selectedCustomer)) {

        }
        else if (!password || password === '') {
            newErrors.password = 'password cannot be blank!'
        }

        return newErrors
    }





    return (
        <Container className="mt-3 col-11 ">

            <Card border="dark" >
                <Card.Header as="h6" className="bg-dark">New Customer</Card.Header>
                <Card.Body className="p-0" style={{ "height": "70vh", "overflowX": "auto" }} >
                    <Container>
                        <Form >
                            <Row className="g-2 mb-2 mt-2">
                                <Col >

                                    <FloatingLabel label="Customer Name">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Vendor Name" isInvalid={!!errors.name} onChange={e => setField('name', e.target.value)} value={customer && customer.name ? customer.name : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.name} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="Customer Code">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Vendor Code" isInvalid={!!errors.code} onChange={e => setField('code', e.target.value)} value={customer && customer.code ? customer.code : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.code} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >

                                    <FloatingLabel label="Address">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="Address" isInvalid={!!errors.address} onChange={e => setField('address', e.target.value)} value={customer && customer.address ? customer.address : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.address} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="GSTIN">
                                        <Form.Control disabled={viewalone} size="sm" type="text" placeholder="GSTIN" isInvalid={!!errors.gstNo} onChange={e => setField('gstNo', e.target.value)} value={customer && customer.gstNo ? customer.gstNo : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.gstNo} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            {/* <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="Manager">
                        <Form.Control size="sm" type="text" placeholder="Manager"  isInvalid={!!errors.manager} onChange={e => setField('manager', e.target.value)} value={form.manager ?form.manager:(props && props.manager?props.manager:"")}/>
                        <Form.Control.Feedback type='invalid'>{errors.manager} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                </Row> */}
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="Phone">
                                        <Form.Control disabled={props && props.selectedCustomer ? true : false} size="sm" type="text" placeholder="Phone Number" isInvalid={!!errors.phone} onKeyPress={numberCheck} maxlength="10" onChange={e => setField('phone', e.target.value)} value={customer && customer.phone ? customer.phone : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.phone} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>

                            {props && props.selectedCustomer ? (
                                <Row className="g-2 mb-2">

                                    <Form.Group >

                                        <Form.Check disabled={viewalone} type="checkbox" label="Active ?" checked={customer && customer.status && customer.status === "active" ? true : false} onChange={e => setField('status', e.target.checked ? 'active' : 'dormant')} id="custActive"/>

                                    </Form.Group>

                                </Row>) :
                                <Row className="g-2 mb-2">
                                    <Col >
                                        <FloatingLabel label="Password">
                                            <Form.Control size="sm" type="password" placeholder="Password" isInvalid={!!errors.userName} onChange={e => setField('password', e.target.value)} value={customer && customer.password ? customer.password : ""} />
                                            <Form.Control.Feedback type='invalid'>{errors.password} </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                            }

                            <Row className="g-2 mb-2">

                                <Form.Group >

                                    <Form.Check disabled={viewalone} type="checkbox" label="Retail Customer ?" id="custRetail" checked={customer && customer.isRetail && customer.isRetail === "Y" ? true : false} onChange={e => setField('isRetail', e.target.checked ? 'Y' : 'N')} />

                                </Form.Group>

                            </Row>

                        </Form>
                    </Container>
                    <Container className="mb-2">
                        <Card>
                            <Card.Header as="h6" className="bg-dark">Product Mapping
                                {
                                    isPermitted("NEW_CUSTOMER") || isPermitted("EDIT_CUSTOMER") ? (
                                        <Button variant="success" size="sm" className="float-end" onClick={() => handleShow()}> Add </Button>)
                                        : ("")
                                }
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-2 mb-2">

                                    {
                                        customer && customer.products ? (

                                            customer.products.map((cus, i) => {
                                                return (
                                                    <Col md={12} sm={4} key={i}>
                                                        <Card border="secondary" >
                                                            <Card.Body className="p-1">
                                                                <Card.Title as="h6">
                                                                    <Button disabled={viewalone} className="p-0" variant="link" size="sm" style={{ fontSize: "inherit" }} onClick={() => handleShow({ ...cus, idx: cus.idx?cus.idx: customer.products.length + 1})}>{cus.text}</Button>
                                                                    {
                                                                        viewalone ? ("") : (<i className="fa fa-trash text-danger float-end" style={{ cursor: "pointer" }} onClick={() => { removeProduct(cus.idx?cus.idx: customer.products.length + 1) }}></i>)
                                                                    }
                                                                </Card.Title>
                                                                <Card.Text>
                                                                    Rs.{cus.price} / {cus.uom.code}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>)
                                            })
                                        ) : ("")
                                    }
                                </Row>
                            </Card.Body>

                        </Card>
                    </Container>

                </Card.Body>
                <Card.Footer>
                    {isPermitted("NEW_CUSTOMER") || isPermitted("EDIT_CUSTOMER") ? (
                        <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                            {
                                props && props.selectedCustomer ? (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                    <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Update</Button>
                                    <Button variant="danger" size="sm" onClick={() => { props.handleClose() }}>Back</Button>
                                </div>) : (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                    <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Save</Button>
                                    <Button variant="danger" size="sm" onClick={() => dispatch(resetCustomer())}>Reset</Button>
                                </div>)
                            }
                        </div>
                    ) : ("")}


                </Card.Footer>
            </Card>


            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Product Mapping</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form size="md">
                        <ProductLookup product={selProduct} productLookup={productLookup} handleClose={handleClose} />

                    </Form>

                </Modal.Body>

            </Modal>

        </Container>);
}