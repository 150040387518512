import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Card, Row, Col, Form, InputGroup, DropdownButton, Dropdown, Alert, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { loadAllPurchases, resetPurchase, loadPurchases,deletePurchase } from '../action/purchaseAction';
import { loadAllSales, resetSale, loadSales,deleteSale } from '../action/saleAction';
import { loadAllCustomer } from "../action/customerAction";
import { loadAllVendor } from "../action/vendorAction";
import { warningNotification } from "../action/notificationAction";
import {ProductListingOption} from "./ProductListing"
import { formatDate, formatDateView } from "../constant/dateFormat";
import { isCustomer, isVendor,isOwner } from "../constant/permission"
import {  Link, } from "react-router-dom";

import {PAGESIZE} from "../constant/constant"

export function Bill({ billType }) {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(resetSale());
        dispatch(resetPurchase());

    }, [dispatch]);

    const header = () => {

        return (<div className="card-header bg-dark rounded-0 p-1">
            <h6 className="float-md-start p-1">
                View {billType === 'purchase' ? 'Purchase' : 'Sales'}

            </h6>
        </div>);
    }

    return (<ViewBills billType={billType} billItem={true} header={header}></ViewBills>);

}

export default function ViewBills({ billType, defltr = {}, billItem = false, ...props }) {


    const dispatch = useDispatch();

    const [availableFilter, setAvailableFilter] = useState([{id:"0",name:"Recent"}, {id:"1",name:"Date Range"}, {id:"2",name:(billType === "purchase" ? "Vendor" : "Customer")},{id:"3",name:"Bill Number"},{id:"4",name: "All"}]);

    const [currentPage, setCurrentPage] = useState(1);

    const [filterType, setFilterType] = useState("0");
    const [selectedIds, setSelectedIds] = useState(0);
    const [srcBillNo,setSrcBillNo]=useState("");
    const [selectedRecent, setSelectedRecent] = useState(0);
    const limit = PAGESIZE;

    const [userFilter, setUserFilter] = useState({});
    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const sale = useSelector(
        (state) => state.sale.sale
    );
    const sales = useSelector(
        (state) => state.sale.sales
    );
    const salePage = useSelector(
        (state) => state.sale.page
    );

    const saleSum = useSelector((state)=>state.sale.sum);
    const saleCount =useSelector((state)=>state.sale.count);

    const purchaseSum=useSelector((state)=>state.purchase.sum);
    const purchaseCount = useSelector((state)=>state.purchase.count);

    const purchase = useSelector(
        (state) => state.purchase.purchase
    );

    const purchases = useSelector(
        (state) => state.purchase.purchases
    );
    const purchasePage = useSelector(
        (state) => state.purchase.page
    );

    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedBill, setSelectedBill] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    React.useEffect(() => {
        dispatch(resetSale());
        dispatch(resetPurchase());


        setFilterType("0");

        setSelectedIds(0);
        setSelectedRecent(0);
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(1);
        setSrcBillNo("");

    }, [dispatch,billType]);



    React.useEffect(() => {

        if (billType === "purchase" && !isVendor()) {
            dispatch(loadAllVendor('active'));

        }
        else if (billType === "sales" && !isCustomer()) {
            dispatch(loadAllCustomer('active'));
        }

        setAvailableFilter([...availableFilter.map(flt => { if(flt.id ==="2" ){ return {...flt,name:billType === "purchase"?"Vendor":"Customer"} } return flt;})]);

        if (isVendor()) {
            setAvailableFilter([...availableFilter.filter(flt => flt.name !== "Vendor")]);
            setUserFilter({ "vendorId": (localStorage.getItem("stakeHolderId") ? Number(localStorage.getItem("stakeHolderId")) : null) });
        }
        if (isCustomer()) {
            setAvailableFilter([...availableFilter.filter(flt => flt.name !== "Customer")]);
            setUserFilter({ "customerId": (localStorage.getItem("stakeHolderId") ? Number(localStorage.getItem("stakeHolderId")) : null) });
        }




    }, [dispatch, billType]);


    const handleClose = () => {
        setShow(false);

        setSelectedBill(null);

    }
    const handleShow = (seletedBill) => {
        if (seletedBill != null) {
            setShow(true);


            if (billType === "purchase") {

                dispatch(loadPurchases(seletedBill));

            }
            else {
                dispatch(loadSales(seletedBill));
            }

            setSelectedBill(seletedBill);
        }
    }

    const getBillDetail = () => {

        if (billType === "purchase") {
            return purchase && purchase.id === selectedBill ? purchase : {}

        }
        else {
            return sale && sale.id === selectedBill ? sale : {}
        }

    }

    const deleteBill=()=>{

        if (billType === "purchase" && selectedBill) {
            dispatch(deletePurchase(selectedBill));

        }
        else if(billType==="sales") {
            dispatch(deleteSale(selectedBill));
        }

        handleClose();

    }


    const loadBillDetail = () => {

        const billObject = getBillDetail();

        return (
            <React.Fragment>
                <Row className="mt-1 mb-1 g-2">
                    {
                        billObject.products ? <ProductListingOption products={ billObject.products} billItem={billItem} ></ProductListingOption>
                          : ("")
                    }
                </Row>

                <Row className="">
                    <Col></Col>
                    <Col className="text-end">Tax :</Col>
                    <Col className="text-end">

                        <input disabled={true} value={billObject && billObject.tax ? billObject.tax : ''} readOnly={true}
                            placeholder="Tax" className="rounded-0 border w-100" />
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col></Col>
                    <Col className="text-end">Total :</Col>
                    <Col className=" fw-bold">

                        &#x20b9;{Number(billObject && billObject.billAmount ? billObject.billAmount : 0).toFixed(2)}

                    </Col>
                </Row>
            </React.Fragment>);

    }


    function loadBills(filter) {

        if (billType === "purchase") {
            dispatch(loadAllPurchases({ ...filter, ...userFilter, ...defltr }));
        }
        else {
            dispatch(loadAllSales({ ...filter, ...userFilter, ...defltr }));
        }

    }

    const viewBills = (page) => {

        page = page ? page : 1;

        if (filterType === "0") {
            loadBills({ limit, page, ...getDateForRecentFilter() });
        }
        else if (filterType === "1") {
            if (startDate && endDate) {
                loadBills({ fromDate: formatDate(startDate), toDate: formatDate(endDate), page, limit });
            }
            else {
                dispatch(warningNotification("Select a valid date range to filter bills"));
            }
        }
        else if (filterType === "2") {

            if (selectedIds) {

                if (billType === "purchase") {
                    dispatch(loadAllPurchases({ vendorId: Number(selectedIds), page, limit, ...defltr }));
                }
                else {
                    dispatch(loadAllSales({ customerId: Number(selectedIds), page, limit, ...defltr }));
                }
            }
            else {
                dispatch(warningNotification(`Select a valid ${billType === "purchase" ? "Vendor" : "Customer"} to filter bills`));
            }

        }
        else if(filterType==="3"){
            if (srcBillNo) {
                loadBills({ billNumber:srcBillNo, page, limit });
            }
            else {
                dispatch(warningNotification("Bil Number should not be empty"));
            }
        }

        else {
            loadBills({ limit, page });
        }

    }

    const getDateForRecentFilter = () => {

        if (selectedRecent === 0) {
            return { fromDate: formatDate(new Date()), toDate: formatDate(new Date()) };
        }
        else if (selectedRecent === 1) {

            let yesterDay = new Date();

            yesterDay.setDate(yesterDay.getDate() - 1);

            return { fromDate: formatDate(yesterDay), toDate: formatDate(yesterDay) };
        }
        else if (selectedRecent === 2) {

            let lastWeek = new Date();

            lastWeek.setDate(lastWeek.getDate() - 7);

            return { fromDate: formatDate(lastWeek), toDate: formatDate(new Date()) };

        }
        else if (selectedRecent === 3) {

            let lastMonth = new Date();

            lastMonth.setDate(lastMonth.getDate() - 30);

            return { fromDate: formatDate(lastMonth), toDate: formatDate(new Date()) };
        }


    }

    const filter = () => {


        if (filterType === "0") {

            return (<Form.Select
                value={selectedRecent}
                onChange={(e) => {
                    setSelectedRecent(Number(e.target.value));
                }}>
                <option value={0}>Today</option>
                <option value={1}>Yesterday</option>
                <option value={2}>Last Week</option>
                <option value={3}>Last Month</option>

            </Form.Select>)

            //return <Form.Text style={{ "flex": "1 1 auto" }} className="border border-secondary mt-0 justify-content-center text-center p-1">Recent Bills</Form.Text>;
        }
        else if (filterType === "1") {

            return <DatePicker maxDate={new Date()}
                placeholderText="Select bill date range"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="form-control form-control-sm h-100"
                isClearable={true}
            ></DatePicker>
        }
        else if(filterType==="3"){
            return <Form.Control as="input" value={srcBillNo} onChange={(e)=>setSrcBillNo(e.target.value)} placeholder="Bill Number"></Form.Control>
        }
        else if (filterType === "4") {
            return <Form.Text style={{ "flex": "1 1 auto" }} className="border border-secondary mt-0 justify-content-center text-center p-1">All Bills</Form.Text>;
        }
        else if (filterType === "2") {

            if (billType === "purchase") {


                return (<Form.Select
                    value={selectedIds}
                    onChange={(e) => {
                        setSelectedIds(e.target.value);
                    }}>
                    <option value={0}>Select Vendor</option>
                    {
                        vendors ? vendors.map((vendor, idx) => {
                            return <option key={idx} value={vendor.id}>{vendor.name}</option>;
                        }) : ("")
                    }
                </Form.Select>)
            }
            else {

                return (<Form.Select aria-label="Text input with dropdown button"
                    value={selectedIds}
                    onChange={(e) => {
                        setSelectedIds(e.target.value);
                    }}>
                    <option value={0}>Select Customer</option>
                    {
                        customers ? customers.map((cust, idx) => {
                            return <option key={idx} value={cust.id}>{cust.name}</option>;
                        }) : ("")
                    }
                </Form.Select>)
            }
        }

    }

    const getBills = () => {

        let bills = [];

        if (billType === "purchase") {
            bills = purchases
        }
        else {
            bills = sales;
        }

        if (!bills || bills.length === 0) {
            return (<Alert variant="warning" className="mt-2">No Records</Alert>);
        }

        return (bills.map((pur, i) => {
            return (
                <Col  sm={6} md={12} key={i} className="mt-1">
                    <Card border="secondary" >
                        <Card.Body className="p-1">
                            <Container fluid>
                                <Row className="ps-1 pe-1">
                                    <Col className="text-start p-0 ">
                                        <p className=" m-0">
                                            <i className="fa fa-user"></i>
                                            <strong> {pur.vendor ? pur.vendor.name : pur.customer.name} </strong>
                                        </p>
                                    </Col>

                                    <Col className="text-end p-0 border-start">
                                        <p className="text-secondary m-0">
                                            <i className="fa fa-calendar"></i>
                                            <span> {formatDateView(pur.billDate)} </span>
                                        </p></Col>
                                </Row>
                                <Row className="ps-1 pe-1 border-top">
                                    <Col className="text-start  text-secondary p-0">

                                        {
                                            billItem ? (
                                                <React.Fragment>
                                                <Button variant="link" onClick={() => { handleShow(pur.id) }} className="p-0">
                                                    <i className="fa fa-file-code-o"></i>
                                                    <span> {pur.billNo} </span>
                                                </Button>
                                                    {
                                                        pur.status === "UNPAID" && !isVendor() && !isCustomer()?(<Link to={`/${billType}/new?editOnly=true&id=${pur.id}&stackHolder=${pur.vendor ? pur.vendor.id : pur.customer.id}`}  target="_blank" rel="noopener noreferrer"> <i className="fa fa-pencil float-end"></i></Link>):("")
                                                    }
                                                    
                                                </React.Fragment>
                                            ) : (
                                                <p className="text-secondary m-0 " >
                                                    <i className="fa fa-file-code-o"></i>
                                                    <span> {pur.billNo} </span>
                                                </p>
                                            )
                                        }

                                    </Col>
                                    <Col className="text-end p-0 border-start">

                                        <strong className={`text-end ${pur.status === "UNPAID" ? "text-danger" : "text-success"}`}> &#x20b9;{pur.billAmount} </strong>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>)
        }));

    }

    const getPage = () => {

        if (billType === "purchase") {
            return purchasePage ? purchasePage : 0
        }
        else {
            return salePage ? salePage : 0;
        }
    }

    const getCount=()=>{

        if (billType === "purchase") {
            return purchaseCount ? purchaseCount : 0
        }
        else {
            return saleCount ? saleCount : 0;
        }

        
    }

    const getTotal=()=>{

        if (billType === "purchase") {
            return purchaseSum && purchaseSum.billAmount ? purchaseSum.billAmount : 0
        }
        else {
            return saleSum && saleSum.billAmount ? saleSum.billAmount : 0;
        }

        
    }

    const getNextPage = () => {

        if (getPage() && Number(getPage()) > currentPage) {
            setCurrentPage(currentPage + 1);
            viewBills(currentPage + 1);
        }
    }

    const getPrevPage = () => {
        if (getPage() && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            viewBills(currentPage - 1);
        }
    }

    return (
        <div>
            {
                (<Container fluid className="p-0">
                    <div className={`card border border-dark mb-0 mt-1`}>

                        {props.header()}

                        <div className="card-body p-0" style={{ "height": "73vh", "overflowX": "auto" }}>
                            <div className="container-fluid">
                                
                                <Row className="bills">

                                    <InputGroup className="mb-2 p-0">
                                        <DropdownButton
                                            id="filterType"
                                            variant="outline-secondary"
                                            title={availableFilter.filter(flt=>flt.id===filterType)[0].name}
                                            onSelect={(e) => {
                                                setFilterType(e);
                                                setSelectedIds(0);
                                                setSelectedRecent(0);
                                                setStartDate(null);
                                                setEndDate(null);
                                                setCurrentPage(1);
                                                setSrcBillNo("");
                                                dispatch(resetSale());
                                                dispatch(resetPurchase());

                                            }}
                                        >
                                            {
                                                availableFilter.map((value, index) => {
                                                    return (<Dropdown.Item key={`drp-${index}`} eventKey={value.id}>{value.name}</Dropdown.Item>);
                                                })
                                            }

                                        </DropdownButton>

                                        {
                                            filter()
                                        }
                                        <InputGroup.Text className="p-0 bg-dark" title="Filter bill based on selection"><Button size="sm" variant="outline" onClick={() => {
                                            viewBills()
                                        }}><i className="fa fa-search text-light"></i></Button></InputGroup.Text>

                                    </InputGroup>

                                </Row>
                                <Row className="g-2 mb-2 fw-bold">

                                {
                                    getTotal()?( <Col className="text-end">{`Total:${getTotal()}`}</Col>) :("")
                                }
                                </Row>
                                
                                <Row className="g-2 mb-2">

                                    {

                                        getBills()

                                    }

                                </Row>

                            </div>
                        </div>
                        <div className="card-footer">
                            <Row>
                                <Col className="me-auto col-auto" >

                                    <Button variant="primary" size="sm" disabled={currentPage === 1} onClick={getPrevPage}>Prev</Button>
                                </Col>

                                <Col className="mx-auto col-auto">

                                    <button className="btn btn-sm">{getBills() ? (`${currentPage} of ${getPage()}`) : ""}<strong>{`[${getCount()}]`}</strong></button>
                                </Col>
                                <Col className="ms-auto col-auto">

                                    <Button variant="primary" className="float-end" size="sm" disabled={!getPage() || Number(currentPage) === Number(getPage())} onClick={getNextPage}>Next</Button>
                                </Col>
                            </Row>



                        </div>
                    </div>
                </Container>)

            }

            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Bill Items</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Container>
                        {
                            loadBillDetail()
                        }
                    </Container>
                </Modal.Body>
                {
                    isOwner()?(<Modal.Footer>
                         <div className="d-grid d-md-flex gap-2 justify-content-md-end">
                            <Button size="sm" variant="danger" onClick={deleteBill}>Delete</Button>
                            </div>
                    </Modal.Footer>):("")

                }
            </Modal>
        </div>
    );
}
