import { combineReducers } from 'redux';
import userReducer from "../reducer/userReducer";
import vendorReducer from "../reducer/vendorReducer";
import notificationReducer from '../reducer/notificationReducer';
import loginReducer from '../reducer/loginReducer';
import customerReducer from '../reducer/customerReducer';
import productLookupReducer from '../reducer/productLookupReducer';
import productReducer from '../reducer/productReducer';
import purchaseReducer from '../reducer/purchaseReducer';
import saleReducer from '../reducer/saleReducer';
import summaryReducer from '../reducer/summaryReducer';
import paymentReducer from '../reducer/paymentReducer';
import expenseReducer from '../reducer/expenseReducer';
import loaderReducer from '../reducer/loaderReducer';
import stockReducer from "../reducer/stockReducer";
export const rootReducer = combineReducers({
    user: userReducer,
    vendor:vendorReducer,
    notification:notificationReducer,
    auth_user:loginReducer,
    customer:customerReducer,
    productLookup:productLookupReducer,
    product:productReducer,
    purchase:purchaseReducer,
    sale:saleReducer,
    summary:summaryReducer,
    payment:paymentReducer,
    expense:expenseReducer,
    fetch:loaderReducer,
    stock:stockReducer
});
