import React from "react";
import { useDispatch } from "react-redux";

import {  Card, Button, Form, FloatingLabel,Row,Col } from "react-bootstrap";
import { login } from "../action/loginAction"
import logo from "../assets/logo.jpeg"
import {numberCheck} from "../constant/numberCheck"

export default function Login() {

  const [userId, setUserId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();


  const handleLoginSubmit = (e) => {

    e.preventDefault();

    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      dispatch(login(userId, password))
    }

  }

  const findFormErrors = () => {

    const newErrors = {}
    if (!userId || userId === "") newErrors.userId = 'User Id cannot be blank!'
    if (!password || password === "") newErrors.password = 'Password cannot be blank!'


    return newErrors
  }

  return (

    <div className="position-absolute top-50 start-50 translate-middle ">
      <Card className="  border-0 card-login" >
        <Card.Body>
          <img id="profile-img" className="profile-img-card" src={logo} />
          <Form autoComplete="off" onSubmit={handleLoginSubmit}>
            <input type="hidden" value="hidden" />

            <Row className="mb-2 ">
              <Col>
              <FloatingLabel label="User Id">
                <Form.Control size="sm" type="text" placeholder="User Id" isInvalid={!!errors.userId}
                  onChange={(e) => {
                    setUserId(e.target.value);
                    setErrors({ ...errors, userId: null });
                  }}
                  onKeyPress={numberCheck}
                  value={userId} />
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>

              </FloatingLabel>
              </Col>
            </Row>

            <Row className="mb-2 ">
              <Col>
              <FloatingLabel label="Password">
                <Form.Control size="sm" type="password" placeholder="Password" isInvalid={!!errors.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrors({ ...errors, password: null });
                  }}
                  value={password} />
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>

              </FloatingLabel>
              </Col>
            </Row>

            <div className="d-grid d-md-flex gap-2 justify-content-md-center  ">
              <Button variant="primary" type="submit" size="sm" disabled={userId.length!==10} >
                Login
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card> </div>);
}
