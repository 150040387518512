import React from "react";
import Loader from "react-loader-spinner";

import { useSelector } from "react-redux";

function CustomLoader() {
  const isLoading = useSelector((state) => state.fetch.isLoading);

  return (
    <React.Fragment>
      <div id="overlay" style={{ display: isLoading?"block":"none" }}></div>
      <Loader
        className="cust-loader"
        type="Puff"
        color="#34BAB3"
        height={100}
        width={100}
        style={{
          display: isLoading?"flex":"none"
        }}
      />
    </React.Fragment>
  );
}

export default CustomLoader;
