import {
     RESETPURCHASE, CHANGEPURCHASE,
    CHANGEITEMPURCHASE, ADDITEMPURCHASE,
    RESETITEMPURCHASE,
    LOADALLPURCHASE,
    LOADPURCHASE,
    CANCELEDITPURCHASE,
    RESETPURCASEEDIT,
    SET_NOTIFICATION
} from "../constant/constant";
import { pAxios } from "..";
import store from "../app/store";


export function loadAllPurchaseService(url) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/purchase?${url}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADALLPURCHASE, purchases: (response.data) });
            }
            else {
                resolve({ type: LOADALLPURCHASE, purchases: [] });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load purchase data", variant: "danger", err } })
            });
    });

}

function loadPurchaseService(purchaseId) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/purchase/${purchaseId}`,
            "headers": {
                'content-type': 'application/json'
            }

        }).then(response => {

            if (response.data) {

                resolve({ type: LOADPURCHASE, purchase: { ...response.data, billDate: (response.data.billDate ? new Date(response.data.billDate) : response.data.billDate) }})
            }
            else {
                resolve({ type: LOADPURCHASE, purchase: {} });
            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Failed to load purchase data", variant: "danger", err } })
            });
    });

}


function savePurchaseService(purchase,editOnly) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": `/api/purchase`,
            "headers": {
                'content-type': 'application/json'
            },
            "data": JSON.stringify(purchase)

        }).then(response => {

            if (response.data) {

                if(editOnly){
                   resolve({
                        type: SET_NOTIFICATION, notification:
                            { message: "Prurchase bill successfully", variant: "success", err: "" }
                    });
                }
                else{

                    store.dispatch({
                        type: SET_NOTIFICATION, notification:
                            { message: "Prurchase bill successfully", variant: "success", err: "" }
                    });
    
                    if(purchase.id){
                        resolve({ type: RESETPURCASEEDIT });
                    }
                    else{
                        
                        resolve({ type: RESETPURCHASE });
                    }
                }

            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Error while saving purchase bill", variant: "danger", err } })
            });
    });

}


function deletePurchaseService(id) {

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "DELETE",
            "url": `/api/purchase/${id}`,
            "headers": {
                'content-type': 'application/json'
            },

        }).then(response => {

            if (response.data) {

                store.dispatch({
                    type: SET_NOTIFICATION, notification:
                        { message: "Prurchase bill deleted successfully", variant: "success", err: "" }
                });

                                 
                    resolve({ type: RESETPURCHASE });
                

            }
        })
            .catch(err => {

                reject({ type: SET_NOTIFICATION, notification: { message: "Error while deleting purchase bill", variant: "danger", err } })
            });
    });

}


export const loadAllPurchases = ({ date = null,billNumber=null,reconsiled=null ,vendorId = null, limit = null, status = null, fromDate = null, toDate = null, page = null }) => {

    let data = {};

    if (date) {
        data = { ...data, fromDate: date, toDate: date }
    }
    else if (fromDate && toDate) {
        data = { ...data, fromDate, toDate }
    }


    if (vendorId) {
        data = { ...data, vendorId }
    }

    if (limit) {
        data = { ...data, limit }
    }

    if (page) {
        data = { ...data, page }
    }

    if (status) {
        data = { ...data, status }
    }

    if(billNumber){
        data={...data,billNumber}
    }

    if(reconsiled){
        data={...data,reconsiled}
    }

    if (Object.keys(data).length === 0) {
        data = { limit: 10 }
    }

    let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')

    return dispatch => {

        loadAllPurchaseService(url)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const loadPurchases = (purchaseId) => {

    return dispatch => {

        loadPurchaseService(purchaseId)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const savePurchase = (purchase,editOnly) => {

    return dispatch => {

        savePurchaseService(purchase,editOnly)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const deletePurchase = (id) => {

    return dispatch => {

        deletePurchaseService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const changePurchase = ({ name, value }) => {

    return dispatch => {
        dispatch({ type: CHANGEPURCHASE, name, value });
    }

}

export const changeItemPurchase = (id, value) => {

    return dispatch => {
        dispatch({ type: CHANGEITEMPURCHASE, id, value });
    }
}

export const addItemPurchase = (product) => {

    return dispatch => {
        dispatch({ type: ADDITEMPURCHASE, product });
    }


}

export const resetItemPurchase = () => {

    return dispatch => {
        dispatch({ type: RESETITEMPURCHASE });
    }

}

export const resetPurchase = () => {

    return dispatch => {
        dispatch({ type: RESETPURCHASE });
    }

}
export const cancelEditPurchase=()=>{
    return dispatch => {
        dispatch({ type: CANCELEDITPURCHASE });
    }
}