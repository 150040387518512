export const LOADING= "loading";

export const LOADSUMMARY="loadSummary";
export const LOADUSER="loadUser";
export const RESETUSER="resetUser";
export const GETPASSWORD="getPassword";
export const LOADALLUSER="loadAllUser";
export const CHANGEUSERFIELD="changeUserField";
export const REFRESHTOKEN="refreshToken";

export const LOADALLVENDOR="loadAllVendor";
export const LOADVENDOR="loadVendor";
export const CHANGEVENDOR="changeVendor";
export const SAVEVENDOR="saveVendor";
export const UPDATEVENDOR="updateVendor";
export const RESETVENDOR="resetVendor";
export const ADDVENDORPRODUCT="addVendorProduct";
export const REMOVEVENDORPRODUCT="removeVendorProduct";
export const CHANGEVENDORPRODUCT="changeVendorProduct";

export const LOADALLCUSTOMER="loadAllCustomer";
export const LOADCUSTOMER="loadCustomer";
export const CHANGECUSTOMER="changeCustomer";
export const SAVECUSTOMER="saveCustomer";
export const UPDATECUSTOMER="updateCustomer";
export const RESETCUSTOMER="resetCustomer";
export const ADDCUSTOMERPRODUCT="addCustomerProduct";
export const REMOVECUSTOMERPRODUCT="removeCustomerProduct";
export const CHANGECUSTOMERPRODUCT="changeCustomerProduct";


export const LOADALLPRODUCT="loadAllProduct";
export const LOADPRODUCT="loadProduct";
export const SAVEPRODUCT="saveProduct";
export const CHANGEPRODUCT="changeProduct";
export const RESETPRODUCT="resetProduct";
export const ADDPRODUCTTYPE="addProductType";
export const REMOVEPRODUCTTYPE="removeProductType";
export const CHANGEPRODUCTTYPE="chnageProductType";

export const LOGIN_USER="userLogin";
export const LOGOUT_USER="userLogout";
export const LOGIN="LOGIN";

export const SET_NOTIFICATION="setNotification";
export const RESET_NOTIFICATION = "resetNotification";

export const LOADLOOKUPS="loadLookups";
export const LOADPRODUCTLOOKUPS="loadProductLookups";


export const SAVEPURCHASE="savePurchase";
export const RESETPURCHASE="resetPurchase";
export const RESETITEMPURCHASE="resetItemPurchase";
export const CHANGEPURCHASE="changePurchase";
export const CHANGEITEMPURCHASE="changeItemPurchase";
export const ADDITEMPURCHASE="addItemPurchse";
export const LOADALLPURCHASE="loadAllPurchase";
export const LOADPURCHASE="loadPurchase";
export const CANCELEDITPURCHASE="cancelEditPurchase";
export const RESETPURCASEEDIT ="resetPurchaseEdit";

export const SAVESALE="saveSale";
export const RESETSALE="resetSale";
export const RESETITEMSALE="resetItemSale";
export const CHANGESALE="changeSale";
export const CHANGEITEMSALE="changeItemSale";
export const ADDITEMSALE="addItemSale";
export const LOADALLSALE="loadAllSale";
export const LOADSALE="loadSale";
export const CANCELEDITSALE="cancelEditSale";
export const RESETSALEEDIT ="resetSaleEdit";

export const SAVEPAYMENT ="savePAyment";
export const LOADALLPAYMENT ="loadAllPayment";
export const LOADPAYMENT ="loadPayment";
export const CHANGEPAYMENT ="changePayment";
export const RESETPAYMENT="resetPayment";
export const LOADSTATEMENTS="loadStatements";
export const RESETSTATEMENT="resetStatement";

export const SAVEEXPENSE ="saveExpense";
export const LOADALLEXPENSE ="loadAllExpense";
export const LOADEXPENSE ="loadExpense";
export const CHANGEEXPENSE ="changeExpense";
export const RESETEXPENSE="resetExpense";
export const CANCELEDITEXPENSE="cancelEditExpense";

export const LOADSTOCKS="loadStocks";
export const RESETSTOCKS="resetStocks";

export const PAGESIZE=50;

export const initialState = { 


	productLookup:[{id:"1",text:"Veggies",subTypes:[{id:"1",text:"Pot"},{id:"2",text:"Tom"}]},{id:"2",text:"Meat",subTypes:[{id:"3",text:"fish"},{id:"4",text:"chicken"}]}],
	purchase:{purchases:[],purchase:{},sum:{}},
	sale:{sales:[],sale:{},sum:{}},
	auth_user:{},
    user: { users:[]},
	notification: { message: null, variant: "default" },
    vendor:{vendors:[]},
	customer:{customers:[]},
	product:{products:[],product:{}},
	summary:{summary:{}},
	payment:{payments:[],payment:{}},
	expense:{expenses:[],expense:{}},
	stock:{stocks:[],stock:{}},
	fetch:{isLoading:false}
};

export function sortByName(rows){

	return rows.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
 
 }

export function sortByText(rows){

	return rows.sort((a,b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
 
 }
 