import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Card, Row, Col, Alert } from "react-bootstrap";
import { loadStocks } from '../action/productAction';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatDate,formatDateView } from "../constant/dateFormat";
import exportFromJSON from 'export-from-json'  ;

export default function ViewStocks() {

    const dispatch = useDispatch();

    const [date, setDate] = React.useState();
    const [filter, setFilter] = React.useState(false);
    const stocks = useSelector(
        (state) => state.stock.stocks
    );

    React.useEffect(() => {
        dispatch(loadStocks(date?formatDate(date):null));
    }, [dispatch,date]);

    const ExportToExcel = () => {  
        exportFromJSON({ data:stocks, fileName:`Stock report - ${date?formatDateView(date):formatDateView(new Date())}`, exportType:'xls' })  
      }  

    const getBills = () => {

        let bills = stocks;


        if (!bills || bills.length === 0) {
            return (<Alert variant="warning" className="mt-2">No Records</Alert>);
        }

        return (bills.map((pur, i) => {
            return (
                <Col md={12} sm={6} key={i} className="mt-1">
                    <Card border="secondary" >
                        <Card.Body className="p-1">
                            <Container fluid className="">
                                <Row className="ps-1 pe-1">
                                    <Col className="text-start col p-0 fw-bold">
                                        {pur.text}
                                        <span className="float-end fs-l7">{pur.code} </span>
                                    </Col>
                                </Row>
                                <Row className="ps-1 pe-1 border-top">

                                    <Col className="text-start col p-0 text-secondary border-end">
                                        <p className="m-0">
                                            <i className="fa fa-arrow-down text-primary"></i>
                                            <span > {pur.purchase ? Number(pur.purchase) : 0}  </span>
                                        </p>
                                    </Col>

                                    <Col className="text-center col p-0 text-secondary border-end">
                                        <p className="m-0">
                                            <i className="fa fa-arrow-up text-danger"></i>
                                            <span > { pur.sales ? Number(pur.sales) : 0}  </span>
                                        </p>
                                    </Col>

                                    <Col className="text-end col p-0 border-start">
                                        <strong className={`text-end`}> {pur && pur.balance ? Number(pur.balance) : 0} </strong>
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>)
        }));

    }


    return (
        <div>
            {
                (<Container fluid className="p-0">
                    <Card border="dark" >
                        <Card.Header as="h6" className="bg-dark">
                            Stocks
                            <i className="fa fa-filter float-end" role="button" onClick={() => {
                                setFilter(!filter)
                                setDate(null);
                            }

                            }></i>
                            <i className="fa fa-file-excel-o float-end pe-2" role="button" onClick={() => {
                                ExportToExcel();
                            }

                            }></i>
                        </Card.Header>

                        <Card.Body className=" p-0" style={{ "height": "75vh", "overflowX": "auto" }}>
                            <Container fluid>
                                {
                                    filter ? (
                                        <Row className="g-2 mb-1 mt-1">
                                            <DatePicker placeholderText="Filter stock by date" maxDate={new Date()} dateFormat="dd-MM-yyyy" className={`form-control form-control-sm `} selected={date ? date : null} onChange={(date) => setDate(date)} />
                                            <hr></hr>
                                        </Row>) : ("")
                                }

                                <Row className="g-2 mb-2 mt-2">
                                    {
                                        getBills()
                                    }
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Container>)

            }
        </div>
    );
}
