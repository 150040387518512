import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Row, Nav, Button, Card, Form, Badge, Modal } from "react-bootstrap";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { formatDate, formatDateView } from "../constant/dateFormat";
import { dangerNotification } from "../action/notificationAction"


import { loadAllVendor } from "../action/vendorAction";
import { loadAllCustomer } from "../action/customerAction";
import { loadAllUser } from "../action/userAction";
import { loadAllSales, resetSale } from "../action/saleAction";
import { loadAllPurchases, resetPurchase } from "../action/purchaseAction";
import { reconsile } from "../action/paymentAction";
import { loadAllExpenses, resetExpense } from '../action/expenseAction'

export default function Reconciliation() {



    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const [stackHolder, setStackHolder] = useState("Vendor");
    const [selectedIds, setSelectedIds] = useState();
    const [selectedBills, setSelectedBills] = useState({});

    const [selectAll,setSelectAll]=useState(false);
    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const users = useSelector(
        (state) => state.user.users
    );

    const sales = useSelector(
        (state) => state.sale.sales
    );
    const purchases = useSelector(
        (state) => state.purchase.purchases
    );

    const expense = useSelector(
        (state) => state.expense.expenses
    );

    const expenseCount = useSelector(
        (state) => state.expense.count
    );
    const saleCount = useSelector((state) => state.sale.count);
    const purchaseCount = useSelector((state) => state.purchase.count);



    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    React.useEffect(() => {

        dispatch(loadAllVendor('active'));
        dispatch(loadAllCustomer('active'));
        dispatch(loadAllUser());
        dispatch(resetExpense());
        dispatch(resetPurchase());
        dispatch(resetSale());
        setSelectedBills({});
    }, [dispatch]);

    
    React.useEffect(() => {
        dispatch(resetExpense());
        dispatch(resetPurchase());
        dispatch(resetSale());
    }, [stackHolder])

    const getStackHolderSelection = () => {

        if (stackHolder === "Vendor") {
            return (
                <Row className="g-2 mb-2">
                    <Col>
                        <Form.Group >
                            <Form.Label>Vendor</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                    setSelectedBills({});
                                    setSelectAll(false);
                                }}>
                                <option value={0}>Select a Vendor</option>
                                {
                                    vendors ? vendors.map((vendor, idx) => {
                                        return <option key={idx} value={vendor.id}>{vendor.name}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>);
        }
        else if (stackHolder === "Customer") {
            return (
                <Row className="g-2 mb-2">
                    <Col>
                        <Form.Group >
                            <Form.Label>Customer</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                    setSelectedBills({});
                                    setSelectAll(false);
                                }}>
                                <option value={0}>Select a Customer</option>
                                {
                                    customers ? customers.map((cust, idx) => {
                                        return <option key={idx} value={cust.id}>{cust.name}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>);
        }

        else {

            return (
                <Row className="g-2 mb-2">
                    <Col>
                        <Form.Group >
                            <Form.Label>User</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                    setSelectedBills({});
                                    setSelectAll(false);
                                }}>
                                <option value={0}>Select User</option>
                                {
                                    users ? users.filter(user => user.role !== "CUSTOMER" && user.role !== "VENDOR").map((user, idx) => {
                                        return <option key={idx} value={user.id}>{user.username}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>);
        }

    }

    const loadBillsByMode = () => {

        setSelectedBills({});
        setSelectAll(false);

        if (startDate && endDate && selectedIds) {
            if (stackHolder === "Vendor") {
                dispatch(loadAllPurchases(getLoadCondition()));
            }
            else if (stackHolder === "Customer") {
                dispatch(loadAllSales(getLoadCondition()));
            }
            else {
                dispatch(loadAllExpenses(getLoadCondition()));
            }

        }


    }

    const getLoadCondition = () => {

        if (stackHolder === "Vendor") {
            return { vendorId: selectedIds, status: "PAID", reconsiled: "N", fromDate: formatDate(startDate), toDate: formatDate(endDate) };
        }
        else if (stackHolder === "Customer") {
            return { customerId: selectedIds, status: "PAID", reconsiled: "N", fromDate: formatDate(startDate), toDate: formatDate(endDate) };
        }
        else {
            return { userId: selectedIds, reconsiled: "N", fromDate: formatDate(startDate), toDate: formatDate(endDate) };
        }

    }

    const getBills = () => {

        if (stackHolder === "Vendor") {
            return purchases ? purchases : [];
        }
        else if (stackHolder === "Customer") {
            return sales ? sales : [];
        }
        else {
            return expense ? expense : [];
        }
    }

    const getCount = () => {

        if (stackHolder === "Vendor") {
            return purchaseCount ? purchaseCount : 0;
        }
        else if (stackHolder === "Customer") {
            return saleCount ? saleCount : 0;
        }
        else {
            return expenseCount ? expenseCount : 0;
        }
    }

    const toggleSelect = (id) => {

        let status = selectedBills[id];
        setSelectedBills({ ...selectedBills, [id]: status && status === "Y" ? "N" : "Y" })
        setSelectAll(false);

    }

    const handleProcess = () => {

        

        if (selectedBills && Object.keys(selectedBills).filter((k)=>selectedBills[k]==='Y').length>0) {

            setShow(true);
            
        }
        else{
            dispatch(dangerNotification("Select bills to reconcile"))
        }
    }

    const continueHandleProcess=()=>{
      dispatch(reconsile({ stackHolder, selectedIds:[...Object.keys(selectedBills).filter((k)=>selectedBills[k]==='Y').map((k) => Number(k))], loadCondition: getLoadCondition() }));
      
      setSelectAll(false);
        setSelectedBills({});
        setShow(false);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    
    const toggleSelectAll=()=>{

        let selection=selectedBills;

        if(!selectAll){
            
            getBills().forEach((bill)=>{
                selection ={...selection,[bill.id]:"Y"};
            });
        }
        else{
            getBills().forEach((bill)=>{
                selection={...selection,[bill.id]:"N"};
            });
        }

        setSelectedBills({...selection});
        setSelectAll(!selectAll);

    }
  

    return (
        <Container className="mt-3  ">
            <Card className={`mb-0 mt-1`} border="dark">

                {/* <Tab.Container  id="tab"> */}
                <Card.Header className="bg-dark">

                    <Nav className="me-auto" defaultActiveKey={stackHolder} id="typeTab" variant="tabs" onSelect={(e) => { setStackHolder(e); setStartDate(null); setEndDate(null); setSelectedBills({}); setSelectedIds(0);setSelectAll(false) }}>
                        <Nav.Item>
                            <Nav.Link eventKey="Vendor">Purchase</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Customer">Sales</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="Users">Expenses</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-auto">

                            <Button variant="success" className="float-end" size="sm" onClick={() => { handleProcess() }} >Process</Button>
                        </Nav.Item>

                    </Nav>


                </Card.Header>
                <Card.Body className="p-0" >
                    <Container fluid>
                        <Row className="g-2 mb-2">
                            <Col>

                                <Form.Group >
                                    <Form.Label>Date</Form.Label>

                                    <DatePicker maxDate={new Date()}
                                        placeholderText="Select date range"
                                        dateFormat="dd-MM-yyyy"
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        className="form-control form-control-sm h-100"
                                        isClearable={true}
                                    ></DatePicker>
                                </Form.Group>
                            </Col>
                        </Row>

                        {

                            getStackHolderSelection()

                        }

                    </Container>
                </Card.Body>

            </Card>

            <Card border="dark" >

                <Card.Header className="bg-dark" as="h6">

                <i className={`fa-2x p-1 fa ${selectAll ? "fa-check-square" : "fa fa-square-o "}`} onClick={()=>{toggleSelectAll() }}></i>
                    

                    Bills-<Badge bg="success">{getCount()}</Badge>
                    <Button className="float-end" variant="success" size="sm" onClick={() => { loadBillsByMode() }} ><i className="fa fa-search"></i></Button>
                </Card.Header>
                <Card.Body className="p-1 mt-2" style={{ fontSize: ".8rem", "height": "50vh", "overflowX": "auto" }}>

                    {
                        getBills().map((pur, i) => {

                            return (<Col md={12} sm={6} key={i} className="mt-1" onClick={() => { toggleSelect(pur.id) }}>
                                <Card border="secondary" key={`card${i}`}>
                                    <Card.Body className="p-1">
                                        <Container fluid className="">

                                            <Row className="ps-1 pe-1 border-bottom">
                                                <Col className="text-start col p-0 " title="Created By">
                                                    <i className="fa fa-id-card-o"></i>
                                                    <strong> {pur.createdBy ? pur.createdBy.name : "admin"} </strong></Col>
                                                <Col className="text-end col border-start p-0"> <p className="text-secondary m-0"><i className="fa fa-calendar"></i> {pur.billDate} </p></Col>
                                            </Row>
                                            <Row className="ps-1 pe-1">

                                                <Col className="p-0 border-end col-1" >
                                                    <i className={`fa-2x fa ${selectedBills[pur.id] && selectedBills[pur.id] === "Y" ? "fa-check text-primary" : "fa fa-square-o text-dark"}`}></i>

                                                </Col>
                                                <Col className=" text-start border-end">
                                                    <p className="m-0"> <i className="fa fa-file-code-o"></i>
                                                        {` ${pur.billNo}`} </p>

                                                </Col>

                                                <Col className="p-0 text-end">
                                                    <p className={`m-0 text-end ${pur.status === "UNPAID" ? "text-danger" : "text-success"}`}>&#x20b9; {pur.billAmount ? pur.billAmount : pur.amount} </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Card>
                            </Col>)
                        })

                    }

                </Card.Body>
            </Card>

            <Modal  show={show} onHide={handleClose} centered size="sm">
                

                <Modal.Body>
                    Please make sure of selected bills, Once reconcile, you not be able to revert it.!
                    <br></br>
                    Number of selected bills - {Object.keys(selectedBills).filter((k)=>selectedBills[k]==='Y').length}
                </Modal.Body>

                <Modal.Footer>
                <Button variant="danger" onClick={handleClose} size="sm">Cancel</Button>
                    <Button variant="primary" onClick={continueHandleProcess} size="sm"> Continue</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}
