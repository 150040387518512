import {
    SET_NOTIFICATION, RESET_NOTIFICATION
} from '../constant/constant';



export const warningNotification = (message) => {
    return dispatch => { dispatch({ type: SET_NOTIFICATION, notification: { message, variant: "warning" } }); };
}

export const dangerNotification = (message) => {
    return dispatch => { dispatch({ type: SET_NOTIFICATION, notification: { message, variant: "danger" } }); };
}

export const resetNotification = () => {
    return dispatch => {
        dispatch({ type: RESET_NOTIFICATION });
    };
}