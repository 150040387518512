export const menus={

    User:[{name:"NEW_USER",link:"/users/new",label:"Add User"},{name:"VIEW_USER",link:"/users/view",label:"View User"}],
    Products:[{name:"NEW_PRODUCT",link:"/product/new",label:"Add Product"},{name:"VIEW_PRODUCT",link:"/product/view",label:"View Product"}],
    Vendor:[{name:"NEW_VENDOR",link:"/vendor/new",label:"Add Vendor"},{name:"VIEW_VENDOR",link:"/vendor/view",label:"View Vendor"}],
    Customer:[{name:"NEW_CUSTOMER",link:"/customer/new",label:"Add Customer"},{name:"VIEW_CUSTOMER",link:"/customer/view",label:"View Customer"}],
    Purchase:[{name:"NEW_PURCHASE",link:"/purchase/new",label:"New Purchase"},{name:"VIEW_PURCHASE_BILL",link:"/purchase/view",label:"Purchase Bill"}],
    Sale:[{name:"NEW_SALE",link:"/sales/new",label:"New Sales"},{name:"VIEW_SALES_BILL",link:"/sales/view",label:"Sales Bill"}],
    Payment:[{name:"NEW_PAYMENT",link:"/payment/new",label:"New Payment"},{name:"TRANSACTIONS",link:"/payment/view",label:"Transactions"}],
    Expense:[{name:"NEW_EXPENSE",link:"/expense/new",label:"New Expense"},{name:"VIEW_EXPENSE",link:"/expense/view",label:"View Exepenses"}],
    Account:[{name:"SUMMARY",link:"/summary",label:"Summary"},{name:"VIEW_STOCKS",link:"/stocks",label:"Stocks"},{name:"STATEMENT",link:"/statement",label:"Statement"},{name:"CLOSE_BILL",link:"/pendingBill",label:"Pending Bills"},{name:"ALL_BILL",link:"/closedBill",label:"Closed Bills"},{name:"REPORTS",link:"/reports",label:"Reports"},{name:"RECONSILATION",link:"/reconciliation",label:"Reconciliation"}],
}

export const icons={



}