import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row,  Form, Card, Nav } from "react-bootstrap";
import VendorEntry from "./VendorEntry";

import NameListing from "./NameListing";
import { loadAllVendor,  updateStatus } from '../action/vendorAction';
import {isVendor} from "../constant/permission"
//import {getRandomColor} from "../constant/color";
export default function Vendor() {

    const dispatch = useDispatch();
    
    const vendors = useSelector(
        (state) => state.vendor.vendors
        );
        
    const [status,setStatus]=useState("active"); 
    const [show, setShow] = useState(false);
    const [filterStr, setFilterStr] = useState("");
    const [selectedIds, setSelectedIds] = useState({});
   
    const handleClose = () => {
        setShow(false);
        setSelectedVendor(null);
        setSelectedIds({});
        dispatch(loadAllVendor(status));

    }

    const handleShow = (id) => {
        setShow(true);
        setSelectedVendor(id);
    }

    const toggleSelect = (id) => {

        let status = selectedIds[id];
        setSelectedIds({ ...selectedIds, [id]: status && status === "Y" ? "N" : "Y" })

    }
    const [seletedVendor, setSelectedVendor] = useState();

    const activeVendors = () => {

        const ids = Object.keys(selectedIds).filter((k) => selectedIds[k] === "Y").map((k) => k);

        if (ids) {

            dispatch(updateStatus(ids));
        }

    }

    React.useEffect(() => {
        dispatch(loadAllVendor(status));
    }, [dispatch, status]);


    


    if(isVendor()){

        const vendorId=localStorage.getItem("stakeHolderId");
        if(vendorId){
            return (<VendorEntry viewalone={true} seletedVendor={Number(vendorId)} handleClose={handleClose} />);
        }
        else{
            return ("");
        }
    }
    else{

        return (
            <div>
                {
                    !show && !seletedVendor ?
                        (<Container fluid>
                            <Card border="dark" className={` mb-0 mt-1`}>
    
    
                                <Card.Header className="bg-dark">
                                    <Nav  variant="pills" defaultActiveKey={status} onSelect={(e)=>setStatus(e)}>
                                        <Nav.Item>
                                            <Nav.Link eventKey={"active"}>Active</Nav.Link>
                                        </Nav.Item>
                                        
                                        <Nav.Item>
                                            <Nav.Link eventKey={"dormant"}>Dormant</Nav.Link>
                                        </Nav.Item>
                                         
    
                                        <Nav.Item className="searchBar col-sm pt-2 pt-sm-0">
                                            
                                            <div className="float-md-end">
                                                <Form.Control type="text" placeholder={`Search ${vendors ? vendors.length : 0} records`} onChange={(e) => setFilterStr(e.target.value)} />
                                            </div></Nav.Item>
                                    </Nav>
    
    
                                    {/* <h6 className="float-md-start p-1">
                                        {status === 'active' ? 'Active' : 'Dormant'}   Vendors
                                    </h6> */}
    
                                </Card.Header>
    
                                <Card.Body className="p-0" style={{ "height": "65vh", "overflowX": "auto" }}>
                                    <Container fluid className="p-1">
                                        {/* <ConfigProvider colors={['red', 'green', 'blue', 'purple', 'orange']}> */}
                                        <Row className="g-2 justify-content-center">
                                            {vendors.filter((vendor) => !filterStr || vendor.name.toLowerCase().includes(filterStr.toLowerCase())).map((vendor, i) => {
                                                return (<NameListing key={i} status={status} element={vendor} handleShow={handleShow} toggleSelect={toggleSelect} selectedIds={selectedIds}></NameListing>);
                                            })}
                                        </Row>
                                        {/* </ConfigProvider> */}
                                    </Container>
                                </Card.Body>
                                {status && status === 'active' ? ("") : (<Card.Footer>
                                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                                        <Button variant="primary" size="sm" onClick={activeVendors}>Active</Button>
                                    </div>
                                </Card.Footer>)}
                            </Card>
                        </Container>)
                        : (<VendorEntry seletedVendor={seletedVendor} handleClose={handleClose} />)
                }
            </div>
        );
    }
}
