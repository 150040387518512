import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Card, Row, Col,  Nav, Alert } from "react-bootstrap";

import { loadAllPurchases } from '../action/purchaseAction';
import { loadAllSales, resetSale } from '../action/saleAction';
import { closePayment } from '../action/paymentAction';


export default function BillSettlment() {

    
    const dispatch = useDispatch();

    const [selectedMode, setSelectedMode] = useState("purchase");
    const [selectedIds, setSelectedIds] = useState({});
    const sales = useSelector(
        (state) => state.sale.sales
    );
    const purchases = useSelector(
        (state) => state.purchase.purchases
    );



    React.useEffect(() => {
        dispatch(loadAllPurchases({ "status": "UNPAID" }));      
        dispatch(resetSale());
        
    }, [dispatch]);





    const loadBillsByMode = (mode) => {

        setSelectedMode(mode);


            if (mode === "purchase") {
                dispatch(loadAllPurchases({ "status": "UNPAID" }));
            }
            else {
                dispatch(loadAllSales({ "status": "UNPAID"  }));
            }
        

    }


    const toggleSelect = (id) => {

        let status = selectedIds[id];
        setSelectedIds({ ...selectedIds, [id]: status && status === "Y" ? "N" : "Y" })

    }

    const handleClosePayment = () => {

        const ids = Object.keys(selectedIds).filter((k) => selectedIds[k] === "Y").map((k) => Number(k));

        if (ids) {
            dispatch(closePayment({ [selectedMode === "purchase" ? "purchaseIds" : "saleIds"]: ids }));

        }
    }

    const getUsers = () => {

        let users = [];
        if (selectedMode === "purchase") {

            purchases.filter(purchase => purchase.vendor).forEach((purchase) => {

                if(!users.some(user=>user.name===purchase.vendor.name)){

                    users.push({name:purchase.vendor.name,wallet: purchase.vendor.wallet });
                }
            });


        }
        else if (selectedMode === "sales") {

            sales.filter(sale => sale.customer).forEach((sale) => {

                
                if(!users.some(user=>user.name===sale.customer.name)){

                    users.push({name:sale.customer.name,wallet: sale.customer.wallet });
                }
                
            });


        }

        let sorted = users.sort((a,b)=>Number(b.wallet)-Number(a.wallet));
        return sorted
    }

    const getBills = (name) => {

        if (selectedMode === "purchase") {
            return purchases.filter(pur => pur.vendor.name === name)
        }
        else {
            return sales.filter(pur => pur.customer.name === name);
        }

    }

    const loadBills = () => {

        let users = getUsers();
    
        if (users && users.length>0) {

            return (<Row className="g-2 mb-2 mt-2">
                {
                    users.map((user,k) => {
                        return (
                           
                            <Card border="dark" className="p-0" key={k}>
                                <Card.Header className="bg-dark">{user.name} <p className="float-end  m-0 tiles"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                </svg> <span>&#x20b9; {Number(user.wallet).toFixed(2)}</span></p></Card.Header>
                                <Card.Body className="p-1">
                                    {

                                        getBills(user.name).map((pur, i) => {
                                            return (
                                                <Col md={12} sm={6} key={i} className="mt-1" onClick={() => { toggleSelect(pur.id) }}>
                                                    <Card border="secondary" key={`card${i}`}>
                                                        <Card.Body className="p-1">
                                                            <Container fluid className="">

                                                                <Row className="ps-1 pe-1 border-bottom">
                                                                    <Col className="text-start col p-0 " title="Created By">
                                                                        <i className="fa fa-id-card-o"></i>
                                                                        <strong> {pur.createdBy ? pur.createdBy.name : "admin"} </strong></Col>
                                                                    <Col className="text-end col border-start p-0"> <p className="text-secondary m-0"><i className="fa fa-calendar"></i> {pur.billDate} </p></Col>
                                                                </Row>
                                                                <Row className="ps-1 pe-1">

                                                                    <Col className="p-0 border-end col-1" >
                                                                        <i className={`fa-2x fa ${selectedIds[pur.id] && selectedIds[pur.id] === "Y" ? "fa-check text-primary" : "fa fa-square-o text-dark"}`}></i>

                                                                    </Col>
                                                                    <Col className=" text-start border-end">
                                                                        <p className="m-0"> <i className="fa fa-file-code-o"></i>
                                                                        {` ${pur.billNo}`} </p>

                                                                    </Col>

                                                                    <Col className="p-0 text-end">
                                                                        <p className={`m-0 text-end ${pur.status === "UNPAID" ? "text-danger" : "text-success"}`}>&#x20b9; {pur.billAmount} </p>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>)
                                        })
                                    } </Card.Body>
                            </Card>
                        )
                    })
                }
            </Row>);
        }

        else {
            return (<Alert variant="warning" className="mt-2">No Records</Alert>);
        }

    }

    return (
        <div>
            {
                (<Container fluid className="p-1">

                    <Card className={`mb-0 mt-1`} border="dark">

                        {/* <Tab.Container  id="tab"> */}
                            <Card.Header className="bg-dark">

                                <Nav className="me-auto" defaultActiveKey={selectedMode} id="typeTab" variant="tabs" onSelect={(e) => loadBillsByMode(e)}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="purchase">Purchase</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sales">Sales</Nav.Link>
                                    </Nav.Item>
                                    
                                        <Nav.Item className="ms-auto">

                                            <Button variant="success" size="sm" onClick={() => { handleClosePayment() }} >Close Bills</Button>
                                        </Nav.Item>

                                </Nav>


                            </Card.Header>
                            <Card.Body className="p-0" style={{ "height": "79vh", "overflowX": "auto" }}>
                                <Container fluid className="p-1">
                                    {
                                                                             
                                        loadBills()
                                    
                                    }
                                </Container>
                            </Card.Body>
                            
                        {/* </Tab.Container> */}
                    </Card>
                </Container>)

            }
        </div>
    );
}
