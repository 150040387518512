import {LOADALLVENDOR,LOADVENDOR,CHANGEVENDORPRODUCT,
        ADDVENDORPRODUCT,REMOVEVENDORPRODUCT,CHANGEVENDOR,
        RESETVENDOR,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


function loadAllVendorService(status){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/vendors?status=${status&&status==='active'?'active':'dormant'}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLVENDOR, vendor: (response.data) });
           }
           else{
            resolve({ type: LOADALLVENDOR, vendor: []});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
            });
    });

}

function loadVendorService(vendorId){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/vendors/${vendorId}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADVENDOR, vendor: (response.data) });
           }
           else{
            resolve({ type: LOADVENDOR, vendor: {}});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load vendor data",variant:"danger",err}  })
            });
    });


}


function saveVendorService(vendor,method){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": method,
            "url": `/api/vendors`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(vendor)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Vendor saved successfully", variant: "success", err: "" }
                });

            resolve({ type: RESETVENDOR});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving vendor",variant:"danger",err}  })
            });
    });
  
  }

  function activeVendorService(vendorIds){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/vendors/status/active`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(vendorIds)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Vendor updated", variant: "success", err: "" }
                });

            resolve(loadAllVendorService("dormant"));

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while updating vendor",variant:"danger",err}  })
            });
    });
  
  }

  
 export const saveVendor=(vendor,method)=>{

    return dispatch => {

        saveVendorService(vendor,method)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



 export const loadAllVendor=(status)=>{

    return dispatch => {

        loadAllVendorService(status)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadVendor=(vendorId)=>{

  return dispatch => {

    loadVendorService(vendorId)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));

  }

}

export const changeVendor=({name,value})=>{

    return dispatch => {
      dispatch({type:CHANGEVENDOR,name,value});
  }
    
  
}

export const changeVendorProduct=(product)=>{

    return dispatch => {
      dispatch({type:CHANGEVENDORPRODUCT,product});
  }
    
  
}
  
export const addVendorProduct=(product)=>{

    return dispatch => {
      dispatch({type:ADDVENDORPRODUCT,product});
  }
  
}

export const removeVendorProduct=(idx)=>{

    return dispatch => {
      dispatch({type:REMOVEVENDORPRODUCT,idx});
  }
  
}
 
export const resetVendor=()=>{
   
   return dispatch=>{

       dispatch({type:RESETVENDOR});
   }
}

export const updateStatus=(vendorIds)=>{

    return dispatch => {

        activeVendorService(vendorIds)
              .then(response => dispatch(response))
              .catch(err => dispatch(err));
    
      }
    
    
}