import {LOADSTATEMENTS, LOADSUMMARY,SET_NOTIFICATION,RESETSTATEMENT} from "../constant/constant";
import {pAxios} from "..";


function loadSummaryService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": "/api/summary",
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADSUMMARY, summary: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load summary data",variant:"danger",err}  })
            });
    });

}


function loadStatementService(param){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/statement?${param}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADSTATEMENTS, statement: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load summary data",variant:"danger",err}  })
            });
    });

}


 export const loadSummary=()=>{

    return dispatch => {

        loadSummaryService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }
}

export const loadStatement=(data)=>{

    let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
    return dispatch => {

        loadStatementService(url)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }
}

export const resetStatement=()=>{
   
    return dispatch=>{
 
        dispatch({type:RESETSTATEMENT});
    }
 }
 





