import {
    RESETPURCHASE,
    CHANGEITEMPURCHASE,
    CHANGEPURCHASE,
    ADDITEMPURCHASE,
    RESETITEMPURCHASE,
    LOADALLPURCHASE,
    LOADPURCHASE,
    CANCELEDITPURCHASE,
    RESETPURCASEEDIT,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.purchase }, action) {

    switch (action.type) {

        case LOADALLPURCHASE:
            return { ...state, purchases: [...action.purchases.rows] ,page:action.purchases.totalPages,count:action.purchases.count,sum:action.purchases.sum };
        case LOADPURCHASE:
            return { ...state, purchase: { ...action.purchase } };

        case CHANGEPURCHASE:
            return { ...state, purchase: { ...state.purchase, [action.name]: action.value } };

        case ADDITEMPURCHASE:
            return {
                ...state, purchase: {
                    ...state.purchase, products: [...(state.purchase.products ?
                        [...state.purchase.products, { ...action.product }]
                        : ([{ ...action.product }]))]
                }
            };
        // case REMOVEVENDORPRODUCT:
        //     return {
        //         ...state, vendor: {
        //             ...state.vendor, products: [
        //                 ...(state.vendor.products.filter((product) =>
        //                     product.idx != action.idx

        //                 ))
        //             ]
        //         }
        //     };
        case CHANGEITEMPURCHASE:
            return {
                ...state, purchase: {
                    ...state.purchase, products: [
                        ...(state.purchase.products.map((product) => {
                            if (product.id === action.id)
                                return { ...product, quantity: action.value };
                            return product;
                        }))
                    ]
                }
            };
        case RESETPURCASEEDIT:
            return { ...state, purchase: {billDate:state.purchase.billDate, vendorId:state.purchase.vendorId } }
        case RESETITEMPURCHASE:
            return { ...state, purchase: { ...state.purchase, products: [] } }
        case RESETPURCHASE:
            return { ...state, purchase: {} ,purchases:[],page:0,sum:{},count:0};
        case CANCELEDITPURCHASE:
            return {...state, purchase:{billDate:state.purchase.billDate,vendorId:state.purchase.vendorId }}
        default:
            return { ...state };
    }
}