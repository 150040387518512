import {
    LOADPRODUCTLOOKUPS,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState }, action) {

    switch (action.type) {
        case LOADPRODUCTLOOKUPS:
            return {...state,productLookup:[...action.productLookup]}
        default:
            return { ...state };
    }
}