import React, { useState } from "react";
import { Container,  Card,Nav,  } from "react-bootstrap";
import ViewBills from "./ViewBills";

export default function ClosedBill() {
    const [selectedMode, setSelectedMode] = useState("purchase");

    const loadBills = () => {
      return (<ViewBills header={()=>""} defltr={{"status":"PAID"}} billType={selectedMode} billItem={true}></ViewBills>);
    }

    return (
        <div>
            {
                (<Container fluid className="p-1">

                    <Card className={`mb-0 mt-1`} border="dark">

                        {/* <Tab.Container  id="tab"> */}
                            <Card.Header className="bg-dark">

                                <Nav className="me-auto" defaultActiveKey={selectedMode} id="typeTab" variant="tabs" onSelect={(e) => setSelectedMode(e)}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="purchase">Purchase</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sales">Sales</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-0" style={{ "height": "100%", "overflowX": "auto" }}>
                                <Container fluid className="p-1">
                                    {                                                                            
                                        loadBills()
                                    }
                                </Container>
                            </Card.Body>
                            
                        {/* </Tab.Container> */}
                    </Card>
                </Container>)

            }
        </div>
    );
}
