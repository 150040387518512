import {
    LOADPRODUCT,
    LOADALLPRODUCT,
    RESETPRODUCT,
    CHANGEPRODUCT,
    ADDPRODUCTTYPE,
    CHANGEPRODUCTTYPE,
    REMOVEPRODUCTTYPE,
    
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.product }, action) {

    switch (action.type) {
        case LOADALLPRODUCT:
            return { ...state, products: [...action.product.rows] };

        case LOADPRODUCT:
            return { ...state, product: { ...action.product } };

        case CHANGEPRODUCT:
            return { ...state, product: { ...state.product, [action.name]: action.value } };

        case ADDPRODUCTTYPE:
            return {
                ...state, product: {
                    ...state.product, subtypes: [...(state.product.subtypes ?
                        [...state.product.subtypes, { ...action.subtype,idx:state.product.subtypes.length+1 }] : ([{ ...action.subtype,idx:1 }]))]
                }
            };
        case REMOVEPRODUCTTYPE:
            return {
                ...state, product: {
                    ...state.product, subtypes: [
                        ...(state.product.subtypes.filter((sub) =>
                            sub.idx !== action.idx

                        ))
                    ]
                }
            };
        case CHANGEPRODUCTTYPE:
            return {
                ...state, product: {
                    ...state.product, subtypes: [
                        ...(state.product.subtypes.map((sub) => {
                            if (sub.idx === action.subtype.idx)
                                return { ...action.subtype };
                            return sub;
                        }))
                    ]
                }
            };
        case RESETPRODUCT:
            return { ...state, product: {} };
        default:
            return { ...state };
    }
}