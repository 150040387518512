import {
    LOADVENDOR,
    LOADALLVENDOR,
    RESETVENDOR,
    CHANGEVENDOR,
    CHANGEVENDORPRODUCT,
    ADDVENDORPRODUCT,
    REMOVEVENDORPRODUCT,
    initialState,
    sortByName,
    sortByText
} from "../constant/constant";

function sortProductName(vendor){


    return {...vendor,products:sortByText(vendor.products)}
}

export default function reducer(state = { ...initialState.vendor }, action) {

    switch (action.type) {
        case LOADALLVENDOR:
            return { ...state, vendors: [...sortByName(action.vendor.rows)] };

        case LOADVENDOR:
            return { ...state, vendor: { ...sortProductName(action.vendor) } };

        case CHANGEVENDOR:
            return { ...state, vendor: { ...state.vendor, [action.name]: action.value } };

        case ADDVENDORPRODUCT:
            return {
                ...state, vendor: {
                    ...state.vendor, products: [...(state.vendor.products ?
                        [...state.vendor.products, { ...action.product,idx:state.vendor.products.length+1  }] : ([{ ...action.product,idx:1 }]))]
                }
            };
        case REMOVEVENDORPRODUCT:
            return {
                ...state, vendor: {
                    ...state.vendor, products: [
                        ...(state.vendor.products.filter((product) =>
                            product.idx !== action.idx

                        ))
                    ]
                }
            };
        case CHANGEVENDORPRODUCT:
            return {
                ...state, vendor: {
                    ...state.vendor, products: [
                        ...(state.vendor.products.map((product) => {
                            if (product.idx === action.product.idx)
                                return { ...action.product };
                            return product;
                        }))
                    ]
                }
            };
        case RESETVENDOR:
            return { ...state, vendor: {} };
        default:
            return { ...state };
    }
}