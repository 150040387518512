import {
    
    LOADALLEXPENSE,
    LOADEXPENSE,
    CHANGEEXPENSE,
    RESETEXPENSE,CANCELEDITEXPENSE,
     initialState
 } from "../constant/constant";
 
 export default function reducer(state = { ...initialState.expense }, action) {
 
     switch (action.type) {
         case LOADALLEXPENSE:
             return { ...state, expenses: [...action.expense.rows],page:action.expense.totalPages,count:action.expense.count };
 
         case LOADEXPENSE:
             return { ...state, expense: { ...action.expense } };
 
         case CHANGEEXPENSE:
             return { ...state, expense: { ...state.expense, [action.name]: action.value } };
 
             case CANCELEDITEXPENSE:
                return {...state, expense:{billDate:state.expense.billDate }}
         case RESETEXPENSE:
             return { ...state, expense: {},expenses:[],page:0,count:0 };
         default:
             return { ...state };
     }
 }