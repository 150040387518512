import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Card, Row, Col, Nav, Button, Alert ,DropdownButton,Dropdown} from "react-bootstrap";
import DatePicker from "react-datepicker";
import fileDownload from 'js-file-download'


import { loadStatement, resetStatement } from "../action/summaryAction"

import { loadAllVendor } from "../action/vendorAction";
import { loadAllCustomer } from "../action/customerAction";
import { loadAllUser } from "../action/userAction";
import { formatDate,formatDateView } from "../constant/dateFormat";
import { pAxios } from ".."
import { isCustomer, isVendor, isOwner, isPartner, isEmployee, isAccounts } from "../constant/permission"

export default function Statements() {


    const dispatch = useDispatch();

    const [stackHolder, setStackHolder] = useState("Vendor");
    const [selectedIds, setSelectedIds] = useState();
    const statement = useSelector(
        (state) => state.summary.statement
    );

    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const users = useSelector(
        (state) => state.user.users
    );


    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    React.useEffect(() => {

        dispatch(loadAllVendor('active'));
        dispatch(loadAllCustomer('active'));
        dispatch(loadAllUser());



        if (isVendor()) {
            setStackHolder("Vendor");
            setSelectedIds(localStorage.getItem("stakeHolderId"));
        }
        else if (isCustomer()) {
            setStackHolder("Customer");
            setSelectedIds(localStorage.getItem("stakeHolderId"));
        }


    }, [dispatch]);

    React.useEffect(() => {
        dispatch(resetStatement());
    }, [stackHolder])


    const getStatement = () => {


        if (stackHolder === "Expense" && (isEmployee() || isPartner())) {

            setSelectedIds(localStorage.getItem("userId"));
        }

        if (startDate && endDate && selectedIds) {

            dispatch(loadStatement({
                fromDate: formatDate(startDate),
                toDate: formatDate(endDate),
                stackHolder: stackHolder.toLowerCase(),
                stackHolderId: selectedIds
            }));
        }
    }

    const handleDownload = (e) => {

        if (startDate && endDate && selectedIds) {

            const data = {
                fromDate: formatDate(startDate),
                toDate: formatDate(endDate),
                type: stackHolder.toLowerCase(),
                stackHolder: stackHolder.toLowerCase(),
                stackHolderId: selectedIds,
                
            }

            let url = Object.keys(data).map(function (k) {
                return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
            }).join('&')

            pAxios({
                "method": "GET",
                "url": "/api/auth",

            }).then(r => {

                pAxios.get(`/api/${e==="summary"?"summary/details":"statement"}/download?${url}`, {
                    responseType: 'blob',
                })
                    .then((res) => {
                        fileDownload(res.data, `${e}-${stackHolder.toLowerCase()}-${selectedIds}-${formatDateView(startDate)}-${formatDateView(endDate)}.pdf`)
                    })
            })

        }
    }

    const getStackHolderSelection = () => {

        if (stackHolder === "Vendor") {
            return (
                <Row className="g-2 ">
                    <Col>
                        <Form.Group >
                            <Form.Label>Vendor</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                }}>
                                <option value={0}>Select a Vendor</option>
                                {
                                    vendors ? vendors.map((vendor, idx) => {
                                        return <option key={idx} value={vendor.id}>{vendor.name}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>);
        }
        else if (stackHolder === "Customer") {
            return (
                <Row className="g-2 ">
                    <Col>
                        <Form.Group >
                            <Form.Label>Customer</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                }}>
                                <option value={0}>Select a Customer</option>
                                {
                                    customers ? customers.map((cust, idx) => {
                                        return <option key={idx} value={cust.id}>{cust.name}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>);
        }
        else {

            return isOwner() || isAccounts() ? (
                <Row className="g-2 ">
                    <Col>
                        <Form.Group >
                            <Form.Label>User</Form.Label>
                            <Form.Select size="sm"
                                value={selectedIds}
                                onChange={(e) => {
                                    setSelectedIds(e.target.value);
                                }}>
                                <option value={0}>Select User</option>
                                {
                                    users ? users.filter(user => user.role !== "CUSTOMER" && user.role !== "VENDOR").map((user, idx) => {
                                        return <option key={idx} value={user.id}>{user.username}</option>;
                                    }) : ("")
                                }
                            </Form.Select>
                        </Form.Group></Col></Row>) : ("");
        }






    }


    const generateRow = () => {
        let totalC = 0, totalD = 0;

        if (!statement || statement.length === 0) {
            return (<Alert variant="warning" className="mt-2">No Records</Alert>)
        }
        else {

            return (
                <React.Fragment>
                    <Col md={12} sm={6} key={"add-Header"} className="m-0">
                        <Card className="border-bottom rounded-0" >
                            <Card.Body className="p-0">

                                <Container fluid>
                                    <Row className="ps-2 " >
                                        <Col className="text-center p-0 col-7 border-end fw-bold rounded-0">

                                            <span>{"Description"}</span>
                                        </Col>
                                        <Col className="text-center p-0 pe-1 fw-bold">

                                            <span> Credit </span>
                                        </Col>
                                        <Col className="text-center p-0 border-start fw-bold rounded-0">

                                            <span>Debit</span>
                                        </Col>

                                    </Row>

                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                    {

                        statement.map((pur, i) => {
                            totalC = Number(totalC) + Number(pur.credit);
                            totalD = Number(totalD) + Number(pur.debit);
                            return (
                                <Col md={12} sm={6} key={i} className="m-0">
                                    <Card className="border-bottom rounded-0" >
                                        <Card.Body className="p-0">

                                            <Container fluid>
                                                <Row className="ps-2" >
                                                    <Col className="text-start p-0 col-7 border-end rounded-0">

                                                        <span>{pur.desc} </span>
                                                    </Col>
                                                    <Col className="text-end p-0 pe-1">

                                                        <span>{pur.credit} </span>
                                                    </Col>
                                                    <Col className="text-end p-0 border-start rounded-0">

                                                        <span>{pur.debit} </span>
                                                    </Col>

                                                </Row>

                                            </Container>
                                        </Card.Body>
                                    </Card>
                                </Col>)
                        })
                    }
                    <Col md={12} sm={6} key={"add-total"} className="m-0">
                        <Card className="border-bottom rounded-0" >
                            <Card.Body className="p-0">

                                <Container fluid>
                                    <Row className="ps-2" >
                                        <Col className="text-center p-0 col-7 border-end fw-bold rounded-0">

                                            <span>{"Total"}</span>
                                        </Col>
                                        <Col className="text-end p-0 pe-1 fw-bold">

                                            <span>{Number(totalC).toFixed(2)} </span>
                                        </Col>
                                        <Col className="text-end p-0 border-start fw-bold rounded-0">

                                            <span>{Number(totalD).toFixed(2)} </span>
                                        </Col>

                                    </Row>

                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </React.Fragment>)
        }



    }


    return (
        <div>
            {
                (<Container fluid className="mt-1 p-0">
                    <Card className={`mb-0 mt-1`} border="dark">
                        <Card.Header className="bg-dark">

                            {!isVendor() && !isCustomer() ? (
                                <Nav variant="tabs" defaultActiveKey={stackHolder} onSelect={(e) => { setStackHolder(e); setSelectedIds(0); setEndDate(null); setStartDate(null) }}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Vendor">Vendor</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Customer">Customer</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Expense">Expense</Nav.Link>
                                    </Nav.Item>

                                </Nav>)
                                : (stackHolder)
                            }


                        </Card.Header>

                        <Card.Body className="p-1" >
                            <Container fluid>
                                <Row className="g-2 mb-2">
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Date</Form.Label>

                                            <DatePicker maxDate={new Date()}
                                                placeholderText="Select date range"
                                                dateFormat="dd-MM-yyyy"
                                                selected={startDate}
                                                onChange={onChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                className="form-control form-control-sm h-100"
                                                isClearable={true}
                                            ></DatePicker>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {
                                    !isVendor() && !isCustomer() ?
                                        (
                                            getStackHolderSelection()
                                        ) : ("")
                                }




                            </Container>
                        </Card.Body>

                    </Card>
                    <Card border="dark" >

                        <Card.Header className="bg-dark" as="h6">
                            Statement


                            

                            <DropdownButton variant="danger" className="d-inline float-end" size="sm"  title={<i className="fa fa-download"></i>} id="state-down" onSelect={(e)=>handleDownload(e)}>
                            {stackHolder !== "Expense" ? (<Dropdown.Item eventKey="summary">Summary</Dropdown.Item>) : ("")}
                                
                                <Dropdown.Item eventKey="statement">Statement</Dropdown.Item>
                            </DropdownButton>

                            <Button className="float-end" variant="success" size="sm" onClick={getStatement} ><i className="fa fa-search"></i></Button>
                        </Card.Header>
                        <Card.Body className="p-1 mt-2" style={{ fontSize: ".8rem", "height": "50vh", "overflowX": "auto" }}>

                            {
                                generateRow()
                            }

                        </Card.Body>
                    </Card>
                </Container>)

            }
        </div >
    );
}
