import {
    LOADALLUSER,
    LOADUSER,
    RESETUSER,
    CHANGEUSERFIELD,
    GETPASSWORD,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.user }, action) {

    switch (action.type) {
        case LOADALLUSER:
            return {...state,users:[...action.user.rows]}
        case GETPASSWORD:
            return {...state,users:[...state.users.map((user)=>{
                if(user.id===action.userId){
                    return{...user,password:action.password}
                }
                return {...user};
            })]}
        case LOADUSER:
            return { ...state, user:{...action.user} };
        case CHANGEUSERFIELD:
            return { ...state,user:{...state.user,[action.name]:action.value}};
        case RESETUSER:
            return {...state,user:{}}
        default:
            return { ...state };
    }
}