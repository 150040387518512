var auth_user = [];

export const setPermissions=(permissions)=>{

    auth_user=permissions;

}
export const isPermitted = (name) => {

    if (auth_user) {
        
        return auth_user.some(user=> user===name.toUpperCase());
    }

    return false;
}

export const isVendor =()=>localStorage.getItem("role")==="VENDOR"
export const isCustomer= ()=>localStorage.getItem("role")==="CUSTOMER"
export const isOwner=()=> localStorage.getItem("role")==="OWNER"
export const isEmployee=()=> localStorage.getItem("role")==="EMPLOYEE"
export const isAccounts=()=> localStorage.getItem("role")==="ACCOUNTS"
export const isPartner=()=> localStorage.getItem("role")==="PARTNER"