
import {pAxios} from "..";



export function loadAllLookupsService(){

    

        pAxios({
            "method": "GET",
            "url": `api/lookups`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                      
                localStorage.setItem("lookup",JSON.stringify(response.data));
               
           }
           else{
                localStorage.setItem("lookup",JSON.stringify({}));
           }
        })
            .catch(err => {
                
                localStorage.setItem("lookup",JSON.stringify({}));
            });
    

}

