

import React from "react";
import { Col ,Button } from "react-bootstrap";
import Avatar, {  } from 'react-avatar';


export default function ProductListing({element,handleShow,toggleSelect,selectedIds,status}) {



    return (<Col xs="6" md="6" className="text-center" >
    {
        status && status === 'active' ?
            (
                <Button variant="link" className="text-decoration-none" onClick={() => {
                    handleShow(element.id);
                }}>
                    <div>
                        <Avatar  className="fw-bold" className="p-1" value={element.name.substring(0, 2).toUpperCase()} round={true} size="60" />
                    </div>
                    <span>{element.name}</span>
                </Button>
            ) :
            (<Button variant="link" className="text-decoration-none" onClick={() => {
                toggleSelect(element.id);
            }}>
                <div>
                    {selectedIds[element.id] && selectedIds[element.id] === "Y" ?
                        (<i className="fa-4x fa fa-check-circle"></i>)
                        : (<Avatar   className="fw-bold" className="p-1" value={element.name.substring(0, 2).toUpperCase()} round={true} size="60" />)}
                </div>
                <span >{element.name}</span>
            </Button>
            )
    }
</Col>);
}

