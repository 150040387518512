import {LOADALLUSER,LOADUSER,RESETUSER,CHANGEUSERFIELD,GETPASSWORD,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";


function loadAllUserService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": "/api/users",
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLUSER, user: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user data",variant:"danger",err}  })
            });
    });

}

function loadUserService(userid){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": "GET",
          "url": `/api/users/${userid}`,
          "headers": {
              'content-type': 'application/json'
          }
        
      }).then(response => {

         if(response.data){
                     
             resolve({ type: LOADUSER, user: (response.data) });
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user data",variant:"danger",err}  })
          });
  });

}


function getPasswordUserService(userId){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/users/${userId}/password`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data && response.data.plain){
                       
               resolve({ type: GETPASSWORD, password: (response.data.plain),userId });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to get user password",variant:"danger",err}  })
            });
    });
  
  }

function saveUserService(user,method){

  return new Promise(function (resolve, reject) {

      pAxios({
          "method": method,
          "url": `/api/users`,
          "headers": {
              'content-type': 'application/json'
          },
          "data":JSON.stringify(user)
        
      }).then(response => {

         if(response.data){
               
          store.dispatch({
            type: SET_NOTIFICATION, notification:
                { message: `User ${method==="POST"?"created":"updated"} successfull`, variant: "success", err: "" }
            });
             resolve({ type: RESETUSER});
         }
      })
          .catch(err => {
              
              reject({ type: SET_NOTIFICATION,notification:{message:"Failed to save user data",variant:"danger",err}  })
          });
  });

}

function resetPasswordService(password){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "PATCH",
            "url": `/api/users`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({id:Number(localStorage.getItem("userId")),password})
          
        }).then(response => {
  
           if(response.data){
                 
            store.dispatch({
              type: SET_NOTIFICATION, notification:
                  { message: `Password reset successfully`, variant: "success", err: "" }
              });
               resolve({ type: RESETUSER});
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to reset password",variant:"danger",err}  })
            });
    });
  
  }

 export const loadAllUser=()=>{

    return dispatch => {

        loadAllUserService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadUser=(userid)=>{

  return dispatch => {

      loadUserService(userid)
          .then(response => dispatch(response))
          .catch(err => dispatch(err));

  }

}

export const getUserPassword=(userid)=>{

    return dispatch => {
  
        getPasswordUserService(userid)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  
  }
  
  export const resetPassword=(password)=>{

    return dispatch => {
  
        resetPasswordService(password)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));
  
    }
  
  }
  

  

export const changeUser=({name,value})=>{

  return dispatch => {
    dispatch({type:CHANGEUSERFIELD,name,value});
}
  

}

export const resetUser=()=>{

  return dispatch => {
    dispatch({type:RESETUSER,});
}
  
}

export const saveUser=(user)=>{

  return dispatch => {
    saveUserService(user,"POST")
    .then(response => dispatch(response))
    .catch(err => dispatch(err));
}
  
}


export const updateUser=(user)=>{

    return dispatch => {
      saveUserService(user,"PATCH")
      .then(response => dispatch(response))
      .catch(err => dispatch(err));
  }
    
  }



