import React, { } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../app/history";
import { Navbar, NavDropdown, Nav, Container, Accordion, ListGroup, Col,Form,Button,Modal } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch, Link, } from "react-router-dom";

import User from "./User";
import Product from "./Product";
import Purchase from "./Purchase";
import Vendor from "./Vendor";
import Customer from "./Customer";
import Sales from "./Sales";
import UserEntry from "./UserEntry";
import CustomerEntry from "./CustomerEntry";
import VendorEntry from "./VendorEntry";
import ProductEntry from "./ProductEntry";
import { Bill as ViewBills } from "./ViewBills";
import Summary from "./Summary";
import Payment from "./Payment";
import Expense from "./Expense";
import BillSettlment from "./BillSettelment";
import ClosedBill from "./ClosedBill";
import Report from "./Report";
import ViewExpenses from "./ViewExpenses";
import Statement from "./Statements";
import ViewPayments from "./ViewPayments";
import ViewStocks from "./ViewStocks";
import { isCustomer, isPermitted, isVendor, setPermissions } from "../constant/permission"
import { logout } from "../action/loginAction";
import { menus } from "../constant/menu";
import {resetPassword} from "../action/userAction";
//import {isAuthenticated} from "../constant/resetLocal".
import { loginUser } from "../action/loginAction";
import Reconciliation from "./Reconciliation";
export function Home() {

    const dispatch = useDispatch();


    const auth_user = useSelector(
        (state) => state.auth_user.auth_user
    );

    const [newMenu, setNewMenu] = React.useState({});

    const [showMenu, setShowMenu] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const [hide,setHide]=React.useState(false);
    
    const [show, setShow] = React.useState(false);
    const [password, setPassword] = React.useState(null);
    //    const [isAuth,setIsAuth]=React.useState(false);



    React.useEffect(() => {
        return history.listen(location => {

            if (history.action === 'POP') {

                history.push("/")
            }
        })
    }, [])


    React.useEffect(() => {

        const userId = localStorage.getItem("userId");

        if (!auth_user && userId) {

            dispatch(loginUser(userId));
        }

        if (auth_user && auth_user.permissions) {
            setPermissions(auth_user.permissions);
            let newMenu = {};
            Object.keys(menus).forEach((k) => {
                newMenu = { ...newMenu, [k]: [...(menus[k].filter((menu) => isPermitted(menu.name)))] };
            });
            setNewMenu({ ...newMenu });
        }
    }, [auth_user, dispatch]);



    const handleClose = () => {
        setShow(false);
        setPassword(null);

    }

    const handleShow = () => {

        setShow(true);

    }

    async function auth() {
        // let auth = await isAuthenticated();

        // setIsAuth(auth);
    }

    const hideMenu=()=>{
        setShowMenu(true);
    }

    const LandingPage = () => {

        return (<Container className=" mt-2 col-md-12 col-8" fluid>
            <Accordion >
                {
                    Object.keys(newMenu).filter((k) => newMenu[k].length > 0).map((k, i) => {

                        return (
                            <Accordion.Item key={k} eventKey={k}>
                                <Accordion.Header>{k}</Accordion.Header>
                                <Accordion.Body className="p-1">
                                    <ListGroup>
                                        {
                                            newMenu[k].map((menu, j) => {
                                                return (<ListGroup.Item key={j}> <Link to={menu.link} className="link"   > {menu.label}</Link></ListGroup.Item>);
                                            })
                                        }
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })
                }
            </Accordion>
        </Container>)

    }

    return (

        <div style={{ height: "100vh" }}>

            <Container fluid className="p-0">
                <Router>

                    {
                        !showMenu ? (
                            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="p-0"  >
                                {/* expanded={expanded} */}
                                <Container fluid >
                                    <Navbar.Brand className="order-last p-0 row me-0 mx-auto">
                                        {/* <i className="fa fa-home fa-2x"></i> */}
                                        {!isVendor() && !isCustomer()?(<Col className="fs-6 border-secondary border-start border-end">
                                            <p className="tiles m-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet-fill" viewBox="0 0 16 16">
                                                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                    <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z" />
                                                </svg> <span> {auth_user && auth_user.wallet ? Number(auth_user.wallet).toFixed(2) : "0.00"} </span></p>
                                        </Col>):("")}
                                        
                                        <Col className="fs-5 pe-0">
                                            <NavDropdown className="p-0" title={<React.Fragment><i className="fa fa-user-o fa-2x"></i> {localStorage.getItem("userName")}</React.Fragment>} className="left-drop">
                                                <NavDropdown.Item as="li" eventKey={0.1} onClick={() => { dispatch(logout()) }}>
                                                    Logout</NavDropdown.Item>
                                                
                                                    <NavDropdown.Item as="li" eventKey={0.1} onClick={handleShow}>
                                                    Change Password</NavDropdown.Item>
                                                
                                                    
                                            </NavDropdown>
                                        </Col>


                                    </Navbar.Brand>

                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="float-left" onClick={() => setExpanded(expanded ? false : "expanded")} />
                                    <Navbar.Collapse id="responsive-navbar-nav">
                                        <Nav className="me-auto" >
                                            <Nav.Link href="/" className="nav-home"> <i className="fa fa-home fa-2x"></i> </Nav.Link>
                                            {
                                                Object.keys(newMenu).filter((k) => newMenu[k].length > 0).map((k, i) => {
                                                    return (
                                                        <NavDropdown key={k} title={k} id={`collasible-nav-${k}-dropdown`} onSelect={() => { }}>
                                                            {
                                                                newMenu[k].map((menu, j) => {
                                                                    return (<NavDropdown.Item as="li" className="p-0" eventKey={`${j}_${k}`} key={`${j}_${k}`}>  <Link to={menu.link} className="link nav-link" onClick={() => setExpanded(expanded ? false : "expanded")} > {menu.label}</Link></NavDropdown.Item>);
                                                                })
                                                            }
                                                        </NavDropdown>
                                                    );
                                                })
                                            }
                                        </Nav>
                                        <Nav className="float-lg-end">


                                            {/* <Nav.Link className="text-light" onClick={() => { dispatch(logout()) }} >Logout</Nav.Link> */}
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        ) : ("")
                    }

                    <Container className="col-md-6 col-lg-4 p-0" fluid>

                        <Switch>
                            <Route exact path="/users/new" component={UserEntry} />
                            <Route exact path="/users/view" component={User} />
                            <Route exact path="/product/new" component={ProductEntry} />
                            <Route exact path="/product/view" component={Product} />
                            <Route exact path="/vendor/new" component={VendorEntry} />
                            <Route exact path="/vendor/view" component={Vendor} />
                            <Route exact path="/customer/new" component={CustomerEntry} />
                            <Route exact path="/customer/view" component={Customer} />
                            <Route exact path="/purchase/view" ><ViewBills billType="purchase" /></Route>

                            <Route exact path="/purchase/new"  ><Purchase hideMenu={hideMenu}/> </Route>
                            <Route exact path="/sales/view" billType="sales"><ViewBills billType="sales" /> </Route>
                            <Route exact path="/sales/new" ><Sales hideMenu={hideMenu}/> </Route> 
                            <Route exact path="/payment/new" component={Payment} />
                            <Route exact path="/payment/view" component={ViewPayments} />
                            <Route exact path="/expense/new" component={Expense} />
                            <Route exact path="/expense/view" component={ViewExpenses} />
                            <Route exact path="/pendingBill" component={BillSettlment} />
                            <Route exact path="/closedBill" component={ClosedBill} />
                            <Route exact path="/summary" component={Summary} />
                            <Route exact path="/statement" component={Statement} />
                            <Route exact path="/stocks" component={ViewStocks} />
                            <Route exact path="/reports" component={Report} />
                            <Route exact path="/reconciliation" component={Reconciliation}/>
                            <Route path="/" component={LandingPage} />

                        </Switch>
                    </Container>


                </Router>
            </Container>

            <Modal show={show} onHide={handleClose} centered size="sm">
                <Modal.Header closeButton as='h6'>
                    Reset Password
                </Modal.Header>
                <Modal.Body>
                    <div className="row gap-2">
                        <Form>
                            <Form.Group >
                                <Form.Label>New Password</Form.Label>
                                <Form.Control size="sm" placeholder="Password" type="password" onChange={e => setPassword(e.target.value)} value={password ? password : ""}></Form.Control>
                            </Form.Group>
                        </Form>
                    </div>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mt-2">
                        
                                <Button variant="danger" disabled={!password} size="sm" onClick={() => {
                                    dispatch(resetPassword(password)); handleClose();
                                }}>Update
                                </Button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    );
}

