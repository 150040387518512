import {CHANGEPAYMENT, LOADALLPAYMENT,
    RESETPAYMENT,
    RESETSALE,
    RESETPURCHASE,
    RESETEXPENSE,
    SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";
import {loadAllPurchaseService} from "./purchaseAction";
import {loadAllSaleService} from "./saleAction";
import {loadAllExpenseService} from "./expenseAction";
 
function loadAllPaymentService(url){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/payments?${url}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLPAYMENT, payment: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load summary data",variant:"danger",err}  })
            });
    });

}



function savePaymentService(payment){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/payments`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(payment)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Payment entry saved successfully", variant: "success", err: "" }
                });

            resolve({ type: RESETPAYMENT});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving payment",variant:"danger",err}  })
            });
    });
  
  }

  
function closePaymentService(payment){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/payments/close`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(payment)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Bill close requested successfully", variant: "success", err: "" }
                });

                if(payment.purchaseIds){
                    resolve(loadAllPurchaseService("status=UNPAID"));
                }
                else{
                    resolve(loadAllSaleService("status=UNPAID"))
                }
            //resolve({ type: RESETPAYMENT});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while processing bill closing",variant:"danger",err}  })
            });
    });
  
  }


  
  
function reconsileService({stackHolder,selectedIds,loadCondition}){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/reconcile`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({stackHolder,selectedIds})
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Bill reconsiled successfully", variant: "success", err: "" }
                });

                let url = Object.keys(loadCondition).map(function (k) {
                    return encodeURIComponent(k) + '=' + encodeURIComponent(loadCondition[k])
                }).join('&')

                if(stackHolder==="Vendor"){
                    resolve(loadAllPurchaseService(url));
                }
                else if(stackHolder==="Customer"){
                    resolve(loadAllSaleService(url))
                }
                else{
                    resolve(loadAllExpenseService(url));
                }
            //resolve({ type: RESETPAYMENT});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while processing bill reconsilation",variant:"danger",err}  })
            });
    });
  
  }

  export const loadAllPayments=({ date = null,  limit = null, status = null, fromDate = null, toDate = null, page = null,userId=null }) => {

    let data = {};

    if (date) {
        data = { ...data, fromDate: date, toDate: date }
    }
    else if (fromDate && toDate) {
        data = { ...data, fromDate, toDate }
    }


    if (limit) {
        data = { ...data, limit }
    }

    if (page) {
        data = { ...data, page }
    }

    if (status) {
        data = { ...data, status }
    }

    if(userId){
        data={...data,userId}
    }

    if (Object.keys(data).length === 0) {
        data = { limit: 10 }
    }

    let url = Object.keys(data).map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
    return dispatch => {

        loadAllPaymentService(url)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const savePayment=(payment)=>{

    return dispatch => {

        savePaymentService(payment)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const closePayment=(payment)=>{

    return dispatch => {

        closePaymentService(payment)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const reconsile=({stackHolder,selectedIds,loadCondition})=>{

    return dispatch => {

        reconsileService({stackHolder,selectedIds,loadCondition})
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}





export const resetPayment=()=>{

    return dispatch => {
      dispatch({type:RESETPAYMENT});
  }
    
  
}

export const changePayment=({name,value})=>{

    return dispatch => {
      dispatch({type:CHANGEPAYMENT,name,value});
  }
    
  
}


