import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Row,  Form, Card,Nav } from "react-bootstrap";
import CustomerEntry from "./CustomerEntry";

import NameListing from "./NameListing"
import { loadAllCustomer, updateStatus } from '../action/customerAction';
import {isCustomer} from "../constant/permission"

export default function Customer() {

    const [status,setStatus]=useState("active"); 
    const dispatch = useDispatch();

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const [show, setShow] = useState(false);
    const [filterStr, setFilterStr] = useState("");
    const [selectedIds, setSelectedIds] = useState({});

    const handleClose = () => {
        setShow(false);
        setSelectedCustomer(null);
        setSelectedIds({});
        dispatch(loadAllCustomer(status));
    }

    const handleShow = (id) => {
        setShow(true);
        setSelectedCustomer(id);
    }

    const toggleSelect = (id) => {

        let status = selectedIds[id];
        setSelectedIds({ ...selectedIds, [id]: status && status === "Y" ? "N" : "Y" })

    }
    const [selectedCustomer, setSelectedCustomer] = useState();

    const activeCustomer = () => {

        const ids = Object.keys(selectedIds).filter((k) => selectedIds[k] === "Y").map((k) => k);

        if (ids) {

            dispatch(updateStatus(ids));
        }

    }

    React.useEffect(() => {
        dispatch(loadAllCustomer(status));
    }, [dispatch,status]);


    if(isCustomer()){

        const customerId=localStorage.getItem("stakeHolderId");
        if(customerId){
            return (<CustomerEntry viewalone={true} selectedCustomer={Number(customerId)} handleClose={handleClose} />);
        }
        else{
            return ("");
        }
      
    }
    else{

        return (
            <div>
                {
                    !show && !selectedCustomer ?
                        (<Container fluid>
                            <Card border="dark" className={`mb-0 mt-1`}>
                                
                                
                                <Card.Header className="bg-dark">
                                    <Nav  variant="pills" defaultActiveKey={status} onSelect={(e)=>setStatus(e)}>
                                        <Nav.Item>
                                            <Nav.Link eventKey={"active"}>Active</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey={"dormant"}>Dormant</Nav.Link>
                                        </Nav.Item>
    
                                        <Nav.Item className="searchBar col-sm pt-2 pt-sm-0">
                                            
                                            <div className="float-md-end">
                                            <Form.Control type="text" placeholder={`Search ${customers ? customers.length : 0} records`} onChange={(e) => setFilterStr(e.target.value)} />
                                            </div></Nav.Item>
                                    </Nav>
                               </Card.Header>
    
                                <Card.Body className=" p-0" style={{ "height": "65vh", "overflowX": "auto" }}>
                                    <Container className="p-1" fluid>
    
                                        <Row className="g-2 justify-content-center">
                                            {customers.filter((customer) => !filterStr || customer.name.toLowerCase().includes(filterStr.toLowerCase())).map((customer, i) => {
                                                return (<NameListing key={i} status={status} element={customer} handleShow={handleShow} toggleSelect={toggleSelect} selectedIds={selectedIds}></NameListing>);
                                            })
                                            }
                                        </Row>
                                    </Container>
                                </Card.Body>
                                {status && status === 'active' ? ("") : (<Card.Footer>
                                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                                        <Button variant="primary" size="sm" onClick={activeCustomer}>Active</Button>
                                    </div>
                                </Card.Footer>)}
                            </Card>
                        </Container>)
                        : (<CustomerEntry selectedCustomer={selectedCustomer} handleClose={handleClose} />)
                }
            </div>
        );
    }

}
