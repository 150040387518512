import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Form, Modal, Card, Row, Col } from "react-bootstrap";
import UserEntry from "./UserEntry";

import { loadAllUser,  getUserPassword } from "../action/userAction";

export default function User() {


  const dispatch = useDispatch();

  const users = useSelector(
    (state) => state.user.users
  );


  const [show, setShow] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const handleClose = () => {
    setShow(false);
    setSelectedUser(null);

  }
  const handleShow = (seletedUser) => {

    if (seletedUser != null) {

      setShow(true);

    }

  }
  const [seletedUser, setSelectedUser] = useState();


  React.useEffect(() => {
    dispatch(loadAllUser());
  }, [dispatch]);



  return (


    <React.Fragment>
      <Container fluid>
        <Card className={`border border-dark mb-0 mt-1`}>
          <Card.Header className="bg-dark p-1">
            <h6 className="float-md-start p-1">
              Users
            </h6>
            <div className="float-md-end">
              <Form.Control type="text" placeholder={`Search ${users ? users.length : 0} records`} onChange={(e) => setFilterStr(e.target.value)} />
            </div>
          </Card.Header>

          <Card.Body className="p-1" style={{ "height": "65vh", "overflowX": "auto" }}>
            <Container fluid >
              <Row>
                {
                  users.filter((user)=>  !filterStr || user.username.toLowerCase().includes(filterStr.toLowerCase())).map((user, i) => {
                    return (<Col md={12} sm={6} key={i} className="mt-1">
                      <Card border="secondary" >
                        <Card.Body className="p-0">
                          <Container fluid >
                            <Row className="ps-1 pe-1 border-bottom">
                              <Col className="text-start p-0 border-end">
                                <p className="m-0 fw-bold">
                                  <i className="fa fa-user-circle-o text-secondary"></i>
                                  {` ${user.username}`}
                                </p>
                              </Col>

                              <Col className="text-end p-0">
                                <p className=" m-0">
                                  <i className="fa fa-phone text-secondary"></i> {user.phone} </p></Col>
                            </Row>
                            <Row className="ps-1 pe-1">
                              <Col className="col-1 p-0 border-end">

                                <i className="fa fa-pencil text-primary fa-2x" role="button" onClick={() => {
                                  setSelectedUser(user.id);
                                  handleShow(user.id);
                                }}></i>
                              </Col>
                              <Col className="text-start  border-end">
                                <p className="m-0 ">
                                  <i className="fa fa-chain text-secondary"></i>
                                  {` ${user.role}`}
                                </p>
                              </Col>
                              <Col className="text-end p-0">
                                <p className="m-0 text-secondary">
                                  {
                                    user.password ?
                                      (user.password)
                                      : ((<React.Fragment> <span style={{ fontSize: ".75rem" }}>
                                        <i className="fa fa-asterisk"></i>
                                        <i className="fa fa-asterisk"></i>
                                        <i className="fa fa-asterisk"></i>
                                        <i className="fa fa-asterisk"></i>
                                        <i className="fa fa-asterisk"></i>
                                        <i className="fa fa-asterisk"></i>
                                      </span>
                                        {` `}
                                        <i className="fa fa-eye text-primary fa-2x " role="button" onClick={() => { dispatch(getUserPassword(user.id)) }}></i></React.Fragment>))
                                  }

                                </p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>);
                  })
                }

              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body><UserEntry seletedUser={seletedUser} handleClose={handleClose} /></Modal.Body>

      </Modal>
    </React.Fragment>

  );
}
