import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Button, Card, Row, Col, Form, InputGroup, DropdownButton, Dropdown, Alert,Badge } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { loadAllPayments, resetPayment } from '../action/paymentAction';


import { warningNotification } from "../action/notificationAction";

import { formatDate, formatDateView } from "../constant/dateFormat";
import { isEmployee } from "../constant/permission"

import {PAGESIZE} from "../constant/constant"

export default function ViewPayments() {



    const dispatch = useDispatch();

    const [availableFilter,] = useState(["Recent", "Date Range",  "All"]);

    const [currentPage, setCurrentPage] = useState(1);

    const [filterType, setFilterType] = useState("0");
    
    const [selectedRecent, setSelectedRecent] = useState(0);
    const limit = PAGESIZE;

    const payments = useSelector(
        (state) => state.payment.payments
    );

    const paymentPage = useSelector(
        (state) => state.payment.page
    );

    const paymentCount = useSelector(
        (state) => state.payment.count
    );


    const [userFilter, setUserFilter] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    React.useEffect(() => {
        dispatch(resetPayment());


        if (isEmployee()) {
           

            setUserFilter({ "userId": (localStorage.getItem("userId") ? Number(localStorage.getItem("userId")) : null) });
        }
    }, [dispatch]);




    function loadBills(filter) {

        dispatch(loadAllPayments({ ...filter,...userFilter }));

    }

    const viewBills = (page) => {

        page = page ? page : 1;

        if (filterType === "0") {
            loadBills({ limit, page, ...getDateForRecentFilter() });
        }
        else if (filterType === "1") {
            if (startDate && endDate) {
                loadBills({ fromDate: formatDate(startDate), toDate: formatDate(endDate), page, limit });
            }
            else {
                dispatch(warningNotification("Select a valid date range to filter bills"));
            }
        }
       

        else {
            loadBills({ limit, page });
        }

    }

    const getDateForRecentFilter = () => {

        if (selectedRecent === 0) {
            return { fromDate: formatDate(new Date()), toDate: formatDate(new Date()) };
        }
        else if (selectedRecent === 1) {

            let yesterDay = new Date();

            yesterDay.setDate(yesterDay.getDate() - 1);

            return { fromDate: formatDate(yesterDay), toDate: formatDate(yesterDay) };
        }
        else if (selectedRecent === 2) {

            let lastWeek = new Date();

            lastWeek.setDate(lastWeek.getDate() - 7);

            return { fromDate: formatDate(lastWeek), toDate: formatDate(new Date()) };

        }
        else if (selectedRecent === 3) {

            let lastMonth = new Date();

            lastMonth.setDate(lastMonth.getDate() - 30);

            return { fromDate: formatDate(lastMonth), toDate: formatDate(new Date()) };
        }


    }

    const filter = () => {


        if (filterType === "0") {

            return (<Form.Select
                value={selectedRecent}
                onChange={(e) => {
                    setSelectedRecent(Number(e.target.value));
                }}>
                <option value={0}>Today</option>
                <option value={1}>Yesterday</option>
                <option value={2}>Last Week</option>
                <option value={3}>Last Month</option>

            </Form.Select>)

            //return <Form.Text style={{ "flex": "1 1 auto" }} className="border border-secondary mt-0 justify-content-center text-center p-1">Recent Bills</Form.Text>;
        }
        else if (filterType === "1") {

            return <DatePicker maxDate={new Date()}
                placeholderText="Select bill date range"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className="form-control form-control-sm h-100"
                isClearable={true}
            ></DatePicker>
        }
        else if (filterType === "2") {
            return <Form.Text style={{ "flex": "1 1 auto" }} className="border border-secondary mt-0 justify-content-center text-center p-1">All Bills</Form.Text>;
        }
       

    }

    const getBills = () => {

        let bills = payments;


        if (!bills || bills.length === 0) {
            return (<Alert variant="warning" className="mt-2">No Records</Alert>);
        }

        return (bills.map((pur, i) => {
            return (
                <Col md={12} sm={6} key={i} className="mt-1">
                    <Card border="secondary" >
                        <Card.Body className="p-1">
                        <Container fluid className="">
                            <Row className="ps-1 pe-1">
                                <Col className="text-start col p-0">
                                    <p className="m-0">
                                        <i className="fa fa-user "></i>
                                        <span > {pur.type==="PAID"? pur.vendor.name:pur.customer.name} </span>
                                    </p>
                                
                                    </Col>
                                <Col className="text-end col p-0 border-start"> 
                                <p className="m-0 text-secondary">
                                      <i className="fa fa-calendar"></i> 
                                       <span> {formatDateView(pur.date)} </span>
                                        </p>
                                      </Col>
                            </Row>
                            <Row className="ps-1 pe-1 border-top">
                                
                                <Col className="text-start col p-0 text-secondary border-end">
                                    <p className="m-0">
                                    <i className={`fa fa-${pur.type==="PAID"?"arrow-up text-danger":"arrow-down text-success"}`}></i>
                                    <strong> {pur.mode} </strong>
                                    </p>
                                </Col>
                                
                                                               
                                <Col className="text-end col p-0 border-start">
                                    <strong className={`text-end ${pur.type==="PAID"?"text-danger":"text-success"}`}> &#x20b9;{pur.amount} </strong>
                                </Col>
                            </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>)
        }));

    }

    const getPage = () => {

        return paymentPage ? paymentPage : 0;

    }

    const getNextPage = () => {

        if (getPage() && Number(getPage()) > currentPage) {
            setCurrentPage(currentPage + 1);
            viewBills(currentPage + 1);
        }
    }

    const getPrevPage = () => {
        if (getPage() && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            viewBills(currentPage - 1);
        }
    }

    return (
        <div>
            {
                (<Container fluid className="p-0">
                    <Card border="dark">

                        <Card.Header className="bg-dark" as="h6">
                                View Payments <Badge bg="info">{paymentCount?paymentCount:''}</Badge>
                            
                        </Card.Header>

                        <Card.Body className="p-0" style={{ "height": "65vh", "overflowX": "auto" }}>
                            <Container fluid>

                                <Row className="bills">

                                    <InputGroup className="mb-2 p-0">
                                        <DropdownButton
                                            id="filterType"
                                            variant="outline-secondary"
                                            title={availableFilter[filterType]}
                                            onSelect={(e) => {
                                                setFilterType(e);
                                               
                                                setSelectedRecent(0);
                                                setStartDate(null);
                                                setEndDate(null);
                                                setCurrentPage(1);
                                                dispatch(resetPayment());
                                                
                                            }}
                                        >
                                            {
                                                availableFilter.map((value, index) => {
                                                    return (<Dropdown.Item key={`drp-${index}`} eventKey={index}>{value}</Dropdown.Item>);
                                                })
                                            }

                                        </DropdownButton>

                                        {
                                            filter()
                                        }
                                        <InputGroup.Text className="p-0 bg-dark" title="Filter bill based on selection"><Button size="sm" variant="outline" onClick={() => {
                                            viewBills()
                                        }}><i className="fa fa-search text-light"></i></Button></InputGroup.Text>

                                    </InputGroup>

                                </Row>
                                <Row className="g-2 mb-2">

                                    {

                                        getBills()

                                    }

                                </Row>

                            </Container>
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col className="me-auto col-auto" >

                                    <Button variant="primary" size="sm" disabled={currentPage === 1} onClick={getPrevPage}>Prev</Button>
                                </Col>

                                <Col className="mx-auto col-auto">

                                    <button className="btn btn-sm">{getBills() ? (`${currentPage} of ${getPage()}`) : ""}</button>
                                </Col>
                                <Col className="ms-auto col-auto">

                                    <Button variant="primary" className="float-end" size="sm" disabled={!getPage() || Number(currentPage) === Number(getPage())} onClick={getNextPage}>Next</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Container>)

            }
        </div>
    );
}
