import {
   
   LOADALLPAYMENT,
   LOADPAYMENT,
   CHANGEPAYMENT,
   RESETPAYMENT,
   
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.payment }, action) {

    switch (action.type) {
        case LOADALLPAYMENT:
            return { ...state, payments: [...action.payment.rows],page:action.payment.totalPages,count:action.payment.count };

        case LOADPAYMENT:
            return { ...state, payment: { ...action.payment } };

        case CHANGEPAYMENT:
            return { ...state, payment: { ...state.payment, [action.name]: action.value } };

       
      
        case RESETPAYMENT:
            return { ...state, payment: {},payments:[],page:0,count:0 };
        default:
            return { ...state };
    }
}