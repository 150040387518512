import React from 'react';
import { Home } from './component/Home'
import Login from './component/Login'
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";
import { Router, Switch, Redirect } from 'react-router-dom';


import { history } from './app/history';
import Notification from "./component/Notification"
import CustomLoader from "./component/CustomLoader";

function App() {
  return (
    <React.Fragment>
     <div className="bg-dark-cust">
      <CustomLoader />
      <Notification />
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute path="/" component={Home} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      </div> 


    </React.Fragment>


  );
}

export default App;
