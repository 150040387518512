import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container,  Col, Row, Form, Button,  Card,  } from "react-bootstrap";

import { loadAllVendor } from '../action/vendorAction';
import { loadAllCustomer } from '../action/customerAction';
import { formatDate } from "../constant/dateFormat";

import {changePayment,savePayment} from "../action/paymentAction"

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {isOwner,isAccounts} from '../constant/permission'

export default function Payment() {


    const mode = new URL(window.location.href).searchParams.get('mode');

    const dispatch = useDispatch();

  

    const payment = useSelector(
        (state) => state.payment.payment
    );

    
    const [isReversal,setIsReversal]=useState(false);
    const [errors, setErrors] = useState({});

    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    const customers = useSelector(
        (state) => state.customer.customers
    );

    const handleSubmit = (e) => {


        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors);
        } else {

            let postData= {...payment};

            if(postData.type==="SELF"){
                postData ={...postData,stackholderId:Number(localStorage.getItem("stakeHolderId")),type:"PAID"}
            }

            dispatch(savePayment({ ...postData, date: formatDate(postData.date),amount:(isReversal?-1*Number(postData.amount):postData.amount) }));

            setIsReversal(false);

        }

    }

    const findFormErrors = () => {

        const { date, stackholderId ,type} = payment
        const newErrors = {}

        if (!date || date === '') newErrors.date = 'Date should not be blank!'
        
        if(type==="SELF"){

        } 
        else if(!stackholderId || stackholderId === '' || stackholderId === 0){
            newErrors.stackholderId = 'Select a vendor or customer !'
        } 

        return newErrors
    }

    const setField = (field, value) => {


        dispatch(changePayment({ name: field, value }));

        if (field === "type") {

            dispatch(changePayment({ name: "stackholderId", value:0 }));
            
        }

        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
       
    }

    const setDecimalField = (field, value) => {

        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;

        if (value === "" || re.test(value)) {

            dispatch(changePayment({ name: field, value }));
           
        }

    }

    const setOnBlur = (field, value) => {
        dispatch(changePayment({ name: field, value: Number(Number(value).toFixed(2))}));
       
    }


    React.useEffect(() => {
       dispatch(loadAllVendor('active'));
       dispatch(loadAllCustomer('active'));

        if (payment && !payment.type) {
            dispatch(changePayment({name:"type",value:"PAID"}))
            dispatch(changePayment({name:"mode",value:"BANK"}))
            dispatch(changePayment({name:"date",value:new Date()}))

        }

    }, [dispatch]);

     
    React.useEffect(() => {
        
        if (payment && !payment.date) {
            dispatch(changePayment({name:"date",value:new Date()}));
            dispatch(changePayment({name:"type",value:"PAID"}));
            dispatch(changePayment({name:"mode",value:"BANK"}))
        }

    }, [dispatch,payment]);



    const vendorLookup = () => {
        return (<React.Fragment>
            <option value={0}>Select a Vendor </option>
            {
                vendors ? vendors.map((vendor, i) => {
                    return <option value={vendor.id} key={i}>{vendor.name}</option>;
                }) : ("")
            }
        </React.Fragment>);
    }


    const customerLookup= () => {

        return (<React.Fragment><option value={0}>Select a Customer </option>
            {
                customers ? customers.map((customer, i) => {
                    return <option value={customer.id} key={i}>{customer.name}</option>;
                }) : ("")
            }
    
        </React.Fragment>);

    }

    return (
        <Container className="mt-3  col-11">

            <Card border="dark" >
                <Card.Header as="h6" className="bg-dark"> Payment 
                    {
                        isOwner()?(
                                    <Form.Check className="d-inline float-end" id="payReversal" label="Reversal Entry" checked={isReversal} onChange={(e)=>setIsReversal(e.target.checked) }></Form.Check>
                        ):("")
                    }
                </Card.Header>
                <Card.Body className="p-0"  >
                    <Container>
                        <Form >
                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker maxDate={new Date()} dateFormat="dd-MM-yyyy" className={`form-control form-control-sm ${!!errors.date ? "is-invalid" : ""}`} isInvalid={true} selected={payment && payment.date ? payment.date : null} onChange={(date) => setField("date", date)} />

                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Payment Type </Form.Label>
                                        <Form.Select size="sm" as="select" onChange={e => setField("type", e.target.value)} value={payment && payment.type ? payment.type : ""}>
                                            <option value="PAID">PAID</option>
                                            <option value="RECEIVED">RECEIVED</option>
                                            {
                                                (isAccounts() ? (<option value="SELF">SELF</option>) :(""))
                                            
                                            }
                                        </Form.Select>
                                    </Form.Group>


                                </Col>
                            </Row>
                            {
                                payment&&payment.type!=="SELF"?(
                                    <Row className="g-2 mb-2">
                                    <Col >
    
                                        <Form.Group >
                                            <Form.Label> {payment.type === "PAID"?"Vendor":"Costumer"}</Form.Label>
                                            <Form.Select size="sm" as="select" isInvalid={!!errors.stackholderId} onChange={e => setField("stackholderId", Number(e.target.value))} value={payment && payment.stackholderId ? payment.stackholderId : ""}>
    
                                                {
                                                    payment.type === "PAID" ? (
                                                        vendorLookup()
                                                    ) : (customerLookup())
                                                }    
    
                                            </Form.Select>
                                        </Form.Group>
    
    
                                    </Col>
                                </Row>
                                ):("")
                            }
                           
                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Mode </Form.Label>
                                        <Form.Select size="sm" as="select" onChange={e => setField("mode", (e.target.value))} value={payment && payment.mode ? payment.mode : ""}>
                                            <option value="BANK">BANK</option>
                                            <option value="CASH">CASH</option>

                                        </Form.Select>
                                    </Form.Group>


                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Payment</Form.Label>

                                        <Form.Control size="sm" placeholder="Amount" as="input"  
                                        onChange={e => setDecimalField("amount", e.target.value)}  
                                        onBlur={(e) => {
                                            setOnBlur("amount", e.target.value)
                                        }}
                                        value={payment && payment.amount ? payment.amount : ""}></Form.Control>


                                    </Form.Group>
                                </Col>
                            </Row>




                        </Form>
                    </Container>


                </Card.Body>
                <Card.Footer>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                        {
                         <Button variant="success"  size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={Number(payment && payment.amount ? payment.amount : 0) === 0}>Save</Button>
                        }
                    </div>

                </Card.Footer>
            </Card>


           
        </Container>
    );
}
