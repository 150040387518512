import {
    RESETSALE,
    CHANGEITEMSALE,
    CHANGESALE,
    ADDITEMSALE,
    RESETITEMSALE,
    LOADALLSALE,
    LOADSALE,
    CANCELEDITSALE,
    RESETSALEEDIT,
    initialState
} from "../constant/constant";

export default function reducer(state = { ...initialState.sale }, action) {

    switch (action.type) {

        case LOADALLSALE:
            return { ...state, sales: [...action.sales.rows],page:action.sales.totalPages,count:action.sales.count,sum:action.sales.sum };
        case LOADSALE:
            return { ...state, sale: { ...action.sale } };

        case CHANGESALE:
            return { ...state, sale: { ...state.sale, [action.name]: action.value } };

        case ADDITEMSALE:
            return {
                ...state, sale: {
                    ...state.sale, products: [...(state.sale.products ?
                        [...state.sale.products, { ...action.product }]
                        : ([{ ...action.product }]))]
                }
            };
        // case REMOVEVENDORPRODUCT:
        //     return {
        //         ...state, vendor: {
        //             ...state.vendor, products: [
        //                 ...(state.vendor.products.filter((product) =>
        //                     product.idx != action.idx

        //                 ))
        //             ]
        //         }
        //     };
        case CHANGEITEMSALE:
            return {
                ...state, sale: {
                    ...state.sale, products: [
                        ...(state.sale.products.map((product) => {
                            if (product.id === action.id)
                                return { ...product, [action.field]: action.value };
                            return product;
                        }))
                    ]
                }
            };
        case RESETSALEEDIT:
            return {...state,sale:{billDate:state.sale.billDate,customerId:state.sale.customerId}}
        case RESETITEMSALE:
            return { ...state, sale: { ...state.sale, products: [] } }
        case RESETSALE:
            return { ...state, sale: {} ,sales:[],page:0,sum:{},count:0};
        case CANCELEDITSALE:
            return {...state, sale:{billDate:state.sale.billDate,customerId:state.sale.customerId }}
        default:
            return { ...state };
    }
}