import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Col, Row, Form, Button, Nav, Card, } from "react-bootstrap";

import { loadAllVendor, loadVendor, resetVendor } from '../action/vendorAction';
import { addItemPurchase, changeItemPurchase, changePurchase, resetItemPurchase, savePurchase, loadAllPurchases, loadPurchases, cancelEditPurchase, resetPurchase, deletePurchase } from '../action/purchaseAction';
import { formatDate } from "../constant/dateFormat";
import ProductListing,{ProductListingOption} from "./ProductListing";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


import { isPermitted, isOwner } from "../constant/permission"

export default function Purchase(props) {


    const params = new URLSearchParams(window.location.search);
    const editBillId = params.get("id");
    const editVendorId = params.get("stackHolder");
    const editOnly = params.get("editOnly");


    const [mode, setMode] = useState("new");

    const dispatch = useDispatch();

    const [editMode, setEditMode] = useState(false);

    const purchases = useSelector(
        (state) => state.purchase.purchases
    );

    const purchase = useSelector(

        (state) => state.purchase.purchase
    );

    const [errors, setErrors] = useState({});


    const vendors = useSelector(
        (state) => state.vendor.vendors
    );

    const vendor = useSelector(
        (state) => state.vendor.vendor
    );


    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = findFormErrors()
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            calculateTotal();

            const postData = { ...purchase, products: purchase.products.filter(pro => pro.quantity && Number(pro.quantity) > 0).map(pro => { return { ...pro, price: Number(pro.price), quantity: Number(pro.quantity) } }) }

            if (postData.products.length > 0) {
                dispatch(savePurchase({ ...postData, billDate: formatDate(purchase.billDate), discount: (purchase.discount ? Number(purchase.discount) : purchase.discount) },editOnly));

            }
            else {

            }
        }

    }

    const findFormErrors = () => {

        const { billDate, billNo, vendorId } = purchase
        const newErrors = {}

        if (!billDate || billDate === '') newErrors.billDate = 'Bill Date should not be blank!'
        if (!billNo || billNo === '') newErrors.billNo = 'Bill number should not be blank!'
        if (!vendorId || vendorId === '' || vendorId === 0) newErrors.vendorId = 'Select a vendor !'
        return newErrors
    }

    const setField = (field, value) => {
        dispatch(changePurchase({ name: field, value }));
        if (mode === "new") {
            if (field === "vendorId") {
                dispatch(loadVendor(value));
                dispatch(resetItemPurchase());
                dispatch(changePurchase({ name: "billAmount", value: 0 }))

            }


            if (!!errors[field]) setErrors({
                ...errors,
                [field]: null
            })
        }
        else if (field === "billDate" || field === "vendorId") {

            if (field === "billDate" && purchase.vendorId) {

                dispatch(loadAllPurchases({ date: formatDate(value), vendorId: purchase.vendorId, status: "UNPAID" }));
            }

            else if (purchase.billDate && field === "vendorId") {

                dispatch(loadAllPurchases({ date: formatDate(purchase.billDate), vendorId: value, status: "UNPAID" }));
            }
        }



        else if (field === "id" && value !== 0) {
            setEditMode(true);
            dispatch(loadPurchases(value));
            dispatch(loadVendor(purchase.vendorId));

        }
    }

    const setDecimalField = (id, value) => {

        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;

        if (value === "" || re.test(value)) {

            dispatch(changeItemPurchase(id, value));

            // setField(field,value);
        }

    }

    const setDecimalFieldByName = (name, value) => {

        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;

        if (value === "" || re.test(value)) {
            setField(name, value);

        }

    }

    const setOnBlur = (id, value) => {
        dispatch(changeItemPurchase(id, Number(Number(value).toFixed(2))));
        calculateTotal();
    }


    const deleteBill = () => {

        if (purchase.id) {
            dispatch(deletePurchase(purchase.id));
        }
    }

    const calculateTax = () => {

        if (purchase.taxper && purchase.billAmount) {

            const billAmount = purchase.products.reduce((billAmount, product) => {
                return Number(billAmount) + Number(product.price * (product.quantity ? product.quantity : 0));
            }, 0);

            const tax = (Number(billAmount) * Number(purchase.taxper) / 100).toFixed(2);

            dispatch(changePurchase({ name: "tax", value: tax }));

            dispatch(changePurchase({ name: "billAmount", value: (Number(billAmount) + Number(tax)).toFixed(2) }));
        }

    }
    const calculateTotal = () => {

        if (purchase && purchase.products) {

            const billAmount = purchase.products.reduce((billAmount, product) => {
                return Number(billAmount) + Number(product.price * (product.quantity ? product.quantity : 0));
            }, 0);

            if (purchase.taxper) {
                calculateTax();
            }
            else {
                dispatch(changePurchase({ name: "billAmount", value: (Number(billAmount) + Number(purchase.tax ? purchase.tax : 0)).toFixed(2) }));
            }

        }
        else {
            dispatch(changePurchase({ name: "billAmount", value: 0 }));
        }

    }

    React.useEffect(() => {
        dispatch(loadAllVendor('active'));
        dispatch(resetVendor());
        dispatch(resetPurchase());
    }, [dispatch]);


    React.useEffect(() => {

        if (purchase && !purchase.billDate) {
            dispatch(changePurchase({ name: "billDate", value: new Date() }))
            setEditMode(false);
        }

    }, [dispatch, mode, purchase]);


    React.useEffect(() => {

        if (vendor && vendor.products && mode === "new") {
            dispatch(resetItemPurchase());
            vendor.products.forEach(product =>
                dispatch(addItemPurchase({ ...product }))
            )
        }
        else if (vendor && vendor.products && mode !== "new") {

            vendor.products.forEach(product => {

                const existing = purchase && purchase.products ? purchase.products.filter(pur => pur.id === product.id) : [];

                if (existing && existing.length > 0) {
                    //already exists    
                }
                else {
                    dispatch(addItemPurchase({ ...product }))
                }
            }
            )
        }
        else {
            dispatch(resetItemPurchase());
        }


    }, [dispatch, vendor]);

    React.useEffect(() => {

        if (editOnly) {

            props.hideMenu();
            setMode("edit");
            setEditMode(true);
            dispatch(loadPurchases(editBillId));
            dispatch(loadVendor(editVendorId))
        }

    }, [dispatch, editOnly]);



    return (
        <Container className="mt-3  col-11">

            <Card border="dark" >
                <Card.Header className="bg-dark">
                    {editOnly ? ("Edit") : (
                        <Nav variant="tabs" defaultActiveKey={mode}
                            onSelect={(e) => {
                                setMode(e);
                                dispatch(resetPurchase());
                                setEditMode(false);

                            }}>
                            <Nav.Item>
                                <Nav.Link eventKey={"new"}>New  </Nav.Link>
                            </Nav.Item>
                            {
                                isPermitted("EDIT_SALE") ? (<Nav.Item>
                                    <Nav.Link eventKey={"edit"}>Edit </Nav.Link>
                                </Nav.Item>) : ("")
                            }

                            {
                                isPermitted("ADD_DISCOUNT") ? (<Nav.Item>
                                    <Nav.Link eventKey={"discount"}>Discount </Nav.Link>
                                </Nav.Item>) : ("")
                            }

                            <Nav.Item className="col ms-auto">

                                <h6 className="nav-link float-end mb-0">
                                    Purchase
                                </h6></Nav.Item>
                        </Nav>)} </Card.Header>
                <Card.Body className="p-1"  >
                    <Container className="p-0" fluid>
                        <Form >
                            <Row className="g-2 mb-2 mt-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Date</Form.Label>
                                        <DatePicker maxDate={new Date()} disabled={editMode} dateFormat="dd-MM-yyyy" className={`form-control form-control-sm ${!!errors.billDate ? "is-invalid" : ""}`} isInvalid={true} selected={purchase && purchase.billDate ? purchase.billDate : null} onChange={(date) => setField("billDate", date)} />

                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >

                                    <Form.Group >
                                        <Form.Label>Vendor</Form.Label>
                                        <Form.Select disabled={editMode} size="sm" as="select" isInvalid={!!errors.user} onChange={e => setField("vendorId", Number(e.target.value))} value={purchase && purchase.vendorId ? purchase.vendorId : ""}>
                                            <option value={0}>Select a Vendor </option>

                                            {
                                                vendors ? vendors.map((vendor, i) => {
                                                    return <option value={vendor.id} key={i}>{vendor.name}</option>;
                                                }) : ("")
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="g-2 mb-2">
                                <Col >
                                    <Form.Group >
                                        <Form.Label>Bill No</Form.Label>
                                        <Form.Control size="sm" placeholder="Bill Number" as="input" isInvalid={!!errors.billNo} onChange={e => setField("billNo", e.target.value)} value={purchase && purchase.billNo ? purchase.billNo : ""}></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {
                                ((mode && mode === "new") || editOnly) ?
                                    ("")
                                    : (
                                        <Row className="g-2 mb-2">
                                            <Col >
                                                <Form.Group >
                                                    <Form.Label>Bills</Form.Label>
                                                    <Form.Select size="sm" as="select" placeholder="Select a bill" onChange={e => { if (Number(e.target.value) > 0) setField("id", Number(e.target.value)) }} value={purchase && purchase.id ? purchase.id : "0"}>
                                                        <option value={0}>  {purchases ? purchases.length : 0} bills avaliable for the bill date </option>
                                                        {
                                                            purchases ? purchases.map((pur, i) => {
                                                                return <option value={pur.id} key={i}>{pur.billNo}</option>;
                                                            }) : ("")
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )
                            }
                            {
                                mode === "discount" ? (<Row className="g-2 mb-2">
                                    <Col >
                                        <Form.Group >
                                            <Form.Label>Discount</Form.Label>
                                            <Form.Control size="sm" placeholder="Discount" as="input" onChange={e => setDecimalFieldByName("discount", e.target.value)} value={purchase && purchase.discount ? purchase.discount : ""}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>) : ("")
                            }

                        </Form>
                        <hr></hr>

                        <Row className="mt-1 mb-1 g-2">
                            {
                                purchase.products ? <ProductListingOption products={purchase.products} isRetail={vendor.isRetail} setDecimalField={setDecimalField} setOnBlur={setOnBlur}></ProductListingOption>
                                     : ("")
                            }
                        </Row>
                        <hr ></hr>


                        <Row className="">
                            <Col className="text-end">Tax:</Col>
                            <Col className="text-end"> <input disabled={purchase && purchase.billAmount && Number(purchase.billAmount) > 0 ? false : true} value={purchase && purchase.taxper ? purchase.taxper : ''} onChange={(e) => setDecimalFieldByName("taxper", Number(e.target.value))}
                                onBlur={calculateTax}
                                placeholder="Tax" className="rounded-0 border w-100" /></Col>
                            <Col className="text-end">

                                &#x20b9;{purchase && purchase.tax ? purchase.tax : ''}

                            </Col>
                        </Row>

                        <Row className="">
                            <Col></Col>
                            <Col className="text-end">Total :</Col>
                            <Col className=" fw-bold text-end">

                                &#x20b9;{Number(purchase && purchase.billAmount ? purchase.billAmount : 0).toFixed(2)}

                            </Col>
                        </Row>
                        <hr></hr>
                    </Container>

                </Card.Body>
                <Card.Footer>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end">
                        {
                            mode === "edit" || mode === "discount" ? (
                                <React.Fragment>
                                    <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={!isOwner() && Number(purchase && purchase.billAmount ? purchase.billAmount : 0) === 0 }>Update</Button>
                                    {isOwner() && !editOnly? (<Button variant="danger" size="sm" onClick={deleteBill}>Delete</Button>) : ("")}
                                </React.Fragment>

                            ) : (
                                <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")} disabled={ !isOwner() && Number(purchase && purchase.billAmount ? purchase.billAmount : 0) === 0 }>Save</Button>
                            )
                        }
                        {(mode === "edit" || mode === "discount") && editMode ?
                            (<Button variant="danger" size="sm" onClick={() => {
                                setEditMode(false);

                                if (editOnly) {
                                    window.close();
                                }
                                else { dispatch(cancelEditPurchase()) }
                            }}>Cancel</Button>)
                            : ("")
                        }
                    </div>
                </Card.Footer>
            </Card>


        </Container>
    );
}
