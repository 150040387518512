import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, FloatingLabel, Col, Row, Form, Button } from "react-bootstrap";
import { loadUser, changeUser, saveUser, resetUser, updateUser } from "../action/userAction";
import {numberCheck} from "../constant/numberCheck"
export default function UserEntry(userProps) {

    const dispatch = useDispatch();

    const [props, setProps] = React.useState(userProps);

    const [errors, setErrors] = useState({});


    const user = useSelector(
        (state) => state.user.user
    );

    React.useEffect(() => {
        if (props && props.seletedUser) {
            dispatch(loadUser(props.seletedUser));
        }
    }, [dispatch]);

    React.useEffect(() => {

        dispatch(resetUser());

    }, [dispatch]);




    const setField = (field, value) => {
        dispatch(changeUser({ name: field, value: (field === "roles" ? [value] : value) }));
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            dispatch(saveUser({ ...user, email: "" }));

            if (props && props.handleClose) {
                props.handleClose();
            }
        }

    }

    const handleSubmitPatch = e => {
        e.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            dispatch(updateUser({ ...user, email: "" }));

            if (props && props.handleClose) {
                props.handleClose();
            }
        }

    }

    const findFormErrors = () => {
        const { username, role, phone, password } = user
        const newErrors = {}

        if (!username || username === '') newErrors.username = 'userName cannot be blank!'


        if (!role || role === "0") newErrors.role = 'select a role!'

        if (!phone || phone === '') newErrors.phone = 'mobilenumber cannot be blank!'

        if (props && props.seletedUser) {

        } else if (!password || password === '') newErrors.password = 'password cannot be blank!'


        return newErrors
    }

    
    return (<Container className="mt-3  col-11" >

        <Form size="md">
            <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="User Name">
                        <Form.Control size="sm" type="text" placeholder="User Name" isInvalid={!!errors.username} onChange={e => setField('username', e.target.value)} value={user && user.username ? user.username : ""} />
                        <Form.Control.Feedback type='invalid'>{errors.username} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            {
                props && props.seletedUser ? ("") : (
                    <Row className="g-2 mb-2">
                        <Col >
                            <FloatingLabel label="Role">
                                <Form.Select size="sm" aria-label="User Role" isInvalid={!!errors.role} onChange={e => setField('role', e.target.value)} value={user && user.role ? user.role : "0"}>
                                    <option value="0">Select user role</option>
                                    {/* <option value="OWNER">OWNER</option> */}
                                    <option value="ACCOUNTS">ACCOUNTS</option>
                                    <option value="PARTNER">PARTNER</option>
                                    <option value="EMPLOYEE">EMPLOYEE</option>
                                    {
                                        props && props.seletedUser ? (
                                            <React.Fragment>
                                                <option value="VENDOR">VENDOR</option>
                                                <option value="CUSTOMER">CUSTOMER</option>
                                            </React.Fragment>
                                        ) : ("")
                                    }
                                    {/*  */}
                                </Form.Select>
                                <Form.Control.Feedback type='invalid'>{errors.role}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                )
            }

            <Row className="g-2 mb-2">
                <Col >
                    <FloatingLabel label="Mobile Number">
                        <Form.Control size="sm" type="text" placeholder="Mobile Number" isInvalid={!!errors.phone} onKeyPress={numberCheck} onChange={e => setField('phone', e.target.value)} value={user && user.phone ? user.phone : ""} />
                        <Form.Control.Feedback type='invalid' >{errors.phone}</Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            {
                props && props.seletedUser ? ("") : (<Row className="g-2 mb-2">
                    <Col >
                        <FloatingLabel label="Password">
                            <Form.Control size="sm" type="password" placeholder="Password" isInvalid={!!errors.password} onChange={e => setField('password', e.target.value)} value={user && user.password ? user.password : ""} />
                            <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>

                        </FloatingLabel>
                    </Col>
                </Row>)
            }

            <div className="d-grid d-md-flex gap-2 justify-content-md-end">
                {props && props.seletedUser ?
                    (<React.Fragment>
                        <Button variant="danger" size="sm" onClick={handleSubmitPatch}>Update</Button>
                    </React.Fragment>) :
                    (<React.Fragment>
                        <Button variant="danger" size="sm" onClick={() => {
                            dispatch(resetUser());
                        }}>Reset
                        </Button>
                        <Button variant="success" size="sm" onClick={handleSubmit}>Save</Button>
                    </React.Fragment>)}
            </div>
        </Form>
    </Container>);
}