import React, { useState } from "react";

import { Container, FloatingLabel, Col, Row, Form, Button } from "react-bootstrap";


export default function ProductLookup(userProps) {

    const [props, setProps] = React.useState(userProps.product ? userProps.product : {});
    const [product, setProduct] = React.useState([]);
    const [subProduct, setSubProduct] = React.useState([]);
    const [errors, setErrors] = useState({});
    React.useEffect(() => {

        setProduct([...userProps.productLookup]);

        if (props && props.type) {

            let filtered = [...userProps.productLookup].filter(p => p.id === props.type);
            
            setSubProduct((filtered && filtered.length > 0 ? filtered[0].subtypes : []));
        }


    }, [userProps]);





    const setMapField = (field, value,e) => {

        // if (field === 'uom') {
        //     var index = e.nativeEvent.target.selectedIndex;
        //     setProps({ ...props, uom: { id: value,text:e.nativeEvent.target[index].text } });
        // }
        if(field==='id'){
           

            let selectedSub= subProduct?subProduct.filter(sub=>Number(sub.id)===Number(value)):[];

            if(selectedSub && selectedSub.length>0){

                setProps({ ...props, [field]: selectedSub[0].id,text:selectedSub[0].text,uom:{...selectedSub[0].uom} });    
            }

        }
        else {

            setProps({ ...props, [field]: value });
        }


        if (field === 'type') {
            if (value && value!=="0") {
                let filtered = product.filter(p => p.id === Number(value));
                let subTypes = (filtered && filtered.length > 0 ? (filtered[0].subtypes?filtered[0].subtypes:[]) : []);
                setSubProduct(subTypes);
            }
            else {
                setSubProduct([]);
            }
            setProps({ ...props, id: null,type:value});
        }
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const handleSubmit = e => {
        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {

            setErrors(newErrors);
        } else {


            if (userProps && userProps.handleClose) {
                userProps.handleClose({...props});
            }
        }
    }


    const findFormErrors = () => {
        const { type, id, price, uom } = props
        const newErrors = {}

        if (!type || type === '' || type === 0) newErrors.type = 'Select a valid product type!'
        if (!id || id === '' || id === 0) newErrors.id = 'Select a valid sub type!'
        if (!price || price === '' || price <= 0) newErrors.price = 'Price should be greater than 0!'
        if (!uom || !uom.id || uom.id === 0) newErrors.uom = 'Select a valid uom!'



        return newErrors
    }

    
    const decimalCheck=(e)=> {
        const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }

     
    const formatDecimalValue=(e)=> {
    
        setProps({ ...props, price: Number(Number(e.target.value).toFixed(2)) });

      } 

      
    return (
        <Container>
            <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="Product Type">
                        <Form.Select size="sm" aria-label="Product Type" isInvalid={!!errors.type} onChange={e => setMapField('type', Number(e.target.value))} value={props && props.type ? props.type : "0"}>
                            <option value={0}>Select product</option>

                            {
                                product.map((p,i) => {
                                    return <option value={p.id} key={i}>{p.text}</option>
                                })
                            }

                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>{errors.type} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="Sub Type">
                        <Form.Select size="sm" aria-label="Sub Type" isInvalid={!!errors.type} onChange={e => setMapField('id', Number(e.target.value),e)} value={props && props.id ? props.id : "0"}>
                            <option value={0}>Select sub type</option>

                            {
                                subProduct.map((s,i) => {
                                    
                                    return <option value={s.id} key={i}>{s.text}</option>
                                })
                            }

                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>{errors.id} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className="g-2 mb-2">
                <Col >

                    <FloatingLabel label="Unit Of Measurement">
                   
                        <Form.Control size="sm" type="text" readOnly={true} value={props && props.uom ? props.uom.code : ""}></Form.Control>
                        <Form.Control.Feedback type='invalid'>{errors.uom} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row className="g-2 mb-2">
                <Col >
                    <FloatingLabel label="Price">
                        <Form.Control size="sm" type="text" placeholder="Price" isInvalid={!!errors.price} onKeyPress={decimalCheck} onBlur={formatDecimalValue} onChange={e => setMapField('price', (e.target.value))} value={props && props.price ? props.price : ''} />
                        <Form.Control.Feedback type='invalid'>{errors.price} </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>

            <div className=" d-flex justify-content-end gap-2">
                <Button variant="danger" size="sm" onClick={()=>{
                    if(userProps && userProps.handleClose){
                        userProps.handleClose();
                    }
                }}>Cancel</Button>
                {props && props.idx ?
                    (<Button variant="primary" size="sm" onClick={handleSubmit}>Update</Button>
                    ) :
                    (
                        <Button variant="success" size="sm" onClick={handleSubmit}>Save</Button>)}
            </div>

        </Container>


    );



}