import {CHANGEEXPENSE, LOADALLEXPENSE,LOADEXPENSE,RESETEXPENSE,CANCELEDITEXPENSE,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";

export function loadAllExpenseService(url){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/expenses?${url}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADALLEXPENSE, expense: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load expense data",variant:"danger",err}  })
            });
    });

}

function loadExpenseByIdService(id){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "GET",
            "url": `/api/expenses/${id}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {

           if(response.data){
                       
               resolve({ type: LOADEXPENSE, expense: {...response.data,billDate:(response.data.billDate ? new Date(response.data.billDate) : response.data.billDate)} });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load expense data",variant:"danger",err}  })
            });
    });

}




function saveExpenseService(payment){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/expenses`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(payment)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Expense entry saved successfully", variant: "success", err: "" }
                });

            resolve({ type: RESETEXPENSE});

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving payment",variant:"danger",err}  })
            });
    });
  
  }

 export const loadAllExpenses=({ date = null,reconsiled=null, userId = null, limit = null, status = null, fromDate = null, toDate = null, page = null }) => {

        let data = {};
    
        if (date) {
            data = { ...data, fromDate: date, toDate: date }
        }
        else if (fromDate && toDate) {
            data = { ...data, fromDate, toDate }
        }
    
    
        if (userId) {
            data = { ...data, userId }
        }
    
        if (limit) {
            data = { ...data, limit }
        }
    
        if (page) {
            data = { ...data, page }
        }
    
        if (status) {
            data = { ...data, status }
        }
    
        if(reconsiled){
            data={...data,reconsiled}
        }

        if (Object.keys(data).length === 0) {
            data = { limit: 10 }
        }
    
        let url = Object.keys(data).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')
    

    return dispatch => {

        loadAllExpenseService(url)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loadExpenseById=(id)=>{

    return dispatch => {

        loadExpenseByIdService(id)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}


export const saveExpense=(payment)=>{

    return dispatch => {

        saveExpenseService(payment)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



export const changeExpense=({name,value})=>{

    return dispatch => {
      dispatch({type:CHANGEEXPENSE,name,value});
  }
    
  
}


export const resetExpense=()=>{

    return dispatch => {
      dispatch({type:RESETEXPENSE});
  }
    
  
}


export const cancelEdit=()=>{

    return dispatch => {
      dispatch({type:CANCELEDITEXPENSE});
  }
    
  
}






