import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, FloatingLabel, Col, Row, Form, Button, Card, Modal } from "react-bootstrap";


import { loadProduct, changeProduct, saveProduct, resetProduct, addProductType, changeProducType, removeType } from "../action/productAction";

export default function ProductEntry(userProps) {

    const [props, setProps] = React.useState(userProps);
    const [errors, setErrors] = useState({});
    const [subErrors, setSubErrors] = useState({});
    const [subtype, setSubtype] = useState({});
    const product = useSelector(
        (state) => state.product.product
    );

    const lookup = JSON.parse(localStorage.getItem("lookup"));
    const [show, setShow] = useState(false);

    const handleClose = (subtype) => {

        if (subtype && subtype.idx) {
            dispatch(changeProducType(subtype));
        }
        else if (subtype) {
            dispatch(addProductType(subtype));
        }
        setShow(false);
        setSubtype({});
        setSubErrors({});
    }

    const removeProduct = (idx) => {
        dispatch(removeType(idx));
    }

    const handleShow = (subtype) => {
        if (subtype != null) {
            setSubtype({ ...subtype });
        }
        setShow(true);
    }

    const dispatch = useDispatch();


    React.useEffect(() => {
        if (props && props.selectedProduct) {
            dispatch(loadProduct(props.selectedProduct));
        }
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(resetProduct());
    }, [dispatch]);



    const setField = (field, value) => {
        dispatch(changeProduct({ name: field, value }))
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) setErrors({
            ...errors,
            [field]: null
        })
    }

    const setSubField = (field, value, e) => {

        if (field === 'uom') {
            var index = e.nativeEvent.target.selectedIndex;
            setSubtype({ ...subtype, uom: { id: value, code: e.nativeEvent.target[index].text } });
        }
        else {
            setSubtype({ ...subtype, [field]: value });
        }

        // Check and see if errors exist, and remove them from the error object:
        if (!!subErrors[field]) setSubErrors({
            ...subErrors,
            [field]: null
        })
    }

    const handleSubmit = (e, method) => {
        e.preventDefault();

        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            dispatch(saveProduct({ ...product, status:'active' }, method));

            if (props && props.handleClose) {
                props.handleClose();
            }
        }
    }

    const handleSubtypeSubmit = (e) => {
        e.preventDefault();

        const newErrors = findSubFormErrors();

        if (Object.keys(newErrors).length > 0) {

            setSubErrors(newErrors);
        } else {

            handleClose(subtype);//dispatch(saveProduct({ ...product, status: 'active' }, method));

        }
    }



    const findFormErrors = () => {
        const { text, code } = product
        const newErrors = {}

        if (!text || text === '') newErrors.text = 'Name should not be blank!'
        if (!code || code === '') newErrors.code = 'Code should not be blank!'

        return newErrors
    }


    const findSubFormErrors = () => {
        const { text, code, uom } = subtype
        const newErrors = {}

        if (!text || text === '') newErrors.text = 'Name should not be blank!'
        if (!code || code === '') newErrors.code = 'Code should not be blank!'

        if (!uom || !uom.id || uom.id === 0) newErrors.uom = 'Select a valid uom!'



        return newErrors
    }

    const subTypeRemovePrefix=(value)=>{

        const {code}=product;

        let sub_code= value.split("-");

        if(sub_code && value.indexOf("-")>0){
            return code?`${code}-${sub_code[1]}`:sub_code[1];
        }
        

        return code?`${code}-`:value; 

    }

    const alphaNum=(e)=> {
        const re = /[0-9a-zA-z]+/g;
        if (!re.test(e.key)) {
          e.preventDefault();
        }
      }

    return (
        <Container className="mt-1 col-11">

            <Card border="dark" >
                <Card.Header as="h6" className="bg-dark">Product</Card.Header>
                <Card.Body className="p-0" style={{ "height": "70vh", "overflowX": "auto" }} >
                    <Container className="mt-2">
                        <Form >
                            <Row className="g-2 mb-2 mt-2">
                                <Col >

                                    <FloatingLabel label="Product Name">
                                        <Form.Control size="sm" type="text" placeholder="Product Name" isInvalid={!!errors.text} onChange={e => setField('text', e.target.value)} value={product && product.text ? product.text : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.text} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className="g-2 mb-2">
                                <Col >
                                    <FloatingLabel label="Product Code">
                                        <Form.Control size="sm" type="text" placeholder="Product Code" isInvalid={!!errors.code} onKeyPress={alphaNum} onChange={e => setField('code', e.target.value)} value={product && product.code ? product.code : ""} />
                                        <Form.Control.Feedback type='invalid'>{errors.code} </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            

                        </Form>
                    </Container>
                    <Container className="mb-2">
                        <Card>
                            <Card.Header as="h6" className="bg-dark">Sub Types <Button variant="success" size="sm" className="float-end" onClick={() => handleShow()}> Add</Button></Card.Header>
                            <Card.Body>
                                <Row className="g-2 mb-2">

                                    {
                                        product && product.subtypes ? (

                                            product.subtypes.filter((sty)=>sty).map((sty, i) => {
                                                return (
                                                    <Col md={12} sm={4} key={i}>
                                                        <Card border="secondary" >
                                                            <Card.Body className="p-1">
                                                                <Card.Title as="h6">
                                                                     {
                                                                        sty && sty.id?(<i className={`fa ${sty.status==="active"?"fa-check-square-o":"fa-square-o"} pe-1 fa-2x`} role="button" onClick={()=>{ dispatch(changeProducType({...sty,idx:i+1,status:sty.status&&sty.status==="active"?"dormant":"active"})) }}></i>):
                                                                        ("")

                                                                    }
                                                                    
                                                                    <Button className="p-0" variant="link" size="sm" style={{ fontSize: "inherit" }} onClick={() => handleShow({ ...sty, idx: i + 1 })}>{sty.text}</Button> 
                                                                   
                                                                    {
                                                                        sty && sty.id?(""):
                                                                        (<i className="fa fa-trash text-danger float-end" style={{ cursor: "pointer" }} onClick={() => { removeProduct(i + 1) }}></i>)

                                                                    }
                                                                   
                                                                    </Card.Title>
                                                                <Card.Text>
                                                                    Code : {sty.code} |  {sty.uom?sty.uom.code:""}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>)
                                            })
                                        ) : ("")
                                    }
                                </Row>
                            </Card.Body>
                            {/* <Card.Footer></Card.Footer> */}
                        </Card>
                    </Container>

                </Card.Body>
                <Card.Footer>
                    <div className="d-grid d-md-flex gap-2 justify-content-md-end mb-2">
                        {
                            props && props.selectedProduct ? (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                <Button variant="primary" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Update</Button>
                                <Button variant="danger" size="sm" onClick={() => { props.handleClose() }}>Back</Button>
                            </div>) : (<div className="d-grid d-md-flex gap-2 justify-content-md-end">
                                <Button variant="success" size="sm" onClick={(e) => handleSubmit(e, "POST")}>Save</Button>
                                <Button variant="danger" size="sm" onClick={() => dispatch(resetProduct())}>Reset</Button>
                            </div>)
                        }
                    </div>

                </Card.Footer>
            </Card>


            <Modal show={show} onHide={()=>handleClose()} centered size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Product Mapping</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form size="md">

                        <Row className="g-2 mb-2">
                            <Col >

                                <FloatingLabel label="Subtype Name">
                                    <Form.Control size="sm" type="text" placeholder="Subtype Name" isInvalid={!!subErrors.text} onChange={e => setSubField('text', e.target.value)} value={subtype && subtype.text ? subtype.text : ""} />
                                    <Form.Control.Feedback type='invalid'>{subErrors.text} </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="g-2 mb-2">
                            <Col >
                                <FloatingLabel label="Subtype Code">
                                    <Form.Control size="sm" type="text" placeholder="Subtype Code" isInvalid={!!subErrors.code} onKeyPress={alphaNum} onChange={e => setSubField('code', e.target.value)} value={subtype && subtype.code ? subTypeRemovePrefix(subtype.code) : ""} />
                                    <Form.Control.Feedback type='invalid'>{subErrors.code} </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="g-2 mb-2">

                            <Col >

                                <FloatingLabel label="Unit Of Measurement">
                                    <Form.Select size="sm" aria-label="uom" isInvalid={!!subErrors.uom} onChange={e => setSubField('uom', Number(e.target.value), e)} value={subtype && subtype.uom ? subtype.uom.id : "0"}>
                                        <option value={0}>Select uom</option>

                                        {
                                            lookup.uom ? lookup.uom.map((s, i) => {
                                                return <option value={s.id} key={i}>{s.code}</option>
                                            }) : ""
                                        }

                                    </Form.Select>
                                    <Form.Control.Feedback type='invalid'>{subErrors.uom} </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                        </Row>
                        <div className=" d-flex justify-content-end gap-2">
                            <Button variant="danger" size="sm" onClick={()=>
                                handleClose()
                            }>Cancel</Button>
                            {props && props.idx ?
                                (<Button variant="primary" size="sm" onClick={handleSubtypeSubmit}>Update</Button>
                                ) :
                                (
                                    <Button variant="success" size="sm" onClick={handleSubtypeSubmit}>Save</Button>)}
                        </div>

                    </Form>

                </Modal.Body>

            </Modal>

        </Container>);
}