import {LOADALLCUSTOMER,LOADCUSTOMER,CHANGECUSTOMERPRODUCT,
    ADDCUSTOMERPRODUCT,REMOVECUSTOMERPRODUCT,CHANGECUSTOMER,
    RESETCUSTOMER,SET_NOTIFICATION} from "../constant/constant";
import {pAxios} from "..";
import store from "../app/store";

function loadAllCustomerService(status){

return new Promise(function (resolve, reject) {

    pAxios({
        "method": "GET",
        "url": `/api/customers?status=${status&&status==='active'?'active':'dormant'}`,
        "headers": {
            'content-type': 'application/json'
        }
      
    }).then(response => {

       if(response.data){
                   
           resolve({ type: LOADALLCUSTOMER, customer: (response.data) });
       }
       else{
        resolve({ type: LOADALLCUSTOMER, customer: []});
       }
    })
        .catch(err => {
            
            reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load customer data",variant:"danger",err}  })
        });
});

}

function loadCustomerService(customerId){

return new Promise(function (resolve, reject) {

    pAxios({
        "method": "GET",
        "url": `/api/customers/${customerId}`,
        "headers": {
            'content-type': 'application/json'
        }
      
    }).then(response => {

       if(response.data){
                   
           resolve({ type: LOADCUSTOMER, customer: (response.data) });
       }
       else{
        resolve({ type: LOADCUSTOMER, customer: {}});
       }
    })
        .catch(err => {
            
            reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load customer data",variant:"danger",err}  })
        });
});


}

function saveCustomerService(customer,method){

return new Promise(function (resolve, reject) {

    pAxios({
        "method": "POST",
        "url": `/api/customers`,
        "headers": {
            'content-type': 'application/json'
        },
        "data":JSON.stringify(customer)
      
    }).then(response => {

       if(response.data){
           
        store.dispatch({
            type: SET_NOTIFICATION, notification:
                { message: "Customer saved successfully", variant: "success", err: "" }
            });

        resolve({ type: RESETCUSTOMER});

       }
    })
        .catch(err => {
            
            reject({ type: SET_NOTIFICATION,notification:{message:"Error while saving customer",variant:"danger",err}  })
        });
});

}


function activeCustomerService(customerIds){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "POST",
            "url": `/api/customers/status/active`,
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify(customerIds)
          
        }).then(response => {
  
           if(response.data){
               
            store.dispatch({
                type: SET_NOTIFICATION, notification:
                    { message: "Customer updated", variant: "success", err: "" }
                });

            resolve(loadAllCustomer("dormant"));

           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Error while updating customer",variant:"danger",err}  })
            });
    });
  
  }


export const saveCustomer=(customer,method)=>{

return dispatch => {

    saveCustomerService(customer,method)
        .then(response => dispatch(response))
        .catch(err => dispatch(err));

}

}



export const loadAllCustomer=(status)=>{

return dispatch => {

    loadAllCustomerService(status)
        .then(response => dispatch(response))
        .catch(err => dispatch(err));

}

}

export const loadCustomer=(customerId)=>{

return dispatch => {

loadCustomerService(customerId)
      .then(response => dispatch(response))
      .catch(err => dispatch(err));

}

}

export const changeCustomer=({name,value})=>{

return dispatch => {
  dispatch({type:CHANGECUSTOMER,name,value});
}


}

export const changeCustomerProduct=(product)=>{

return dispatch => {
  dispatch({type:CHANGECUSTOMERPRODUCT,product});
}


}

export const addCustomerProduct=(product)=>{

return dispatch => {
  dispatch({type:ADDCUSTOMERPRODUCT,product});
}

}

export const removeCustomerProduct=(idx)=>{

return dispatch => {
  dispatch({type:REMOVECUSTOMERPRODUCT,idx});
}

}




export const resetCustomer=()=>{

return dispatch=>{

   dispatch({type:RESETCUSTOMER});
}
}

export const updateStatus=(customerIds)=>{

    return dispatch => {

        activeCustomerService(customerIds)
              .then(response => dispatch(response))
              .catch(err => dispatch(err));
    
      }
    
    
}