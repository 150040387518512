import {LOGIN_USER,LOGOUT_USER,SET_NOTIFICATION,REFRESHTOKEN} from "../constant/constant";
import {pAxios} from "..";
import { history } from '../app/history';
import {loadAllLookupsService} from './lookupAction';
import {removeLocal} from "../constant/resetLocal"

function loginService(userId,passWord){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/signin",
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({
                "phone": userId,
                "password":passWord
            })
        }).then(response => {

           if(response.data && response.data.accessToken){

               localStorage.setItem("userId",response.data.id);
               localStorage.setItem("token",response.data.accessToken);
               localStorage.setItem("refreshToken",response.data.refreshToken);
               localStorage.setItem("userName",response.data.username);
               localStorage.setItem("role",response.data.role);
               localStorage.setItem("stakeHolderId",response.data.stakeHolderId);
               loadAllLookupsService();

              
                history.push("/");
            
               resolve(loadUserService(response.data.id));
           }


        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Authenticaltion failed",variant:"danger",err}  })
            });
    });

}

function loadUserService(userid){

    return new Promise(function (resolve, reject) {
  
        pAxios({
            "method": "GET",
            "url": `/api/users/${userid}`,
            "headers": {
                'content-type': 'application/json'
            }
          
        }).then(response => {
  
           if(response.data){
                       
               resolve({ type: LOGIN_USER, user: (response.data) });
           }
        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Failed to load user data",variant:"danger",err}  })
            });
    });
  
  }
  

function refreshTokenService(userId,passWord){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/refreshToken",
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({
                "username": localStorage.getItem("userName"),
                "refreshToken":localStorage.getItem("refreshToken")
            })
        }).then(response => {

           if(response.data && response.data.accessToken){

               localStorage.setItem("token",response.data.accessToken);
               resolve({ type: REFRESHTOKEN});
           }


        })
            .catch(err => {
                
                reject({ type: SET_NOTIFICATION,notification:{message:"Authenticaltion failed",variant:"danger",err}  })
            });
    });

}

function logoutService(){

    return new Promise(function (resolve, reject) {

        pAxios({
            "method": "POST",
            "url": "/api/auth/signout",
            "headers": {
                'content-type': 'application/json'
            },
            "data":JSON.stringify({
                refreshToken:localStorage.getItem("refreshToken")
            })
        }).then(response => {

           if(response.data && response.data){

                removeLocal();
               history.push("/login");
               resolve({ type: LOGOUT_USER});
           }
        })
            .catch(err => {            
                reject({ type: SET_NOTIFICATION,notification:{message:"Logout failed",variant:"danger",err}  })
            });
    });

}


export const login=(userId,password)=>{

    return dispatch => {

        loginService(userId, password)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const loginUser=(userId)=>{

    return dispatch => {

        loadUserService(userId)
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}

export const refreshToken=()=>{

    return dispatch => {

        refreshTokenService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}



export const logout=()=>{

    return dispatch => {

        logoutService()
            .then(response => dispatch(response))
            .catch(err => dispatch(err));

    }

}